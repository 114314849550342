import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { Taxonomy } from 'src/app/models/services/insights/nielsen-data/taxonomy';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GlobalsService } from '../globals.service';
import { Query } from 'src/app/models/services/insights/nielsen-data/query';
import { TableData } from 'src/app/models/services/core/table-data';
import { TableDataPreview } from 'src/app/models/services/core/table-data-preview';

@Injectable({
  providedIn: 'root'
})
export class NielsenDataService {

  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService
  ) { }

  public getTaxonomy(): Observable<Taxonomy> {
    let url: string = `${environment.insightsServicesApiBaseUri}/nielsen/taxonomy`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

  public getDataPreview(query: Query): Observable<TableDataPreview> {
    let url: string = `${environment.insightsServicesApiBaseUri}/nielsen/data-preview`;
    return this.httpService.post(url, query, this.globalsService.getActiveCompany()?.id);
  }  

  public getData(query: Query): Observable<TableData> {
    let url: string = `${environment.insightsServicesApiBaseUri}/nielsen/data`;
    return this.httpService.post(url, query, this.globalsService.getActiveCompany()?.id);
  }  
}
