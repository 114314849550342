import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { CompanySelectorService } from '../../../services/dialogs/company-selector.service';
import { DialogService } from '../../../services/core/dialog.service';
import { Company } from '../../../models/services/company';
import { CompaniesService } from 'src/app/services/companies.service';
import { timer } from 'rxjs';
import { ThemeOptions } from 'src/app/theme-options';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.sass']
})
export class RootComponent implements OnInit {

  constructor(
    private router: Router,
    public usersService: UsersService,
    private companySelectorService: CompanySelectorService,
    private dialogService: DialogService,
    private companiesService: CompaniesService,
    private globalsService: GlobalsService,
    private globals: ThemeOptions) { }

  ngOnInit(): void {
    if (!this.usersService.hasSomeRights())
      return;

    // Check if message on first login must be shown            
    if (localStorage.getItem("firstLoginMessageSkipped") != "YES")
    {
        this.dialogService.showConfirmation("Please make sure you familiarize yourself with the Groupe's Acceptable Use Policy, which details your responsibilities when managing the Groupe's information assets", () => {
          localStorage.setItem("firstLoginMessageSkipped", "YES");
          this.showCompanySelectorIfNeeded();
        }, () => {
          this.showCompanySelectorIfNeeded();
        });
    }
    else
      this.showCompanySelectorIfNeeded();
  }

  navigateTo = (path: string): void => {
    this.router.navigate([path]);7
  }


  private showCompanySelectorIfNeeded = (): void => {
    // Wait for pages ngInit
    timer(500).subscribe(() => {
      if (this.globals.adminMode)
        return;

      let activeCompany: Company = this.globalsService.getActiveCompany();
      if (!!activeCompany)
        return;
  
      if (this.globalsService.getAllowedCompanies().length == 1) {
        this.companiesService.setActiveCompany(this.globalsService.getAllowedCompanies()[0].id);
        return;
      }
    
      // Otherwise, show selector
      this.companySelectorService.showCompanySelector("Select the active company", (selectedCompanyId: number) => {      
        this.companiesService.setActiveCompany(selectedCompanyId);        
      }, false);  
    });
  }  
}
