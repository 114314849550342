import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { IABMaster } from 'src/app/models/services/iab';
import { DialogService } from 'src/app/services/core/dialog.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';
import { timer } from 'rxjs';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { TaxonomyItem, TaxonomyItemKeyword } from 'src/app/models/services/taxonomy';
import { TaxonomyItemSelectorDialogService } from 'src/app/services/dialogs/taxonomy-item-selector-dialog.service';

@Component({
  selector: 'app-taxonomy-maintenance',
  templateUrl: './taxonomy-maintenance.component.html',
  styleUrls: ['./taxonomy-maintenance.component.sass']
})
export class TaxonomyMaintenanceComponent implements OnInit, OnDestroy {
  heading: string = 'Taxonomy maintenance';
  subheading: string = '';
  icon: string = 'pe-7s-graph2 icon-gradient bg-midnight-bloom';
  loadFinished: boolean = false;
  treeData: TaxonomyItem[];
  selectedNode: TaxonomyItem;
  form: FormGroup;
  locked: boolean = false;

  constructor(
    public usersService: UsersService,
    private taxonomyService: TaxonomyService,
    private taxonomyItemSelectorDialogService: TaxonomyItemSelectorDialogService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private globals: ThemeOptions,
    private spinnerService: NgxSpinnerService   
  ) { 
      this.form = this.formBuilder.group(
        {
          name: ['', Validators.required],
          description: ['', Validators.required]
        });
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.globals.adminMode = true;

    this.taxonomyService.isMaintenanceLocked().subscribe(
      locked => {
        if (locked) {
          this.locked = true;
          // Wait and retry
          timer(2000).subscribe(() => this.ngOnInit());
        } else {
          this.locked = false;
          this.taxonomyService.getTaxonomyTree().subscribe(
            x => {
              this.treeData = x;
              this.spinnerService.hide();
              this.loadFinished = true;
            }
          )
        }

      }      
    );
  }

  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }

  onSelectedNodeChange = (node: TaxonomyItem) => {
    this.selectedNode = {...node};
  }

  joinSelectedNode = (): void => {  
    this.dialogService.showConfirmation("Are you sure you want to join this taxonomy item? \nThis action cannot be undone and all profiles, keywords, etc ... will be moved to the target one.\nThis action can potentially raise a big number of profile recalculations.",
      () => {
        this.taxonomyItemSelectorDialogService.show(
          this.treeData, 
          (selectedItem:TaxonomyItem) => {
            var modifiedNode = {...this.selectedNode};
            this.selectedNode = null;
            this.loadFinished = false;
            this.spinnerService.show();          
            this.locked = true;
            this.taxonomyService.joinTaxonomyItem(modifiedNode.id, selectedItem.id).subscribe(
              () => {
                this.ngOnInit();
              }
            )
          }, () =>{      
          }
        ); 
      })
  }

  reparentSelectedNode = (): void => {     
    this.dialogService.showConfirmation("Are you sure you want to reparent this taxonomy item? \nThis action can potentially raise a big number of profile recalculations.",
      () => {
        this.taxonomyItemSelectorDialogService.show(
          this.treeData, 
          (selectedItem:TaxonomyItem) => {
            var modifiedNode = {...this.selectedNode};
            this.selectedNode = null;
            this.loadFinished = false;
            this.spinnerService.show();          
            modifiedNode.parentId = selectedItem.id;
            this.taxonomyService.updateTaxonomyItem(modifiedNode).subscribe(
              () => {
                this.selectedNode = null;
                this.dialogService.showMessage('Taxonomy maintenance', 'Taxonomy item reparented successfully');
                this.ngOnInit();
              }
            )
          }, () =>{      
          }
        );        
      })
    }

  saveSelectedNode = (): void => {  
    var modifiedNode = {...this.selectedNode};
    this.selectedNode = null;
    this.loadFinished = false;
    this.spinnerService.show();
    this.taxonomyService.updateTaxonomyItem(modifiedNode).subscribe(      
      () => {
        this.dialogService.showMessage('Taxonomy maintenance', 'Taxonomy item saved successfully');        
        this.ngOnInit();
      }
    )
  }

  getPartnersNames = (keyword: TaxonomyItemKeyword) => {
    return keyword.partners.map(x => x.name).join(', ');
  }
}