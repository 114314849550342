<ngx-spinner name="dialogSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p style="color: white">Loading ...</p>
</ngx-spinner>
<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="main-card card">
        <div class="card-body">
            <div class="row">
                <div *ngFor="let tag of selectedTags" class="badge badge-pill badge-light" style="padding: 0.4rem 0.6rem; margin-left: 0.5rem;">
                    <span>{{tag.name}}&nbsp;&nbsp;</span>
                    <mat-icon class="pe-7s-ticket" style="vertical-align:middle;"></mat-icon>  
                    <span>&nbsp;&nbsp;</span>
                    <mat-icon class="pe-7s-trash" style="vertical-align:middle;color: red;cursor: pointer;" (click)="removeTag(tag)"></mat-icon>  
                </div>
            </div>  
            <div class="row">&nbsp;</div> 
            <div class="row">
                <mat-divider style="width: 100%;"></mat-divider>
            </div> 
            <div class="row">&nbsp;</div> 
            <div class="row">
                <div class="col-md-12">Create a new tag:</div>
            </div>
            <div class="row">&nbsp;</div> 
            <div class="row">
                <div class="col-md-10">
                    <form class="example-form">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Tag</mat-label>
                            <input type="email" matInput [formControl]="newTagFormControl" [errorStateMatcher]="matcher"
                                    placeholder="Ex. 'tag 1'" />
                            <mat-hint>Errors appear instantly!</mat-hint>
                            <mat-error *ngIf="newTagFormControl.hasError('existingTag') && !newTagFormControl.hasError('required')">Please enter a non existing tag name</mat-error>
                            <mat-error *ngIf="newTagFormControl.hasError('required')">Tag name is <strong>required</strong></mat-error>
                        </mat-form-field>
                    </form>                    
                </div>
                <div class="col-md-2">
                    <mat-icon class="pe-7s-up-arrow" style="vertical-align:middle;color: green;cursor: pointer;" (click)="addNewTag()" *ngIf="newTagFormControl.valid && newTagFormControl.dirty"></mat-icon>
                </div>
            </div>
            <div class="row">&nbsp;</div> 
            <div class="row">
                <div class="col-md-5">
                    <mat-divider style="width: 100%;display: inline-flex;vertical-align:middle"></mat-divider>
                </div>
                <div class="col-md-2" style="text-align: center;">
                    OR
                </div>
                <div class="col-md-5">
                    <mat-divider style="width: 100%;display: inline-flex;vertical-align:middle"></mat-divider>
                </div>
            </div>
            <div class="row">&nbsp;</div> 
            <div class="row">                
                <div class="col-md-12">choose a existing one:</div>
            </div>
            <div class="row">&nbsp;</div> 
            <div class="row">
                <div *ngFor="let tag of nonSelectedTags" class="badge badge-pill badge-light" style="padding: 0.4rem 0.6rem; margin-left: 0.5rem; margin-bottom: 0.5rem;">
                    <span>{{tag.name}}&nbsp;&nbsp;</span>
                    <mat-icon class="pe-7s-ticket" style="vertical-align:middle;"></mat-icon>          
                    <span>&nbsp;&nbsp;</span>
                    <mat-icon class="pe-7s-up-arrow" style="vertical-align:middle;color: #B6D7A8;cursor: pointer;" (click)="addTag(tag)"></mat-icon>
                </div>                
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onAccept()" [disabled]="!acceptButtonEnabled()">Accept</button>        
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">Cancel</button>
</div>