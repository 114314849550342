import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PowerBIReport } from 'src/app/models/services/powerBIReport';
import { DialogService } from 'src/app/services/core/dialog.service';
import { PowerBIReportParametersDialogService } from 'src/app/services/dialogs/powerbi-report-parameters-dialog.service';
import { PowerBIReportsService } from 'src/app/services/insights/power-bi-reports.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-admin-powerbi-reports',
  templateUrl: './admin-powerbi-reports.component.html',
  styleUrls: ['./admin-powerbi-reports.component.sass']
})
export class AdminPowerBIReportsComponent implements OnInit, OnDestroy {
  heading: string = 'PowerBI Reports master maintenance';
  subheading: string = '';
  icon: string = 'pe-7s-graph2 icon-gradient bg-midnight-bloom';

  powerBIReports: PowerBIReport[];
  loadFinished: boolean = false;

  constructor(
    public powerBIReportsService: PowerBIReportsService,
    public usersService: UsersService,
    private globals: ThemeOptions,
    private powerBIReportParametersDialogService: PowerBIReportParametersDialogService,
    private dialogService: DialogService,
    private spinnerService: NgxSpinnerService    
  ) { }

  ngOnInit(): void {
    this.spinnerService.show();
    this.globals.adminMode = true;
        
    this.powerBIReportsService.getAll().subscribe(x => {
      this.powerBIReports = x;
      this.spinnerService.hide();
      this.loadFinished = true;
    });      
  }

  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }

  addReport = (): void => {
    this.powerBIReportParametersDialogService.showDialog(x => {
      this.spinnerService.show();
      this.powerBIReportsService.addPowerBIReport(x).subscribe(() => {
        this.dialogService.showMessage("PowerBI report", "Report added");
        // Reload
        this.ngOnInit();
      })      
    }, () => {
      this.spinnerService.hide();
    });
  }

}
