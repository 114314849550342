import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MultipleDatasetsChartData, ProfileCounts, Report, SingleDatasetChartData } from '../../models/services/insights/insights';
import { HttpService } from '../core/http.service';
import { Observable } from 'rxjs';
import { GlobalsService } from '../globals.service';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {

  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService
  ) { }
  
  public getProfileCounts(): Observable<ProfileCounts> {
    let url: string = `${environment.insightsServicesApiBaseUri}/insights/profileCounts`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }   

  public getGendersPerAgeRanges(): Observable<MultipleDatasetsChartData> {
    let url: string = `${environment.insightsServicesApiBaseUri}/insights/gendersPerAgeRanges`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public getParentalStatusPerHouseholdSizes(): Observable<MultipleDatasetsChartData> {
    let url: string = `${environment.insightsServicesApiBaseUri}/insights/parentalStatusPerHouseholdSizes`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

  public getContextualInterests(): Observable<SingleDatasetChartData> {
    let url: string = `${environment.insightsServicesApiBaseUri}/insights/contextualInterests`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  } 

  public getMobileAppsInterests(): Observable<SingleDatasetChartData> {
    let url: string = `${environment.insightsServicesApiBaseUri}/insights/mobileAppsInterests`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  } 
  
  public getProfileUpdates(): Observable<SingleDatasetChartData> {
    let url: string = `${environment.insightsServicesApiBaseUri}/insights/profileUpdates`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }   

  public getPurchaseIntents(): Observable<SingleDatasetChartData> {
    let url: string = `${environment.insightsServicesApiBaseUri}/insights/purchaseIntents`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }   

  public getReportDetail(reportId: string): Observable<Report> {
    let url: string = `${environment.insightsServicesApiBaseUri}/powerbi/reports/${reportId}`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }   

}
