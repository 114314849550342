<ngx-spinner name="PowerBIReportParametersDialogComponentSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p style="color: white">Loading ...</p>
</ngx-spinner>
<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>
        {{message}}
    </p>
    <form [formGroup]="form">
        <div class="form-group">
            <label>PowerBI Portal workspace</label>
            <div class="page-title-actions">
                <div class="mb-2 mr-2 btn-group" ngbDropdown>
                    <button class="btn btn-primary btn-lg">{{groupsSelectorText}}</button>
                    <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary btn-lg">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div ngbDropdownMenu>
                        <button class="dropdown-item" *ngFor="let group of powerBIPortalGroups" (click)="onGroupChange(group)">{{group.name}}</button>
                    </div>
                </div>
            </div>
            <label>PowerBI Portal report</label>
            <div class="page-title-actions" [ngClass]="{ 'is-invalid': form.controls.powerBIReportId.errors && (form.controls.powerBIReportId.dirty || form.controls.powerBIReportId.touched) }">
                <div class="mb-2 mr-2 btn-group" ngbDropdown>
                  <button class="btn btn-primary btn-lg" [disabled]="!powerBIPortalGroupReports || powerBIPortalGroupReports.length == 0">{{reportsSelectorText}}</button>
                  <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary btn-lg" [disabled]="!powerBIPortalGroupReports || powerBIPortalGroupReports.length == 0"><span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div ngbDropdownMenu>
                    <button class="dropdown-item" *ngFor="let report of powerBIPortalGroupReports" (click)="onReportChange(report)">{{report.name}}</button>
                  </div>
                </div>
            </div>
            <div *ngIf="form.controls.powerBIReportId.errors && (form.controls.powerBIReportId.dirty || form.controls.powerBIReportId.touched)"
            class="cross-validation-error-message alert alert-danger">
                <div *ngIf="form.controls.powerBIReportId.errors?.required">
                    PowerBI report is required.
                </div>
            </div>
            <label for="name">Display name</label>
            <input type="text" formControlName="name" class="form-control"
                [ngClass]="{ 'is-invalid': form.controls.name.errors && (form.controls.name.dirty || form.controls.name.touched) }">
            <div *ngIf="form.controls.name.errors && (form.controls.name.dirty || form.controls.name.touched)"
                class="cross-validation-error-message alert alert-danger">
                <div *ngIf="form.controls.name.errors?.required">
                    Display name is required.
                </div>
            </div>    
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onAccept()"
        [disabled]="form.invalid">Accept</button>
    <button type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
</div>