<app-epsilon-spinner *ngIf="globalsService.getIsStarting()"></app-epsilon-spinner>
<ng-container *ngIf="!globalsService.getIsStarting()">
  <div *ngIf="!userService.isLogged()">
    <app-non-logged></app-non-logged>
  </div>
  <div *ngIf="globalsService.getIsPlatformMaintenance()">
    <app-platform-maintenance></app-platform-maintenance>
  </div>
  <div *ngIf="userService.isLogged() && !usersService.hasSomeRights()">
    <app-no-rights></app-no-rights>
  </div>
  <div *ngIf="userService.isLogged() && usersService.hasSomeRights()" [ngClass]="{
  'closed-sidebar' : globals.toggleSidebar,
  'closed-sidebar-md' : globals.toggleSidebarMobile,
  'closed-sidebar-open': globals.sidebarHover || globals.toggleSidebarMobile,
  'header-menu-open' : globals.toggleHeaderMobile,
  'fixed-footer' : globals.toggleFixedFooter
  }" [class]="'app-container app-theme-white'">

    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="app-main__outer">
      <div class="app-main__inner">
        <div>
          <router-outlet #o="outlet"></router-outlet>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
    <div class="sidebar-menu-overlay" (click)="toggleSidebarMobile()"></div>
  </div>
  <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
    <p style="color: white">Loading ...</p>
  </ngx-spinner>
</ng-container>