import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';
import { Segment } from '../models/services/segment';
import { MultipleDatasetsChartData, SingleDatasetChartData } from '../models/services/insights/insights';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class SegmentsService {

  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService
  ) { }

  public getAll(): Observable<Array<Segment>> {
    let url: string = `${environment.filesServicesApiBaseUri}/segments`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public get(id: string): Observable<Segment> {
    let url: string = `${environment.filesServicesApiBaseUri}/segments/${id}`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public delete(id: string): Observable<Segment> {
    let url: string = `${environment.filesServicesApiBaseUri}/segments/${id}`;
    return this.httpService.delete(url, this.globalsService.getActiveCompany()?.id)
  }

  public calculateSegmentInsights(id: string): Observable<void> {
    let url: string = `${environment.filesServicesApiBaseUri}/segments/${id}/insights/calculate`;
    return this.httpService.postNoResponse(url, null, this.globalsService.getActiveCompany()?.id);
  }  

  public getGendersPerAgeRanges(id: string): Observable<MultipleDatasetsChartData> {
    let url: string = `${environment.filesServicesApiBaseUri}/segments/${id}/insights/gendersPerAgeRanges`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public getParentalStatusPerHouseholdSizes(id: string): Observable<MultipleDatasetsChartData> {
    let url: string = `${environment.filesServicesApiBaseUri}/segments/${id}/insights/parentalStatusPerHouseholdSizes`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

  public getContextualInterests(id: string): Observable<SingleDatasetChartData> {
    let url: string = `${environment.filesServicesApiBaseUri}/segments/${id}/insights/contextualInterests`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  } 

  public getMobileAppsInterests(id: string): Observable<SingleDatasetChartData> {
    let url: string = `${environment.filesServicesApiBaseUri}/segments/${id}/insights/mobileAppsInterests`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  } 
  
  public getPurchaseIntents(id: string): Observable<SingleDatasetChartData> {
    let url: string = `${environment.filesServicesApiBaseUri}/segments/${id}/insights/purchaseIntents`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }     
}
