<div class="card">
    <div class="card-body">
        <ngx-spinner name="dialogSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p style="color: white">Loading ...</p>
        </ngx-spinner>        
        <div class="row">
            <div class="col-md-12">
                <h3>WAM audience parameters</h3>
                <section>
                    <mat-button-toggle-group [(ngModel)]="newCustomSegment" name="newCustomSegmentSelector" aria-label="New custom segment" (change)="onNewCustomSegmentSelectedChange()">
                        <mat-button-toggle value="0">Existing custom segment</mat-button-toggle>
                        <mat-button-toggle value="1">New custom segment</mat-button-toggle>
                    </mat-button-toggle-group>        
                </section>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-12" *ngIf="newCustomSegment == '1'">
                    <mat-form-field style="width: 100%;">
                        <mat-label>CustomSegment</mat-label>
                        <input type="text" placeholder="Ex. 'PID_'" aria-label="Custom segment" matInput 
                        [formControl]="form.controls.customSegmentDescription"
                        (input)="onCustomSegmentDescriptionChange()" style="width: 100%;">       
                        <mat-error *ngIf="form.controls.customSegmentDescription.errors && (form.controls.customSegmentDescription.dirty || form.controls.customSegmentDescription.touched)">Custom segment name is required</mat-error>
                    </mat-form-field>                    
                </div>
            </div>              
            <div class="row">
                <div class="col-md-12" *ngIf="newCustomSegment == '0'">
                    <mat-form-field style="width: 100%;">
                        <mat-label>CustomSegment</mat-label>
                        <input #matAutocompleteCustomSegments type="text" placeholder="Pick one" aria-label="Custom segment" matInput 
                        [formControl]="form.controls.customSegmentId" [matAutocomplete]="auto"
                        (input)="filterCustomSegments()" (focus)="filterCustomSegments()" (click)="resetCustomSegmentsFilter()"
                        style="width: 100%;">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onCustomSegmentChange($event)" [displayWith]="getCustomSegmentDescription.bind(this)">
                            <mat-option *ngFor="let customSegment of filteredCustomSegments" [value]="customSegment.id">{{customSegment.name}}</mat-option>
                        </mat-autocomplete>                      
                        <mat-error *ngIf="form.controls.customSegmentId.errors && (form.controls.customSegmentId.dirty || form.controls.customSegmentId.touched)">Existing custom segment is required</mat-error>
                    </mat-form-field>   
                </div>
            </div>      
        </form>
    </div>
</div>