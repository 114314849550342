import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { BaseTenantRequiredPageComponent } from 'src/app/components/core/base-tenant-required-page/base-tenant-required-page.component';
import { PowerBIReport } from 'src/app/models/services/powerBIReport';
import { GlobalsService } from 'src/app/services/globals.service';
import { InsightsService } from 'src/app/services/insights/insights.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-custom-insights',
  templateUrl: './custom-insights.component.html',
  styleUrls: ['./custom-insights.component.sass']
})
export class CustomInsightsComponent extends BaseTenantRequiredPageComponent implements OnInit {
  public heading: string = 'Custom insights';
  public subheading: string = '';
  public icon: string = 'pe-7s-graph2 icon-gradient bg-midnight-bloom';
  public reports: PowerBIReport[] = [];
  public embedConfig : IReportEmbedConfiguration = undefined;

  constructor(
    private insightsService: InsightsService,
    private globalsService: GlobalsService,
    private spinnerService: NgxSpinnerService,
    usersService: UsersService,
    router: Router) {
      super(
        usersService.hasSelectedCompanySectionRight('Insights'),
        globalsService, 
        router);
    var selectedCompany = this.globalsService.getActiveCompany();
    this.reports = selectedCompany?.powerBIReports?.length === 0 ? [] : selectedCompany.powerBIReports;
  }

  ngOnInit(): void {
  }

  showReport(powerBIReportId: string): void {
    this.spinnerService.show();
    this.insightsService.getReportDetail(powerBIReportId).subscribe(result => {
      this.embedConfig = {
        type: "report",
        id: result.id,
        embedUrl: result.embedUrl,
        accessToken: result.embedToken,
        tokenType: models.TokenType.Embed,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                }
            },
            bars: {
              statusBar: {
                visible: true
              }
            },            
            background: models.BackgroundType.Default,
            layoutType: models.LayoutType.Custom,            
            customLayout: {
               displayOption: models.DisplayOption.FitToWidth
            }        
        }
      };
      this.heading = `Custom insights - ${result.name}`;
      this.spinnerService.hide();
    },
    () => {
      this.spinnerService.hide();
    });
  }

  hasCustomReports = (): boolean => {
    var selectedCompany = this.globalsService.getActiveCompany();
    return selectedCompany?.powerBIReports?.length > 0;
  }

}
