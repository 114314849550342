import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ErisTreeNode } from 'src/app/models/components/eris-tree-node';
import { remove } from 'util';

@Component({
  selector: 'app-eris-tree',
  templateUrl: './eris-tree.component.html',
  styleUrls: ['./eris-tree.component.sass']
})
export class ErisTreeComponent implements OnInit, OnChanges {

  @Input() 
  data: ErisTreeNode[];

  @Input() 
  selectionModel: SelectionModel<ErisTreeNode>;

  @Input() 
  maxLevelDeep: number;

  @Output() 
  onSelectionChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.nodesTreeDataSource.data = this.getDataFiltered();
  }

  ngOnChanges(changes: SimpleChanges) {      
    this.nodesTreeDataSource.data = this.getDataFiltered();
    this.selectionModel.clear();
  }

  //nodeHasChild = (_: number, node: ErisTreeNode) => !!node.children && node.children.length > 0 && (!this.maxLevelDeep || node.level <= this.maxLevelDeep);
  nodeHasChild = (_: number, node: ErisTreeNode) => !!node.children && node.children.length > 0;
   
  private _transformer = (node: ErisTreeNode, level: number) => {
    return {
      id: node.id,
      name: node.name,
      children: node.children,
      expandable: this.nodeHasChild(0, node),
      level: level
    };
  };

  treeControl = new FlatTreeControl<ErisTreeNode>(
    node => node.level,
    node => this.nodeHasChild(0, node)
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  nodesTreeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: ErisTreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.selectionModel.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected = (node: ErisTreeNode): boolean => {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.selectionModel.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  nodeSelectionToggle = (node: ErisTreeNode): void => {
    if(node.children?.length > 0) {
      this.selectionModel.toggle(node);
      const descendants = this.treeControl.getDescendants(node);
      this.selectionModel.isSelected(node)
        ? this.selectionModel.select(...descendants)
        : this.selectionModel.deselect(...descendants);  
    } else 
      this.selectionModel.toggle(node);

    if (!!this.onSelectionChange)
      this.onSelectionChange.emit();
  }

  getDataFiltered = (): ErisTreeNode[] => {
    var result = JSON.parse(JSON.stringify(this.data));
    if (!!this.maxLevelDeep)
      result.forEach((x: ErisTreeNode) => this.removeExtraLevels(x));
    
    return result;
  }

  removeExtraLevels = (node: ErisTreeNode): void => {
    if (!this.maxLevelDeep || node.level <= this.maxLevelDeep)
      node.children.forEach((x: ErisTreeNode) => this.removeExtraLevels(x));
    else
      delete node.children;
  }

}
