import { Component, Input, OnInit } from '@angular/core';
import { CitiserviQAReport } from 'src/app/models/services/qaReport';

@Component({
  selector: 'app-report-viewer-citiservi',
  templateUrl: './report-viewer-citiservi.component.html',
  styleUrls: ['./report-viewer-citiservi.component.sass']
})
export class ReportViewerCitiserviComponent implements OnInit {
  
  @Input()
  public selectedReport: CitiserviQAReport;

  constructor() { }

  ngOnInit(): void {
  }

}
