<div class="modal-header">
    <h4 class="modal-title">Select the active company</h4>
</div>
<div class="modal-body">
    <div ngbRadioGroup name="companies" [(ngModel)]="selectedCompanyId">
        <div style="padding: 2px;" *ngFor="let row of allowedCompaniesMatrix">
            <div class="btn-group-lg btn-group btn-group-toggle">
                <label *ngFor="let c of row" ngbButtonLabel class="btn-focus btn-company" (click)="onAccept(c.id, false)">
                    <input ngbButton type="radio" [value]="c.id">
                    <img width="120px" [src]="companiesService.getCompanyLogoSrc(c.id)">
                </label>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer" *ngIf="acceptButtonVisible">
    <button [disabled]="!selectedCompanyId" type="button" class="btn btn-secondary"
        (click)="onAccept(selectedCompanyId, true)">Accept</button>
</div>