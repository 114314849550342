<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div *ngIf="!usersService.hasGlobalActionRight('Admin', 'UserManagement', 'Manage')">
    You have no rights to access this page
</div>
<div *ngIf="loadFinished && usersService.hasGlobalActionRight('Admin', 'UserManagement', 'Manage')">
    <div class="row">
        <div class="col-4 col-md-3">
            <button class="btn btn-success" (click)="addUser()">Add user from AD B2C</button>
            <br/>
            <br/>
            <h5>Users</h5>
            <div ngbRadioGroup name="users" [(ngModel)]="selectedUserId">
                <div style="padding: 2px;" *ngFor="let user of users">
                    <div class="btn-group-lg btn-group btn-group-toggle">
                        <label ngbButtonLabel class="btn-focus">
                            <input ngbButton type="radio" [value]="user.id" (change)="onChangeUser($event)">
                            <div>{{user.name}}</div>                    
                        </label>
                    </div>
                </div>
            </div>                   
        </div>
        <div class="col-8 col-md-9" *ngIf="!!selectedUser">
            <div class="row">
                <div class="col-4 col-md-3">
                    <h5>Global rights</h5>
                </div>
                <div class="col-8 col-md-9" style="text-align: right;">
                    <button class="btn btn-success" (click)="selectAllGlobalRights()">Select all</button>&nbsp;
                    <button class="btn btn-success" (click)="unselectAllGlobalRights()">Unselect all</button>
                </div>
            </div>
            <br/>        
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Granted</th>
                        <th scope="col">Section</th>
                        <th scope="col">Module</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let globalRight of currentUserGlobalsRights">
                        <td><input type="checkbox" [(ngModel)]="globalRight.selected"/></td>
                        <td>{{globalRight.section}}</td>
                        <td>{{globalRight.module}}</td>
                        <td>{{globalRight.action}}</td>
                    </tr>
                </tbody>
            </table>         
            <br/>
            <br/>            
            <div class="row">
                <div class="col-4 col-md-3">
                    <h5>Companies rights</h5>
                </div>
                <div class="col-8 col-md-9" style="text-align: right;">
                    <button class="btn btn-success" (click)="selectAllCompaniesRights()">Select all</button>&nbsp;
                    <button class="btn btn-success" (click)="unselectAllCompaniesRights()">Unselect all</button>
                </div>
            </div>
            <br/>
            <div class="row">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Company</th>
                            <ng-container *ngFor="let companyRight of companiesRights">
                                <th scope="col" style="text-align: center;">
                                    {{companyRight.section}}<br/>
                                    {{!!companyRight.module ? companyRight.module : '-'}}<br/>
                                    {{!!companyRight.action ? companyRight.action : '-'}}
                                </th>                                    
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let companyRights of currentUserCompaniesRights">
                            <tr>
                                <td>{{companyRights.company.name}}</td>
                                <ng-container *ngFor="let right of companyRights.rights">
                                    <td style="text-align: center;">
                                        <input type="checkbox" [(ngModel)]="right.selected"/>
                                    </td>                              
                                </ng-container>                                
                            </tr>
                        </ng-container>
                    </tbody>
                </table>                                          
            </div>
            <div style="text-align: right;">
                <button class="btn btn-success" (click)="copyUserRights()">Copy rights from other user</button>&nbsp;
                <button class="btn btn-success" (click)="saveUser()">Save user rights</button>
            </div>
            <br/>
        </div>
    </div>
</div>