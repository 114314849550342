import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

class ObjectStoreConfig {
  name: string;
  keyPath: string;
} 

class DBConfig {
  name: string;
  objectStoresConfig: ObjectStoreConfig[];
}

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {

  private config: DBConfig[] = [{
      name: 'cache',
      objectStoresConfig: [{
        name: 'objects',
        keyPath: 'objectId'
      }]
    }
  ]

  constructor() {
  }

  public get = <T extends Object>(dbName: string, objectStoreName: string, key: string): Observable<T> => {
    return new Observable<T>(subscriber => {
      let dbOpenRequest: IDBOpenDBRequest = indexedDB.open(dbName)

      dbOpenRequest.onupgradeneeded = () => {
        this.onUpgradeNeeded(dbOpenRequest);
      }

      dbOpenRequest.onsuccess = () => {
        let db: IDBDatabase = dbOpenRequest.result;
        const transaction = db.transaction(objectStoreName, 'readonly');
        const objectStore = transaction.objectStore(objectStoreName);
        const request = objectStore.get(key);

        request.onsuccess = () => {
          subscriber.next(request.result?.object) 
        }

        request.onerror = () => {
          console.error(request.error);
          subscriber.error(request.error) 
        }

        transaction.oncomplete = () => {
          db.close();
          subscriber.complete();
        }

        transaction.onabort = () => {
          db.close();
          console.error('Indexed DB transaction aborted');
          subscriber.error('Indexed DB transaction aborted');          
          subscriber.complete();
        }

        transaction.onerror = () => {
          db.close();
          console.error(transaction.error);
          subscriber.error(transaction.error);
          subscriber.complete();
        }     

      }

      dbOpenRequest.onerror = () => {
        subscriber.error(dbOpenRequest.error);
        subscriber.complete(); 
      }

      dbOpenRequest.onblocked = () => {
        subscriber.error("Indexed DB blocked");
        subscriber.complete(); 
      }

    });

  }

  public save = (dbName: string, objectStoreName: string, key: string, value: any): Observable<void> => {
    return new Observable(subscriber => {
      let dbOpenRequest: IDBOpenDBRequest = indexedDB.open(dbName)

      dbOpenRequest.onupgradeneeded = () => {
        this.onUpgradeNeeded(dbOpenRequest);
      }

      dbOpenRequest.onsuccess = () => {
        let db: IDBDatabase = dbOpenRequest.result;
        const transaction = db.transaction(objectStoreName, 'readwrite');
        const objectStore = transaction.objectStore(objectStoreName);
        let dbConfig: DBConfig = this.config.find(x => x.name == db.name);
        let objectStoreConfig: ObjectStoreConfig = dbConfig.objectStoresConfig.find(x => x.name == objectStoreName);
        let dbObject: any = {};
        dbObject[objectStoreConfig.keyPath] = key;
        dbObject.object = value;
        const request = objectStore.add(dbObject);

        request.onsuccess = () => {
          subscriber.next();
        }

        request.onerror = () => {
          console.error(request.error);
          subscriber.error(request.error) 
        }

        transaction.oncomplete = () => {
          db.close();
          subscriber.complete();
        }

        transaction.onabort = () => {
          db.close();
          console.error('Indexed DB transaction aborted');
          subscriber.error('Indexed DB transaction aborted');          
          subscriber.complete();
        }

        transaction.onerror = () => {
          db.close();
          console.error(transaction.error);
          subscriber.error(transaction.error);
          subscriber.complete();
        }        
      }

      dbOpenRequest.onerror = () => {
        subscriber.error(dbOpenRequest.error);
        subscriber.complete(); 
      }

      dbOpenRequest.onblocked = () => {
        subscriber.error("Indexed DB blocked");
        subscriber.complete(); 
      }

    });
    
  };    

  public delete = (dbName: string, objectStoreName: string, key: string): Observable<void> => {
    return new Observable<void>(subscriber => {
      let dbOpenRequest: IDBOpenDBRequest = indexedDB.open(dbName)

      dbOpenRequest.onupgradeneeded = () => {
        this.onUpgradeNeeded(dbOpenRequest);
      }

      dbOpenRequest.onsuccess = () => {
        let db: IDBDatabase = dbOpenRequest.result;
        const transaction = db.transaction(objectStoreName, 'readwrite');
        const objectStore = transaction.objectStore(objectStoreName);
        const request = objectStore.delete(key);

        request.onsuccess = () => {
          subscriber.next() 
        }

        request.onerror = () => {
          console.error(request.error);
          subscriber.error(request.error) 
        }

        transaction.oncomplete = () => {
          db.close();
          subscriber.complete();
        }

        transaction.onabort = () => {
          db.close();
          console.error('Indexed DB transaction aborted');
          subscriber.error('Indexed DB transaction aborted');          
          subscriber.complete();
        }

        transaction.onerror = () => {
          db.close();
          console.error(transaction.error);
          subscriber.error(transaction.error);
          subscriber.complete();
        }     

      }

      dbOpenRequest.onerror = () => {
        subscriber.error(dbOpenRequest.error);
        subscriber.complete(); 
      }

      dbOpenRequest.onblocked = () => {
        subscriber.error("Indexed DB blocked");
        subscriber.complete(); 
      }

    });

  }  

  
  private onUpgradeNeeded = (dbOpenRequest: IDBOpenDBRequest): void => {
    let db: IDBDatabase = dbOpenRequest.result;
    let dbConfig: DBConfig = this.config.find(x => x.name == db.name);

    if(!dbConfig)
      return;

    dbConfig.objectStoresConfig.forEach(element => {
      if (!db.objectStoreNames.contains(element.name))
      db.createObjectStore(element.name, {
          keyPath: element.keyPath
        });
    });
  }
}
