<div class="h-100 bg-premium-dark bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <img src="../../assets/images/ERIS.png" />
                <div>ERIS ID Portal</div>
                <span>Please sign in to your account below.</span>
              </h4>
            </div>
            <div class="float-right">
              <button type="button" class="btn btn-primary btn-lg" (click)="userService.login();">Login to
                Dashboard</button>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © ArchitectUI 2019
      </div>
        -->
    </div>
  </div>
</div>