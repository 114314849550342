<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [searchEnabled]="true" [searchCallback]="filterDataTransfers">    
</app-page-title>
<div class="card">
    <div class="card-body row">
        <div class="col-lg-6 text-left">
        </div>
        <div class="col-lg-6 text-right">
            <a class="vsm-link">
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="downloadWAMInfoCsv()">
                    Download WAM info CSV
                </button>
            </a>            
            &nbsp;&nbsp;&nbsp;
            <a class="vsm-link">
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="downloadPublicisInfoCsv()">
                    Download Publicis info CSV
                </button>
            </a>            
        </div>
    </div>
</div>
<app-publicis-table [dataSource]="dataTransfersDataSource" #parent>
    <ng-container *ngFor="let dataTransfer of parent.data; index as i">
        <tr>
            <th scope="row" class="align-middle" style="width: 5%;">{{ dataTransfer.wamDataTransfer.id }}</th>
            <td class="align-middle" style="width: 35%; word-break: break-all;">{{ dataTransfer.wamDataTransfer.name }}</td>
            <td class="align-middle" style="width: 10%; word-break: break-all;">{{ dataTransfer.wamDataTransfer.status?.charAt(0)?.toUpperCase() + dataTransfer.wamDataTransfer.status?.slice(1) }}</td>
            <td class="align-middle" style="width: 10%; word-break: break-all;">{{ dataTransfer.wamDSP?.name }}</td>
            <td class="align-middle" style="width: 10%;">{{ dataTransfer.wamDataTransfer.cpm | currency : 'EUR' }}</td>
            <td class="align-middle" style="width: 10%;">{{ dataTransfer.wamDataTransfer.createdDate | date }}</td>
            <td class="align-middle" style="width: 10%;">{{ dataTransfer.wamDataTransfer.endDate | date }}</td>
            <td class="align-middle" style="width: 10%;">
                <div class="row">
                    <div class="btn" style="padding: 0.1rem 0.1rem" title="Show data transfer info">
                        <div class="font-icon-wrapper-medium" (click)="showDataTransferInfo(dataTransfer)">
                            <i class="pe-7s-info"></i>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr *ngIf="dataTransfer.audienceFiles?.length > 0">
            <th scope="row" colspan="1"></th>
            <td colspan="7">
                <table class="table table-striped">
                    <tr>
                        <th scope="col">Sent files</th>
                        <th scope="col">ID</th>
                        <th scope="col">Creation date</th>
                        <th scope="col">Size</th>
                        <th scope="col">Times sent</th>
                        <th scope="col">Last sent date</th>
                    </tr>
                    <tr *ngFor="let audienceFile of dataTransfer.audienceFiles; index as j">
                        <td class="align-middle" style="width: 30%;"></td>
                        <td class="align-middle" style="width: 10%;">{{ audienceFile.id }}</td>
                        <td class="align-middle" style="width: 20%;">{{ audienceFile.created | date }}</td>
                        <td class="align-middle" style="width: 10%;">{{ audienceFile.bytesSize | bytes }}</td>
                        <td class="align-middle" style="width: 10%;">{{ audienceFile.timesSent.length }}</td>
                        <td class="align-middle" style="width: 30%;">{{ audienceFile.timesSent.length > 0 ? (audienceFile.timesSent[audienceFile.timesSent.length - 1].timeSent | date:'medium') : '' }}</td>
                    </tr>
                </table>
            </td>
        </tr>
    </ng-container>
</app-publicis-table>