import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { AdminService } from 'src/app/services/admin.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-admin-data-transfers-report',
  templateUrl: './admin-data-transfers-report.component.html',
  styleUrls: ['./admin-data-transfers-report.component.sass']
})
export class AdminDataTransfersReportComponent implements OnInit, OnDestroy {
  public heading: string = 'Data transfers report';
  public subheading: string = '';
  public icon: string = 'pe-7s-radio icon-gradient bg-midnight-bloom';
  public embedConfig : IReportEmbedConfiguration = undefined;

  constructor(
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
    private globals: ThemeOptions
  ) { }

  ngOnInit(): void {
    this.globals.adminMode = true;

    this.adminService.getDataTransfersReportDetail().subscribe(result => {
      this.embedConfig = {
        type: "report",
        id: result.id,
        embedUrl: result.embedUrl,
        accessToken: result.embedToken,
        tokenType: models.TokenType.Embed,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                }
            },
            bars: {
              statusBar: {
                visible: true
              }
            },
            background: models.BackgroundType.Default,
            layoutType: models.LayoutType.Custom,            
            customLayout: {
               displayOption: models.DisplayOption.FitToWidth
            }       
        }
      };
      this.spinnerService.hide();
    },
    () => {
      this.spinnerService.hide();
    });    
  }

ngOnDestroy(): void {
  this.globals.adminMode = false;
}


}
