import { Injectable } from '@angular/core';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';
import { IABMaster } from '../models/services/iab';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IABService {

  constructor(
    private httpService: HttpService    
  ) { }

  public getIABMasterTree = (): Observable<IABMaster[]> => {
    let url: string = `${environment.adminServicesApiBaseUri}/master/iab`;
    return this.httpService.get(url, undefined);
  }  

  public updateIABMaster = (iabMaster: IABMaster): Observable<void> => {
    let url: string = `${environment.adminServicesApiBaseUri}/master/iab`;
    return this.httpService.putNoResponse(url, iabMaster);
  }  

  public joinIABMaster = (id: number, targetId: number): Observable<void> => {
    let url: string = `${environment.adminServicesApiBaseUri}/master/iab/${id}/join/${targetId}`;
    return this.httpService.postNoResponse(url, null);
  } 

  public isMaintenanceLocked = (): Observable<boolean> => {
    let url: string = `${environment.adminServicesApiBaseUri}/master/iab/locked`;
    return this.httpService.get(url, null);
  }      
}
