<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Carrefour QA Report - {{selectedReport.date | date}}</b></h3>
</div>
<!-- -------------------------------------------------------- -->
<div class="row">&nbsp;</div>
<!-- -------------------------------------------------------- -->
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>PHYSICAL STORE</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total tickets: {{selectedReport.totalOfflineTickets | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total ticket lines: {{selectedReport.totalOfflineTicketLines | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Ticket lines average per ticket: {{selectedReport.totalOfflineTicketLines / selectedReport.totalOfflineTickets | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total tickets identified: {{selectedReport.totalOfflineTicketsIdentified | number:'':'es' }}
</div>
<!-- -------------------------------------------------------- -->
<div class="row">&nbsp;</div>
<!-- -------------------------------------------------------- -->
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>ONLINE</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total tickets: {{selectedReport.totalOnlineTickets | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total ticket lines: {{selectedReport.totalOnlineTicketLines | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Ticket lines average per ticket: {{selectedReport.totalOnlineTicketLines / selectedReport.totalOnlineTickets | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total tickets identified: {{selectedReport.totalOnlineTicketsIdentified | number:'':'es' }}
</div>