import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { TapTapAudiencesService } from 'src/app/services/audiences/taptap-audiences.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-taptap-attributes',
  templateUrl: './taptap-attributes.component.html',
  styleUrls: ['./taptap-attributes.component.sass']
})
export class TaptapAttributesComponent implements OnInit {
    @ViewChild('matAutocompleteCategories') inputAutocompleteCategories: ElementRef<HTMLInputElement>;
    @ViewChild('matAutocompleteAudiences') inputAutocompleteAudiences: ElementRef<HTMLInputElement>;
  
    @Input() 
    form: FormGroup;
  
    @Input() 
    updateValueAndValidity: () => void;

    @Input() 
    erisAudienceName: string;
  
    categories: string[] = [];
    filteredCategories: string[] = [];
    audiences: string[] = [];
    filteredAudiences: string[] = [];
    newAudience: string = '0';
  
    constructor(
      private taptapAudiencesServices: TapTapAudiencesService,
      private spinnerService: NgxSpinnerService
    ) {
    }
  
    ngOnInit(): void {
      this.spinnerService.show("dialogSpinner");
      this.taptapAudiencesServices.getCategories().subscribe(
        resp => {
          this.categories = resp;
          this.onNewAudienceSelectedChange();
          
          this.spinnerService.hide("dialogSpinner");
          this.onCategoryChange(null);
        }
      )
    }
   
    onCategoryChange = ($event: MatAutocompleteSelectedEvent): void => {
      let _this: TaptapAttributesComponent = this;
      this.audiences = [];
      if (!!this.form.controls.categoryName.value) {
        this.spinnerService.show("dialogSpinner");
        this.taptapAudiencesServices.getAudiences(this.form.controls.categoryName.value).subscribe(
          resp => {
            if (resp.length == 0)
              _this.form.controls.audienceName.setValue(null);
  
            _this.audiences = resp;
            _this.spinnerService.hide("dialogSpinner");
            _this.inputAutocompleteCategories.nativeElement.blur();        
  
            _this.updateValueAndValidity();
          }
        )
      } else 
        this.updateValueAndValidity();
    }
    
    resetCategoriesFilter = (): void => {
      this.inputAutocompleteCategories.nativeElement.value = '';
      this.filteredCategories = this.categories;
      this.form.controls.categoryName.setValue(null);
      this.updateValueAndValidity();
    }
  
    filterCategories = (): void => {
      const filterValue = this.inputAutocompleteCategories.nativeElement.value.toLowerCase();
      this.filteredCategories = this.categories.filter(o => o.toLowerCase().includes(filterValue));
      this.updateValueAndValidity();
    }
     
    resetAudiencesFilter = (): void => {
      this.inputAutocompleteAudiences.nativeElement.value = '';
      this.form.controls.audienceName.setValue(null);
      this.filteredAudiences = this.audiences;
      this.updateValueAndValidity();
    }
      
    filterAudiences = (): void => {
      const filterValue = this.inputAutocompleteAudiences.nativeElement.value.toLowerCase();
      this.filteredAudiences = this.audiences.filter(o => o.toLowerCase().includes(filterValue));
      if (filterValue != this.form.controls.audienceName.value?.toLowerCase())
        this.form.controls.audienceName.setValue(null);
      
      this.updateValueAndValidity();
    }
  
    onAudienceChange = ($event: MatAutocompleteSelectedEvent): void => {    
      this.inputAutocompleteAudiences.nativeElement.blur();
      this.form.controls.audienceName.setValue(this.inputAutocompleteAudiences.nativeElement.value);
      this.updateValueAndValidity();
    }
  
    onAudienceDescriptionChange = (): void => {
      this.updateValueAndValidity();
    }

    onNewAudienceSelectedChange = (): void => {
      this.form.controls.audienceName.setValue(!!(+this.newAudience) ? this.erisAudienceName : null);
      this.form.controls.audienceName.updateValueAndValidity();
      this.updateValueAndValidity();      
    }

  }
  