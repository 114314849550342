<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon">    
</app-page-title>
<div class="card">
    <div class="card-body row">
        <div class="card-body">
            <h3>
                Provider&nbsp;&nbsp;&nbsp;
                <div class="mb-2 mr-2 btn-group" ngbDropdown>
                    <button class="btn btn-primary btn-lg">{{selectedProviderDescription}}</button>
                    <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary btn-lg"><span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div ngbDropdownMenu>
                        <button class="dropdown-item" *ngFor="let partnerQAReports of availablePartnersQAReports" (click)="onPartnerSelected(partnerQAReports)">{{getPartnerDescription(partnerQAReports.partner)}}</button>              
                    </div>
                </div>
                <ng-container *ngIf="!!selectedPartnerQAReports">
                    <mat-label>&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;</mat-label>
                    <input matInput [matDatepicker]="picker" [value]="initialDate" [matDatepickerFilter]="filterValidDates" (dateChange)="onReportDateSelected($event)">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker #picker></mat-datepicker>
                </ng-container>
            </h3>            
        </div>
    </div>
    <div class="card-body" *ngIf="!!selectedReport"> 
        <app-report-viewer-citiservi *ngIf="selectedPartnerQAReports?.partner == partnersEnum.Citiservi" [selectedReport]="selectedReport"></app-report-viewer-citiservi>
        <app-report-viewer-weborama *ngIf="selectedPartnerQAReports?.partner == partnersEnum.Weborama" [selectedReport]="selectedReport"></app-report-viewer-weborama>
        <app-report-viewer-logan *ngIf="selectedPartnerQAReports?.partner == partnersEnum.Logan" [selectedReport]="selectedReport"></app-report-viewer-logan>
        <app-report-viewer-fintonic *ngIf="selectedPartnerQAReports?.partner == partnersEnum.Fintonic" [selectedReport]="selectedReport"></app-report-viewer-fintonic>
        <app-report-viewer-afilead *ngIf="selectedPartnerQAReports?.partner == partnersEnum.Afilead" [selectedReport]="selectedReport"></app-report-viewer-afilead>
        <app-report-viewer-carrefour *ngIf="selectedPartnerQAReports?.partner == partnersEnum.Carrefour" [selectedReport]="selectedReport"></app-report-viewer-carrefour>
    </div>    
</div>