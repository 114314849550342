import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TableData } from 'src/app/models/services/core/table-data';
import { DialogService } from 'src/app/services/core/dialog.service';
import { NielsenDataService } from 'src/app/services/insights/nielsen-data.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Taxonomy, TaxonomyAggregation, TaxonomyLevel, TaxonomyMarket, TaxonomyPeriod, TaxonomyProduct } from 'src/app/models/services/insights/nielsen-data/taxonomy';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErisTreeNode } from 'src/app/models/components/eris-tree-node';
import { SelectionModel } from '@angular/cdk/collections';
import { Query } from 'src/app/models/services/insights/nielsen-data/query';
import { TableDataPreview } from 'src/app/models/services/core/table-data-preview';
import { AdminService } from 'src/app/services/admin.service';
import { CodeModel } from '@ngstack/code-editor';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-sql-runner',
  templateUrl: './sql-runner.component.html',
  styleUrls: ['./sql-runner.component.sass']
})
export class SqlRunnerComponent implements OnInit {
  public heading: string = 'SQL runner';
  public subheading: string = '';
  public icon: string = 'pe-7s-graph2 icon-gradient bg-midnight-bloom';

  public tableDataPreview: TableDataPreview;
  public tableData: TableData;  

  public codeModel: CodeModel = {
    language: 'sql',
    uri: '',
    value: ''
  };

  constructor(
    private adminService: AdminService,
    private datePipe: DatePipe,    
    private decimalPipe: DecimalPipe,    
    private dialogService: DialogService,
    private spinnerService: NgxSpinnerService,
    private globals: ThemeOptions) { }

  ngOnInit(): void {
    this.globals.adminMode = true;
  }

  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }
  
  getDataPreview = (): void => {
    this.spinnerService.show();
    this.clearResults();
    this.adminService.getSqlQueryDataPreview(this.codeModel.value).subscribe(resp => {
      this.tableDataPreview = resp;
      this.dialogService.showMessage("Query runner", "Data loaded");
      this.spinnerService.hide();
    }, () => {
      this.spinnerService.hide();
    });
  }

  getData = (actionCallback: () => void): void => {
    this.spinnerService.show();
    if(!this.tableData) {
      this.adminService.getSqlQueryData(this.codeModel.value).subscribe(resp => {
        this.tableData = resp;
        if (!actionCallback)
          this.dialogService.showMessage("Query runner", "Data loaded");      
        else
          actionCallback();
        this.spinnerService.hide();
      }, () => {
        this.spinnerService.hide();
      });        
    } else {
      if (!!actionCallback)
        actionCallback();
      this.spinnerService.hide();
    }
  }

  pasteToClipboard = (): void => {
    navigator.clipboard.writeText(this.getExcelData());
    this.dialogService.showMessage("Query runner", "Excel data copied to clipboard");
  }

  downloadTSV = (): void => {
    let filename: string = "Query result.tsv";
    const blob = new Blob([this.getExcelData()], {type: 'text/tab-separated-values'});
    if((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveBlob(blob, filename);
    }
    else{
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;        
        document.body.appendChild(elem);
        elem.click();        
        document.body.removeChild(elem);
    }
  }
    
  downloadCSV = (): void => {
    let filename: string = "Query result.csv";
    var content = this.getExcelData();
    const blob = new Blob([content.replace("\t", ",")], {type: 'text/csv'});
    if((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveBlob(blob, filename);
    }
    else{
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;        
        document.body.appendChild(elem);
        elem.click();        
        document.body.removeChild(elem);
    }
  }

  getExcelData = (): string => {
    let headers = this.tableData.columns.map(x => x.header);
    let excelData: string = headers.join("\t") + "\n";
    for(let i:number = 0; i < this.tableData.columns[0].data.length; i++) {
      for (let j:number = 0; j < headers.length; j++) {
        excelData += this.formatData(this.tableData.columns[j].data[i], this.tableData.columns[j].type);        
        if(j < headers.length - 1)
          excelData += "\t";
      }
      excelData += "\n";
    }      

    return excelData;
  }

  formatData = (value: any, type: string): string => {
    switch (type.toLowerCase()) {
      case "float" :
      case "int" :
      case "bigint" :
            return value == null ? '' : this.decimalPipe.transform(value, '', 'es');

      case "datetime" :
        return value == null ? '' : this.datePipe.transform(value, 'yyyy-MM-dd hh:mm:ss');       
        
      default:
        return value == null ? '' : value.toString();
    }
  }

  clearResults = (): void => {
    this.tableDataPreview = null;
    this.tableData = null;
  }
}
