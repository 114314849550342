<div class="modal-header">
    <h4 class="modal-title">Audiences list filter</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row" style="width: 100%;">
        <div class="col-md-12">
            <div class="main-card card">
                <div class="card-body">
                    <h2 class="card-title">Text filter (applies on fields name, description, category and status)</h2>            
                    <mat-form-field style="width: 100%;">
                        <mat-label>Text</mat-label>
                        <input type="text" matInput [formControl]="textSearchFormControl" placeholder="Ex. 'search term'" (keyup.enter)="onAcceptClick()" />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="row">&nbsp;</div>
    <div class="row" style="width: 100%;">
        <div class="col-md-12">
            <div class="main-card card">
                <div class="card-body">        
                    <h2 class="card-title">Tags filter</h2>   
                    <div>
                        <div class="badge badge-pill" [class]="tagsSearch.length == 0 ? 'badge-light' : 'badge-grey'" 
                            style="padding: 0.4rem 0.6rem; margin-left: 0.5rem; margin-bottom: 0.5rem; cursor:pointer;"
                            (click)="onShowAllTagsClick()">
                            <span>No tag filter&nbsp;&nbsp;</span>
                            <mat-icon class="pe-7s-ticket" style="vertical-align:middle;"></mat-icon>          
                        </div>      
                        <div *ngFor="let tag of tags" class="badge badge-pill" [class]="isTagInFilter(tag) ? 'badge-light' : 'badge-grey'"    
                            style="padding: 0.4rem 0.6rem; margin-left: 0.5rem; margin-bottom: 0.5rem; cursor:pointer;"
                            (click)="onShowTagClick(tag)">
                            <span>{{tag.name}}&nbsp;&nbsp;</span>
                            <mat-icon class="pe-7s-ticket" style="vertical-align:middle;"></mat-icon>          
                        </div>        
                    </div>    
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onResetFilterClick()">Reset filter</button>        
    <button type="button" class="btn btn-secondary" (click)="onAcceptClick()">Accept</button>        
    <button type="button" class="btn btn-secondary" (click)="dismiss()">Close</button>
</div>