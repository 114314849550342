import { Injectable } from '@angular/core';
import { CompanyUserRight, UserRight } from '../models/services/right';
import { environment } from 'src/environments/environment';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RightsService {

  constructor(
    private httpService: HttpService
  ) { }

  public getAllRights(): Observable<UserRight[]> {
    let url: string = `${environment.adminServicesApiBaseUri}/rights`;
    return this.httpService.get(url, undefined);
  }  

  public getAllCompanyRights(): Observable<CompanyUserRight[]> {
    let url: string = `${environment.adminServicesApiBaseUri}/companiesRights`;
    return this.httpService.get(url, undefined);
  }  
  
}
