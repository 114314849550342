<div class="card">
    <div class="card-body">
        <ngx-spinner name="dialogSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p style="color: white">Loading ...</p>
        </ngx-spinner>        
        <div class="row">
            <div class="col-md-12">
                <h3>Meta audience parameters</h3>
                <section>
                    <mat-button-toggle-group [(ngModel)]="newCustomAudience" name="newAudienceSelector" aria-label="New audience" (change)="onNewAudienceSelectedChange()">
                        <mat-button-toggle value="0">Existing audience</mat-button-toggle>
                        <mat-button-toggle value="1">New audience</mat-button-toggle>
                    </mat-button-toggle-group>        
                </section>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-12" *ngIf="newCustomAudience == '0'">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Audience</mat-label>
                        <input #matAutocompleteAudiences type="text" placeholder="Pick one" aria-label="Audience" matInput 
                        [formControl]="form.controls.audienceExternalId" [matAutocomplete]="auto"
                        (input)="filterAudiences()" (focus)="filterAudiences()" (click)="resetAudiencesFilter()"
                        style="width: 100%;">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onAudienceChange($event)" [displayWith]="getAudienceDescription.bind(this)">
                            <mat-option *ngFor="let audience of filteredAudiences" [value]="audience.id">{{audience.name}}</mat-option>
                        </mat-autocomplete>                      
                        <mat-error *ngIf="form.controls.audienceExternalId.errors && (form.controls.audienceExternalId.dirty || form.controls.audienceExternalId.touched)">Existing audience is required</mat-error>
                    </mat-form-field>   
                </div>
            </div>      
        </form>
    </div>
</div>