import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErisProcess } from 'src/app/models/services/erisProcess';
import { ErisProcessesInfo } from 'src/app/models/services/erisProcessesInfo';
import { DialogService } from 'src/app/services/core/dialog.service';
import { MonitorService } from 'src/app/services/monitor.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-admin-processes',
  templateUrl: './admin-processes.component.html',
  styleUrls: ['./admin-processes.component.sass']
})
export class AdminProcessesComponent implements OnInit, OnDestroy {

  heading: string = 'Processes';
  subheading: string = '';
  icon: string = 'pe-7s-config icon-gradient bg-midnight-bloom';
  loadFinished: boolean = false;

  erisProcessesInfo: ErisProcessesInfo;

  constructor(
    public usersService: UsersService,
    private globals: ThemeOptions,
    private monitorService: MonitorService,
    private spinnerService: NgxSpinnerService,
    private dialogService: DialogService
  ) { 
  }

  ngOnInit(): void {
    this.globals.adminMode = true;
    this.loadProcesses();
  }

  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }

  loadProcesses = (): void => {
    this.spinnerService.show();

    this.monitorService.getAllProcesses().subscribe(resp => {
      this.erisProcessesInfo = resp;
      this.loadFinished = true;
      this.spinnerService.hide();
    })
  }

  refresh = (): void => {
    this.erisProcessesInfo = {
      finishedProcesses: [],
      runningProcesses: []
    };
    this.loadProcesses();
  }

  showProcessDetail = (process: ErisProcess): void => { 
    this.spinnerService.show();
    this.monitorService.getProcessDetail(process.operationId).subscribe(resp => {
      this.spinnerService.hide();
      this.dialogService.showMessage("Process detail", resp.join('\n'), { size: 'xl' }, true);
    })

  }

}
