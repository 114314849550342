<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [searchEnabled]="false">
</app-page-title>
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="formQuery" *ngIf="!!taxonomy">
                    <!-- STEP 1 -->
                    <div class="row" *ngIf="currentStep == 1">
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <h3 class="card-title">Group level</h3>
                                    <mat-form-field style="width: 100%;">
                                        <mat-label>Hier level</mat-label>
                                        <input #matAutocompleteLevels type="text" placeholder="Pick one" aria-label="Hier level" matInput 
                                            [formControl]="formQuery.controls.levelId" [matAutocomplete]="levels" 
                                            (input)="filterLevels()" (focus)="filterLevels()" (click)="resetLevelsFilter()">
                                        <mat-autocomplete autoActiveFirstOption #levels="matAutocomplete" [displayWith]="getLevelDescription.bind(this)">
                                            <mat-option *ngFor="let level of filteredLevels" [value]="level.id">{{level.name}}</mat-option>
                                        </mat-autocomplete>                    
                                        <mat-error *ngIf="formQuery.controls.levelId.errors && (formQuery.controls.levelId.dirty || formQuery.controls.levelId.touched)">Level is required</mat-error>
                                    </mat-form-field>                   
                                </div>
                            </div>    
                        </div>                        
                        <div class="col-md-8">
                            <div class="card">
                                <div class="card-body">
                                    <h3 class="card-title">Week period</h3>
                                    <div class="row">
                                        <div class="col-md-1">From</div>
                                        <div class="col-md-5">
                                            <mat-form-field  style="width: 100%;">
                                                <mat-label>Period</mat-label>
                                                <input #matAutocompleteFromPeriods type="text" placeholder="Pick one" aria-label="Period" matInput 
                                                    [formControl]="formQuery.controls.fromPeriodId" [matAutocomplete]="fromPeriods" 
                                                    (input)="filterFromPeriods()" (focus)="filterFromPeriods()" (click)="resetFromPeriodsFilter()">
                                                <mat-autocomplete autoActiveFirstOption #fromPeriods="matAutocomplete" [displayWith]="getPeriodDescription.bind(this)">
                                                    <mat-option *ngFor="let period of filteredPeriods" [value]="period.id">{{period.description}}</mat-option>
                                                </mat-autocomplete>                    
                                                <mat-error *ngIf="formQuery.controls.fromPeriodId.errors && (formQuery.controls.fromPeriodId.dirty || formQuery.controls.fromPeriodId.touched)">From period is required</mat-error>
                                            </mat-form-field>                                        
                                        </div>
                                        <div class="col-md-1">to</div>
                                        <div class="col-md-5">
                                            <mat-form-field  style="width: 100%;">
                                                <mat-label>Period</mat-label>
                                                <input #matAutocompleteToPeriods type="text" placeholder="Pick one" aria-label="Period" matInput 
                                                    [formControl]="formQuery.controls.toPeriodId" [matAutocomplete]="toPeriods" 
                                                    (input)="filterToPeriods()" (focus)="filterToPeriods()" (click)="resetToPeriodsFilter()">
                                                <mat-autocomplete autoActiveFirstOption #toPeriods="matAutocomplete" [displayWith]="getPeriodDescription.bind(this)">
                                                    <mat-option *ngFor="let period of filteredPeriods" [value]="period.id">{{period.description}}</mat-option>
                                                </mat-autocomplete>                    
                                                <mat-error *ngIf="formQuery.controls.toPeriodId.errors && (formQuery.controls.toPeriodId.dirty || formQuery.controls.toPeriodId.touched)">From period is required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>                        
                    </div>
                    <!--
                    <div class="row">&nbsp;</div>
                    -->
                    <!-- STEP 3 -->
                    <div class="row" *ngIf="currentStep == 3">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h3 class="card-title">Markets selection</h3>
                                            <mat-form-field style="width: 100%;">
                                                <mat-label>Market</mat-label>
                                                <input #matAutocompleteMarkets type="text" placeholder="Pick one" aria-label="Market" matInput 
                                                    [matAutocomplete]="markets" 
                                                    (input)="filterMarkets()" (focus)="filterMarkets()" (click)="resetMarketsFilter()">
                                                <mat-autocomplete autoActiveFirstOption #markets="matAutocomplete" [displayWith]="getMarketDescription.bind(this)">
                                                    <mat-option *ngFor="let market of filteredMarkets" [value]="market.id">
                                                        <mat-checkbox class="checklist-leaf-node" [checked]="marketsSelectionModel.isSelected(market)" (change)="toggleMarketSelection(market, $event)" (click)="$event.stopPropagation()">
                                                            {{market.description}}
                                                        </mat-checkbox>              
                                                    </mat-option>
                                                </mat-autocomplete>                                        
                                            </mat-form-field>   
                                        </div>
                                        <div class="col-md-4">
                                            <h3 class="card-title">Selected markets</h3>
                                            <div *ngFor="let market of marketsSelectionModel.selected">{{market.description}}</div>
                                        </div>                                
                                    </div>                                                      
                                </div>
                            </div>    
                        </div>                        
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h3 class="card-title">Aggregation fields selection</h3>
                                            <mat-form-field style="width: 100%;">
                                                <mat-label>Aggregation</mat-label>
                                                <input #matAutocompleteAggregations type="text" placeholder="Pick one" aria-label="Aggregation" matInput 
                                                    [matAutocomplete]="aggregations" 
                                                    (input)="filterAggregations()" (focus)="filterAggregations()" (click)="resetAggregationsFilter()">
                                                <mat-autocomplete autoActiveFirstOption #aggregations="matAutocomplete" [displayWith]="getAggregationDescription.bind(this)">
                                                    <mat-option *ngFor="let aggregation of filteredAggregations" [value]="aggregation.id">
                                                        <mat-checkbox class="checklist-leaf-node" [checked]="aggregationsSelectionModel.isSelected(aggregation)" (change)="toggleAggregationSelection(aggregation, $event)" (click)="$event.stopPropagation()">
                                                            {{aggregation.description}}
                                                        </mat-checkbox>              
                                                    </mat-option>
                                                </mat-autocomplete>                                        
                                            </mat-form-field>   
                                        </div>
                                        <div class="col-md-4">
                                            <h3 class="card-title">Selected aggregation fields</h3>
                                            <div *ngFor="let aggregation of aggregationsSelectionModel.selected">{{aggregation.description}}</div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>    
                        </div>                       
                    </div>
                    <!--
                    <div class="row">&nbsp;</div>                    
                    -->
                    <!-- STEP 2 -->
                    <div class="row" *ngIf="currentStep == 2">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h3 class="card-title">Products selection</h3>
                                            <app-eris-tree [data]="productsTreeData" [selectionModel]="productsSelectionModel" [maxLevelDeep]="!formQuery.controls.levelId.value ? null : +formQuery.controls.levelId.value - 1"></app-eris-tree>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="card-title">Selected products</h3>
                                            <div *ngFor="let node of productsSelectionModel.selected | slice:0:24">{{getComposedName(node)}}</div>
                                            <div class="row">&nbsp;</div>
                                            <div *ngIf="productsSelectionModel.selected.length > 25">... and more ...</div>
                                        </div>                                
                                    </div>    
                                </div>                   
                            </div>     
                        </div>
                    </div>
                    <!--
                    <div class="row">&nbsp;</div>                    
                    -->                    
                    <!-- STEP 4 -->
                    <div class="row" *ngIf="currentStep == 4">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 class="card-title">Products selection</h3>
                                    <div class="row">&nbsp;</div>
                                    {{getQueryDescription()}}  
                                </div>                   
                            </div>     
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>                    
                    <!-- WIZARD BUTTONS -->
                    <div class="row">
                        <div class="col-md-12" style="text-align: right;">
                            <button 
                                type="button" class="btn btn-primary" style="margin-right:10px; width: 150px" 
                                *ngIf="showPreviousButton()" (click)="previousStep()" [disabled]="disabledNavigationButtons()">
                                Previous
                            </button>
                            <button 
                                type="button" class="btn btn-primary" style="margin-right:10px; width: 150px" 
                                *ngIf="showNextButton()" (click)="nextStep()" [disabled]="disabledNavigationButtons()">
                                Next
                            </button>
                            <button type="button" class="btn btn-primary" style="margin-right:10px; width: 150px" *ngIf="showGetDataButton()" (click)="getDataPreview()">Get data</button>
                        </div>
                    </div>  
                </form>              
            </div>    
        </div>
    </div>
</div>
<div class="row">&nbsp;</div>
<div class="row" *ngIf="!!tableDataPreview">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Data preview (first {{tableDataPreview.preview.columns[0].data.length | number:'':'es' }} rows of {{tableDataPreview.totalCount | number:'':'es' }})</h3>
                <table class="table table-striped" >
                    <thead>
                        <tr>
                            <th scope="col" *ngFor="let column of tableDataPreview.preview.columns">
                                {{column.header}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of tableDataPreview.preview.columns[0].data; index as rowIndex">
                            <td *ngFor="let column of tableDataPreview.preview.columns; index as columnIndex">{{this.formatData(tableDataPreview.preview.columns[columnIndex].data[rowIndex], tableDataPreview.preview.columns[columnIndex].type)}}</td>
                        </tr>
                    </tbody>
                </table>           
                <div class="row">&nbsp;</div>
                <div class="row" *ngIf="!!tableDataPreview">
                    <div class="col-md-12" style="text-align: right">
                        <button type="button" class="btn btn-primary" style="margin-right:10px;" (click)="getData(pasteToClipboard)">Copy full result to clipboard</button>
                        <button type="button" class="btn btn-primary" (click)="getData(downloadTsv)">Download as TSV file</button>
                    </div>
                </div>                     
            </div>
        </div>        
    </div>
</div>
<div class="row">&nbsp;</div>
