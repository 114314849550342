import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Company } from '../models/services/company';
import { Observable } from 'rxjs';
import { GlobalsService } from './globals.service';
import { HttpService } from './core/http.service';
import { HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CompaniesService {

  constructor(
    private globalsService: GlobalsService,
    private httpService: HttpService) {
  }

  public getAll(): Observable<Company[]> {
    let url: string = `${environment.coreServicesApiBaseUri}/companies`;  
    return this.httpService.get(url, undefined, true);
  }  

  public setActiveCompany = (companyId: number): boolean => {
    let allowedCompanies: Company[] = this.globalsService.getAllowedCompanies();
    if (!allowedCompanies)
      return false;
        
    let company: Company = allowedCompanies.find(x => x.id == companyId);
    if (!company)
      return false;

    this.globalsService.setActiveCompany(company);

    return true;
  }

  public clearActiveCompany = (): void => {
    this.globalsService.setActiveCompany(null);
  }

  public addCompany = (company: Company): Observable<void> => {
    let url: string = `${environment.coreServicesApiBaseUri}/companies`;
    return this.httpService.postNoResponse(url, company, undefined);
  }

  public updateCompany = (company: Company): Observable<void> => {
    let url: string = `${environment.coreServicesApiBaseUri}/companies`;
    return this.httpService.putNoResponse(url, company, undefined);
  }    

  public deleteCompany = (companyId: number): Observable<void> => {
    let url: string = `${environment.coreServicesApiBaseUri}/companies/${companyId}`;
    return this.httpService.deleteNoResponse(url, undefined);
  }   

  public uploadLogo = (company: Company, file: any): Observable<HttpEvent<any>> => {
    let formData = new FormData();
    formData.append("file", file);

    let url: string = `${environment.coreServicesApiBaseUri}/companies/${company.id}/logo`
    return this.httpService.postWithProgress(url, formData, undefined);
  }  

  public getCurrentCompanyLogoSrc = (): string => {
    return `${environment.assetsServicesApiBaseUri}/companies/${this.globalsService.getActiveCompany().id}/logo`;    
  }  

  public getCompanyLogoSrc = (companyId: number): string => {
    return `${environment.assetsServicesApiBaseUri}/companies/${companyId}/logo`;    
  }  
}
