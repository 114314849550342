import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { InsightsService } from 'src/app/services/insights/insights.service';
import { SegmentsService } from 'src/app/services/segments.service';

@Component({
  selector: 'publicis-id-insights-purchase-intents',
  templateUrl: './insights-purchase-intents.component.html',
  styleUrls: ['./insights-purchase-intents.component.sass']
})
export class InsightsPurchaseIntentsComponent implements OnInit {
  @Input() segmentId: string;
  // Pie
  public chartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
        formatter: function(value, ctx) {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return `${label}\n${value.toLocaleString()}`;
        }
      }
    },
    tooltips: {
      callbacks: {
            label: function(tooltipItem, data) {
                var value = data.datasets[0].data[tooltipItem.index];
                return value.toLocaleString();
            }
      }
    }
  };

  public chartLabels: Label[] = [];
  public chartData: number[] = [];
  public chartType: ChartType = 'pie';
  public chartLegend = true;
  public chartPlugins = [pluginDataLabels];
  public chartBackgroundColors: Color[] = [
    { backgroundColor: ['#e69ba2', '#febe89', '#ffe082', '#93d3a1', '#7fbcff', '#e69ba2', '#febe89', '#ffe082', '#93d3a1', '#7fbcff'] }
  ];

  constructor(
    private insightsService: InsightsService,
    private segmentsService: SegmentsService) { }

  ngOnInit(): void {
    if (!this.segmentId) {
      this.insightsService.getPurchaseIntents().subscribe(
        resp => {
          this.chartLabels = resp.labels;
          this.chartData = resp.dataSet as number[];
        },
        () => {
        }
      )    
    }
    else {
      this.segmentsService.getPurchaseIntents(this.segmentId).subscribe(
        resp => {
          this.chartLabels = resp.labels;
          this.chartData = resp.dataSet as number[];
        },
        () => {
        }
      )            
    }
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

}
