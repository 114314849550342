<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card-body">        
        <div class="main-card card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-2">
                        <label>{{taxonomyItem.name}}</label>
                    </div>
                    <div class="col-md-2">
                        <div class="d-inline-block" ngbDropdown>
                            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-success">{{selectedOperator}}</button>
                            <div ngbDropdownMenu>
                              <ng-container *ngFor="let operator of operators">
                                <button class="dropdown-item" (click)="onSelectOperator(operator)">{{operator}}</button>
                              </ng-container>                                
                            </div>
                        </div>       
                    </div>
                    <div class="col-md-8">
                        <mat-form-field>
                            <!--<mat-label>Value binding</mat-label>-->
                            <input matInput [matDatepicker]="picker" [formControl]="dateFormCtrl" [value]="dateFormCtrl.value">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>      
                    </div>
                </div>
            </div>
        </div>
    </div>  
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onAccept()" [disabled]="!acceptButtonEnabled()">Accept</button>        
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">Cancel</button>
</div>