import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/http.service';
import { Observable } from 'rxjs';
import { GlobalsService } from '../globals.service';
import { Profile } from 'src/app/models/services/audiences/amazon/profile';
import { Advertiser } from 'src/app/models/services/audiences/amazon/advertiser';
import { Audience } from 'src/app/models/services/audiences/amazon/audience';


@Injectable({
  providedIn: 'root'
})
export class AmazonAudiencesService {

  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService
  ) { }

  public getProfiles(): Observable<Profile[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/amazon/profiles`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public getAdvertisers(profileId: number): Observable<Advertiser[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/amazon/profiles/${profileId}/advertisers`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

  public getAudiences(profileId: number, advertiserId: bigint): Observable<Audience[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/amazon/profiles/${profileId}/advertisers/${advertiserId}/audiences`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

}