<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>
        {{message}}
    </p>
    <form [formGroup]="form">
        <div class="form-group">
            <label for="name">Name</label>
            <input type="text" formControlName="name" class="form-control"
                [ngClass]="{ 'is-invalid': form.controls.name.errors && (form.controls.name.dirty || form.controls.name.touched) }">
        </div>
        <div *ngIf="form.controls.name.errors && (form.controls.name.dirty || form.controls.name.touched)"
            class="cross-validation-error-message alert alert-danger">
            <div *ngIf="form.controls.name.errors?.required">
                Name is required.
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="accept(form.controls.name.value)"
        [disabled]="form.invalid">Accept</button>
    <button type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
</div>