<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Citiservi QA Report - {{selectedReport.date | date}}</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>General data</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total records: {{selectedReport.totalRecords | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Unique identities: {{selectedReport.uniqueIdentifiersRecords | number:'':'es' }}
</div>  
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total unique citiservi uids: {{selectedReport.citiserviUids.total | number:'':'es' }} - Non valid (non GUID): {{selectedReport.citiserviUids.nonValid | number:'':'es' }}
</div>                 
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total unique hashed emails: {{selectedReport.hashedEmails.total | number:'':'es' }} - Non valid (length distinct than 44): {{selectedReport.hashedEmails.nonValid | number:'':'es' }}
</div>     
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total unique xandr cookies: {{selectedReport.xandrCookies.total | number:'':'es' }} - Non valid (non numeric identifiers): {{selectedReport.xandrCookies.nonValid | number:'':'es' }}
</div>    
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total unique ttd cookies: {{selectedReport.ttdCookies.total | number:'':'es' }} - Non valid (non GUID): {{selectedReport.ttdCookies.nonValid | number:'':'es' }}
</div>                                                    
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total unique weborama cookies: {{selectedReport.weboramaCookies.total | number:'':'es' }} - Non valid (length distinct than 22): {{selectedReport.weboramaCookies.nonValid | number:'':'es' }}
</div>
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Anomalies</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Records with no identifiers (just home_id): {{selectedReport.nonIdentifiersRecords | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Records with no timestamp: {{selectedReport.noTimestampRecords | number:'':'es' }}
</div>    
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Identities with no segments (at least one identifier): {{selectedReport.identifiersNoSegmentsRecords | number:'':'es' }}
</div>               
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h4><b>Duplicated identities (more than one signal)</b></h4>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Number of identities with more than one signal: {{selectedReport.anomalies.duplicatedSignals.total | number:'':'es' }}
</div>      
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Top 20 identities with more signals
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Hashed email</th>
                <th scope="col">Xandr cookie</th>
                <th scope="col">TTD cookie</th>
                <th scope="col">Weborama cookie</th>
                <th scope="col">Number of signals</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let top20Item of selectedReport.anomalies.duplicatedSignals.top20; index as i">
                <td>{{ top20Item.hashedEmail }}</td>
                <td>{{ top20Item.xandrCookie }}</td>
                <td>{{ top20Item.ttdCookie }}</td>
                <td>{{ top20Item.weboramaCookie }}</td>
                <td>{{ top20Item.count | number:'':'es' }}</td>
            </tr>
        </tbody>
    </table>        
</div>          
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h4><b>Identities with more than two home ids</b></h4>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Number of identities with more than two home ids: {{selectedReport.anomalies.identitiesWithMoreThan2HomeIds.total | number:'':'es' }}
</div>      
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Top 20 identities with more home ids
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Hashed email</th>
                <th scope="col">Xandr cookie</th>
                <th scope="col">TTD cookie</th>
                <th scope="col">Weborama cookie</th>
                <th scope="col">Number of home ids</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let top20Item of selectedReport.anomalies.identitiesWithMoreThan2HomeIds.top20; index as i">
                <td>{{ top20Item.hashedEmail }}</td>
                <td>{{ top20Item.xandrCookie }}</td>
                <td>{{ top20Item.ttdCookie }}</td>
                <td>{{ top20Item.weboramaCookie }}</td>
                <td>{{ top20Item.count | number:'':'es' }}</td>
            </tr>
        </tbody>
    </table>        
</div>      
<!---->
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h4><b>Identities with more than two home zips</b></h4>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Number of identities with more than two home zips: {{selectedReport.anomalies.identitiesWithMoreThan2HomeZips.total | number:'':'es' }}
</div>      
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Top 20 identities with more home zips
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Hashed email</th>
                <th scope="col">Xandr cookie</th>
                <th scope="col">TTD cookie</th>
                <th scope="col">Weborama cookie</th>
                <th scope="col">Number of home zips</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let top20Item of selectedReport.anomalies.identitiesWithMoreThan2HomeZips.top20; index as i">
                <td>{{ top20Item.hashedEmail }}</td>
                <td>{{ top20Item.xandrCookie }}</td>
                <td>{{ top20Item.ttdCookie }}</td>
                <td>{{ top20Item.weboramaCookie }}</td>
                <td>{{ top20Item.count | number:'':'es' }}</td>
            </tr>
        </tbody>
    </table>        
</div>      
<!---->
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h4><b>Identities with more than two work zips</b></h4>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Number of identities with more than two work zips: {{selectedReport.anomalies.identitiesWithMoreThan2WorkZips.total | number:'':'es' }}
</div>      
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Top 20 identities with more work zips
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Hashed email</th>
                <th scope="col">Xandr cookie</th>
                <th scope="col">TTD cookie</th>
                <th scope="col">Weborama cookie</th>
                <th scope="col">Number of signals</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let top20Item of selectedReport.anomalies.identitiesWithMoreThan2WorkZips.top20; index as i">
                <td>{{ top20Item.hashedEmail }}</td>
                <td>{{ top20Item.xandrCookie }}</td>
                <td>{{ top20Item.ttdCookie }}</td>
                <td>{{ top20Item.weboramaCookie }}</td>
                <td>{{ top20Item.count | number:'':'es' }}</td>
            </tr>
        </tbody>
    </table>        
</div>              
<!---->
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Other interesting info</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Top 20 older signals (at least one identifier)
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Hashed email</th>
                <th scope="col">Xandr cookie</th>
                <th scope="col">TTD cookie</th>
                <th scope="col">Weborama cookie</th>
                <th scope="col">Timestamp</th>
                <th scope="col">Formatted date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let top20Item of selectedReport.top20OlderSignals; index as i">
                <td>{{ top20Item.hashedEmail }}</td>
                <td>{{ top20Item.xandrCookie }}</td>
                <td>{{ top20Item.ttdCookie }}</td>
                <td>{{ top20Item.weboramaCookie }}</td>
                <td>{{ top20Item.timestamp }}</td>
                <td>{{ top20Item.date | date : 'medium'}}</td>
            </tr>
        </tbody>
    </table>        
</div>            