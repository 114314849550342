<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <h5 class="card-title">Data transfer</h5>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Identifier:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDataTransfer.id}}
                        </div>
                    </div>   
                    <div class="row">
                        <div class="col-md-2">
                            <label>Name:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDataTransfer.name}}
                        </div>
                    </div>   
                    <div class="row">
                        <div class="col-md-2">
                            <label>Status:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDataTransfer.status?.charAt(0)?.toUpperCase() + dataTransfer.wamDataTransfer.status?.slice(1)}}
                        </div>
                    </div>                       
                    <div class="row">
                        <div class="col-md-2">
                            <label>Created date:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDataTransfer.createdDate | date }}
                        </div>
                    </div>                                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>End date:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDataTransfer.endDate | date }}
                        </div>
                    </div>                                       
                    <div class="row" *ngIf="dataTransfer.wamDataTransfer.deletedDate != '0000-00-00 00:00:00'">
                        <div class="col-md-2">
                            <label>Deleted date:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDataTransfer.deletedDate | date }}
                        </div>
                    </div>                                                                 
                    <div class="row">
                        <div class="col-md-2">
                            <label>Email:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDataTransfer.email}}
                        </div>
                    </div>                                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>Email CC:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDataTransfer.emailCC}}
                        </div>
                    </div>                                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>Delivering type:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDataTransfer.deliveringType}}
                        </div>
                    </div>                                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>CPM:</label>
                        </div>
                        <div class="col-md-10">
                            {{ !!dataTransfer.wamDataTransfer.cpm ? (dataTransfer.wamDataTransfer.cpm | currency : 'EUR') : '---' }}
                        </div>
                    </div>                                                    
<!--
                bootstrap: string;
                bootstrapStatus: string;
                cookieSplit: string;
                pîxelUrl: string;
                audienceEstimatedVolume: string;
                dspSegmentId: string;
                expirable: string;                
-->                                                 
                </div>
            </div>
            <div class="main-card mb-3 card" *ngIf="dataTransfer.audienceFiles?.length > 0">
                <div class="card-body">
                    <div class="row">
                        <h5 class="card-title">Publicis audiences</h5>
                        <table class="table table-striped">
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Name</th>                                
                                <th scope="col">Counts</th>
                                <th scope="col">Creation date</th>
                                <th scope="col">Description</th>
                            </tr>
                            <tr *ngFor="let audience of getUniqueAudiences(dataTransfer); index as j">
                                <td class="align-middle" style="width: 10%;">{{ audience.id }}</td>
                                <td class="align-middle" style="width: 20%; word-break: break-all;">{{ audience.name }}</td>
                                <td class="align-middle" style="width: 10%;">
                                    <div>Total profiles: {{audience.profilesCount}}</div>
                                    <ng-container *ngFor="let item of audience.counts">
                                        <div *ngIf="item.count > 0 && item.idGraphTypeId != '6'">{{ audiencesService.getIdGraphTypeDescription(+item.idGraphTypeId) }}: {{ item.count | number:'':'es'}}</div>
                                    </ng-container>
                                </td>
                                <td class="align-middle" style="width: 10%;">{{ audience.created | date }}</td>
                                <td class="align-middle" style="width: 50%;">{{ audience.description }}</td>
                            </tr>
                        </table>

<!--
    id: number;
    name: string;
    description: string;
    status: number;
    profilesCount: number;
    progressPercentage: number;
    created: string;
    updated: string | null;
    counts: PublicisAudienceCount[];    
-->                                                       
<!--
    id: number;
    targetPlatformId: number;
    bytesSize: number;
    created: string;
    updated: string | null;
    audienceId: number;
    audience: PublicisAudience;
    fileAttributes: PublicisAudienceFileAttribute[];
    timesSent: PublicisAudienceFileTimeSent[];    
-->                                        
                    </div>   
                </div>   
            </div>               
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="main-card mb-3 card" *ngIf="!!dataTransfer.wamAudience">
                <div class="card-body">
                    <h5 class="card-title">Audience</h5>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Identifier:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.id}}
                        </div>
                    </div>      
                    <div class="row">
                        <div class="col-md-2">
                            <label>Name:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.name}}
                        </div>
                    </div>      
                    <div class="row">
                        <div class="col-md-2">
                            <label>Type:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.audienceType}}
                        </div>
                    </div>             
                    <div class="row">
                        <div class="col-md-2">
                            <label>Status:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.status?.charAt(0)?.toUpperCase() + dataTransfer.wamAudience.status?.slice(1)}}
                        </div>
                    </div>                      
                    <div class="row">
                        <div class="col-md-2">
                            <label>Created date:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.createdDate | date }}
                        </div>
                    </div>                                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>End date:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.endDate | date }}
                        </div>
                    </div>                                    
                    <div class="row" *ngIf="dataTransfer.wamAudience.updatedDate != '0000-00-00 00:00:00'">
                        <div class="col-md-2">
                            <label>Updated date:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.updatedDate | date }}
                        </div>
                    </div>                                       
                    <div class="row" *ngIf="dataTransfer.wamAudience.deletedDate != '0000-00-00 00:00:00'">
                        <div class="col-md-2">
                            <label>Deleted date:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.deletedDate | date }}
                        </div>
                    </div>                                                               
                    <div class="row">
                        <div class="col-md-2">
                            <label>User:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.user}}
                        </div>
                    </div>                                   
                    <div class="row">
                        <div class="col-md-2">
                            <label>Email:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.email}}
                        </div>
                    </div>                                   
                    <div class="row">
                        <div class="col-md-2">
                            <label>Email CC:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.emailCC}}
                        </div>
                    </div>                                   
                    <div class="row">
                        <div class="col-md-2">
                            <label>Estimated volume:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.audienceEstimatedVolume}}
                        </div>
                    </div>                                   
                    <div class="row">
                        <div class="col-md-2">
                            <label>Description:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAudience.description}}
                        </div>
                    </div>                                                   
                </div>
<!--
    countryISOCode: string;
    userId: string;
    initialEndDate: string;
    strongClassifiersFamilyId: string;
    bootstrap: string;
    bootstrapPeriod: string;
    appScoreId: string;
    bigSea: string;
    maxRecency: string;
    hasGeoDemo: string;
    authorizedCountries: string;
    sharingParentId: string;
    strongClassifier: string;
-->            
            </div>
            <div class="main-card mb-3 card" *ngIf="dataTransfer.audienceFiles?.length > 0">
                <div class="card-body">
                    <h5 class="card-title">Files sent history</h5>
                    <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                        <div *ngFor="let audienceFileTimeSent of getSentHistory(dataTransfer); index as i" class="vertical-timeline-item dot-primary vertical-timeline-element">
                          <div>
                            <span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">
                                {{ audienceFileTimeSent.timeSent | date :'medium' }}
                              </h4>
                            </div>
                          </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="main-card mb-3 card" *ngIf="!!dataTransfer.wamCustomSegment">
                <div class="card-body">
                    <h5 class="card-title">Custom segment</h5>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Id:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamCustomSegment.id}}
                        </div>
                    </div>       
                    <div class="row">
                        <div class="col-md-2">
                            <label>Name:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamCustomSegment.name}}
                        </div>
                    </div>       
                    <div class="row">
                        <div class="col-md-2">
                            <label>Custom identifier:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamCustomSegment.customIdentifier}}
                        </div>
                    </div>       
                    <div class="row">
                        <div class="col-md-2">
                            <label>Category:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamCustomSegment.category}}
                        </div>
                    </div>       
                    <div class="row">
                        <div class="col-md-2">
                            <label>Type:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamCustomSegment.type}}
                        </div>
                    </div>                           
                    <div class="row">
                        <div class="col-md-2">
                            <label>Created date:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamCustomSegment.createdDate | date }}
                        </div>
                    </div>                                                                  
                    <div class="row" *ngIf="dataTransfer.wamCustomSegment.updatedDate != '0000-00-00 00:00:00'">
                        <div class="col-md-2">
                            <label>Updated date:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamCustomSegment.updatedDate | date }}
                        </div>
                    </div>                                                                      
<!--
    displayOrder: string;
    bootstrapStatus: string;
    oldName: string;
    bootstraCreated: string;
    parentWAMCustomSegmentId: string;
    lastFrequencyUpdate: string;    
-->                                
                </div>
            </div>              
            <div class="main-card mb-3 card" *ngIf="!!dataTransfer.wamDSP">
                <div class="card-body">
                    <h5 class="card-title">DSP</h5>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Identifier:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDSP.id}}
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>Name:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDSP.name}}
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>Weborama management:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDSP.weboramaManagement}}
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>Status:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDSP.status?.charAt(0)?.toUpperCase() + dataTransfer.wamDSP.status?.slice(1) }}
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>Url pattern:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDSP.urlPattern}}
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>Pixel separator:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDSP.pixelSeparator}}
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>Segment creation type:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDSP.segmentCreationType}}
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>Label key:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamDSP.labelKey}}
                        </div>
                    </div>                    
                </div>
              </div>
              <div class="main-card mb-3 card" *ngIf="!!dataTransfer.wamAccountDSP">
                <div class="card-body">
                    <h5 class="card-title">Account DSP</h5>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Identifier:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAccountDSP.id}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Name:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAccountDSP.name}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Client identifier:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAccountDSP.dSPClientIId}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Transfer type:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAccountDSP.transferType}}
                        </div>
                    </div>                
                    <div class="row">
                        <div class="col-md-2">
                            <label>Is segment owner:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAccountDSP.isSegmentOwner}}
                        </div>
                    </div>                
                    <div class="row">
                        <div class="col-md-2">
                            <label>Extra info:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAccountDSP.extraInfo}}
                        </div>
                    </div>                
                    <div class="row">
                        <div class="col-md-2">
                            <label>Sharing record identifier:</label>
                        </div>
                        <div class="col-md-10">
                            {{ dataTransfer.wamAccountDSP.sharingRecordId}}
                        </div>
                    </div>                
                </div>
            </div>            
        </div>  
    </div>    
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismiss()">Close</button>
</div>