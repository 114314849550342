import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanySelectorDialogComponent } from '../../components/dialogs/core/company-selector-dialog/company-selector-dialog.component';
import { CompaniesService } from '../companies.service';
import { HttpService } from '../core/http.service';

@Injectable({
  providedIn: 'root'
})
export class CompanySelectorService {

  constructor(
    private modalService: NgbModal
  ) { }

  public showCompanySelector = (dialogTitle: string, accept: (selectedCompanyId: number) => void, acceptButtonVisible: boolean): void => {
    var ref = this.modalService.open(CompanySelectorDialogComponent, {
      backdrop: 'static'
    });

    // Get the OkDialog from the NgbModal object  
    let dialog: CompanySelectorDialogComponent = ref.componentInstance as CompanySelectorDialogComponent;

    // Set the dialog's properties
    dialog.title = dialogTitle;
    dialog.acceptButtonVisible = acceptButtonVisible;
    dialog.accept = (selectedCompanyId: number) => {      
      accept(selectedCompanyId);
      ref.close();
    };
  }

}
