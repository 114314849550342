export const environment = {
  production: true,
  baseUri: "https://platform-dev.eris.id/",
  clientId: "0e5c1cbd-59ac-4d34-b517-6c883a5b64ce",
  authScopes: ["openid", "offline_access", "0e5c1cbd-59ac-4d34-b517-6c883a5b64ce"],
  assetsServicesApiBaseUri: "https://publicisidapi.azure-api.net/epsilon-idgraph-services-assets-staging",
  coreServicesApiBaseUri: "https://publicisidapi.azure-api.net/epsilon-idgraph-services-core-staging",
  filesServicesApiBaseUri: "https://publicisidapi.azure-api.net/epsilon-idgraph-services-files-staging",
  audiencesServicesApiBaseUri: "https://publicisidapi.azure-api.net/epsilon-idgraph-services-audiences-staging",
  insightsServicesApiBaseUri:  "https://publicisidapi.azure-api.net/epsilon-idgraph-services-insights-staging",
  lookAlikesServicesApiBaseUri: "https://publicisidapi.azure-api.net/eris-idgraph-services-look-alikes-staging",
  adminServicesApiBaseUri: "https://publicisidapi.azure-api.net/eris-idgraph-services-admin-staging",
  maxFileSizeBytesAllowed: 536870912
};
