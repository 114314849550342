<div class="h-100 row align-items-center">
    <div class="col text-center">
        <img src="/assets/images/ERIS.png">
        <br />
        <i class="pe-7s-hourglass fa-spin" style="font-size: 48px;"></i>
        <br />
        <br />
        <br />
        Loading
    </div>
</div>