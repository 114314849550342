export enum AudiencesStatus {
    Creating = 1,
    Created = 2,
    Error = 3,
    CalculatingProfilesCount = 4,
    CalculatingProfilesCountPerType = 5,
    RetrievingProfiles = 6,
    CalculatingLookAlikeParameters = 7,
    MovingAudience = 8,
    WaitingRetry = 9,
    Archived = 10
}