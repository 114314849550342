<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Fintonic QA Report - {{selectedReport.date | date}}</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>General data</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total users: {{selectedReport.totalUsers | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total sectors: {{selectedReport.totalSectors | number:'':'es' }}
</div>
<!-- -------------------------------------------------------- -->
<div class="row">&nbsp;</div>
<!-- -------------------------------------------------------- -->
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Users info</b></h3>
</div>
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total users without banks info: {{selectedReport.totalUsersWithoutBanksInfo | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total users without costs info: {{selectedReport.totalUsersWithoutCostsInfo | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total users without banks and costs info: {{selectedReport.totalUsersWithoutBanksAndCostsInfo | number:'':'es' }}
</div>
