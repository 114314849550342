import {Component, Input} from '@angular/core';
import { faStar, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
})
export class PageTitleComponent {

  faStar = faStar;
  faPlus = faPlus;

  @Input() heading: string;
  @Input() subheading: string;
  @Input() icon: string;
  @Input() searchEnabled: boolean;
  @Input() searchCallback: (searchTerm: string) => void;
  @Input() openSearchDialogCallback: () => void;
  @Input() isFilterApplied: () => boolean;

  onSearch = (searchBoxName: string, searchTerm: string) => {
    this.searchCallback(searchTerm);
  }
  
}
