<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
    <div class="col-md-6">
        <publicis-id-insights-gender-per-age-ranges [segmentId]="segmentId"></publicis-id-insights-gender-per-age-ranges>
    </div>
    <div class="col-md-6">
        <publicis-id-insights-parental-status-per-household-sizes [segmentId]="segmentId"></publicis-id-insights-parental-status-per-household-sizes>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <publicis-id-insights-contextual-interests [segmentId]="segmentId"></publicis-id-insights-contextual-interests>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <publicis-id-insights-mobile-apps-interests [segmentId]="segmentId"></publicis-id-insights-mobile-apps-interests>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <publicis-id-insights-purchase-intents [segmentId]="segmentId"></publicis-id-insights-purchase-intents>
    </div>
</div>

