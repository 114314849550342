<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div *ngIf="!usersService.hasGlobalSectionRight('Admin')">
    You have no rights to access this page
</div>
<div *ngIf="loadFinished && usersService.hasGlobalSectionRight('Admin')">
    <mat-tab-group>
        <mat-tab label="Maintenance processes">
            <app-maintenance-processes></app-maintenance-processes>
        </mat-tab>
        <mat-tab label="Ingestion processes">
            <app-ingestion-progress></app-ingestion-progress>
        </mat-tab>
        <mat-tab label="Portal running processes">
            <app-manual-processes></app-manual-processes>
        </mat-tab>
    </mat-tab-group>
</div>