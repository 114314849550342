import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models/services/company';
import { User } from 'src/app/models/services/user';
import { CompaniesService } from 'src/app/services/companies.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-selector-dialog',
  templateUrl: './company-selector-dialog.component.html',
  styleUrls: ['./company-selector-dialog.component.sass']
})
export class CompanySelectorDialogComponent implements OnInit {

  allowedCompaniesMatrix: Array<Array<Company>>;
  title: string = "Select the active company";
  selectedCompanyId: string;
  acceptButtonVisible: boolean;

  constructor(
    public companiesService: CompaniesService,
    private globalsService: GlobalsService) { }

  ngOnInit(): void {
    let allowedCompanies: Company[] = this.globalsService.getAllowedCompanies().sort((x, y): number => +x.id - +y.id);    
    this.allowedCompaniesMatrix = allowedCompanies.reduce(
      (rows, key, index) => (index % 3 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows, []
    );    
  }

  public accept: (selectedCompanyId: number) => void;

  public onAccept = (selectedCompanyId: number, acceptButton: boolean): void  => {
    if (this.acceptButtonVisible && !acceptButton)
      return;
    
    this.accept(selectedCompanyId);
  }

}
