import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UsersService } from 'src/app/services/users.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { CompaniesService } from 'src/app/services/companies.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  public sampleMenus: boolean = !environment.production;

  constructor(
    public globals: ThemeOptions, 
    public usersService: UsersService,
    public globalsService: GlobalsService,
    private companiesService: CompaniesService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
  }

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;    
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;

  }

  navigateToRoot = (): void => {
    if (location.pathname != '/') {
      this.companiesService.clearActiveCompany()
      this.router.navigate(['/']);  
    }
  }

  getRootLinkClass = (): string => window.location.pathname != '/' ? 'main-page-link' : '';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }

}
