<div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="icon {{icon}}"></i>
        </div>
        <div>
          {{heading}}
          <div class="page-title-subheading">
            {{subheading}}
          </div>
        </div>
      </div>
      <div class="page-title-actions">
        <div class="mb-2 mr-2 btn-group" ngbDropdown>
          <button class="btn btn-primary btn-lg">Select a report</button>
          <button type="button" ngbDropdownToggle class="dropdown-toggle-split btn btn-primary btn-lg"><span class="sr-only">Toggle Dropdown</span>
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item" *ngFor="let report of reports" (click)="showReport(report.powerBIId)">{{report.name}}</button>              
          </div>
        </div>
      </div>
    </div>
</div>
<div class="row" *ngIf="!!embedConfig">
    <div class="col-md-12" >
        <powerbi-report [embedConfig]="embedConfig" cssClassName="power-bi-report-container">
        </powerbi-report>          
    </div>
</div>
<div class="row" *ngIf="!hasCustomReports()">
  <div class="col-md-12">
    No custom reports for this company
  </div>
</div>
