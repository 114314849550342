<ngx-spinner name="dialogSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p style="color: white">Loading ...</p>
</ngx-spinner>
<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div ngbRadioGroup name="users" [(ngModel)]="selectedUserId">
        <div style="padding: 2px;" *ngFor="let user of users">
            <div class="btn-group-lg btn-group btn-group-toggle">
                <label ngbButtonLabel class="btn-focus">
                    <input ngbButton type="radio" [value]="user.id">
                    <div>{{user.name}}</div>                    
                </label>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button [disabled]="!selectedUserId" type="button" class="btn btn-secondary"
        (click)="onAccept(selectedUserId)">Accept</button>
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">Cancel</button>
</div>