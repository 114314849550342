<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div *ngIf="!usersService.hasGlobalActionRight('Admin', 'CompaniesManagement', 'Manage')">
    You have no rights to access this page
</div>
<div *ngIf="usersService.hasGlobalActionRight('Admin', 'CompaniesManagement', 'Manage')">
    <div class="row">
        <div class="col-4 col-md-3">
            <div class="row">
                <button class="btn btn-success" (click)="addCompany()">Add new company</button>
            </div>            
            <div class="row">&nbsp;</div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <h5>Companies</h5>
            </div>
            <div class="row">            
                <div ngbRadioGroup name="companies" [(ngModel)]="selectedCompanyId">
                    <div class="row" style="padding: 3px;" *ngFor="let company of companies">
                        <div class="col-10 col-md-10" >
                            <div class="btn-group-lg btn-group btn-group-toggle">
                                <label ngbButtonLabel class="btn-focus">
                                    <input ngbButton type="radio" [value]="company.id" (change)="onChangeCompany($event)">
                                    <div>{{company.name}}</div>              
                                </label>
                            </div>                    
                        </div>
                        <div class="col-2 col-md-2">
                            <div class="btn" style="padding: 0.1rem 0.1rem" title="Delete company">
                                <div class="font-icon-wrapper-medium" (click)="deleteCompany(company)">
                                    <i class="pe-7s-trash"></i>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>         
            </div>          
        </div>
        <div class="col-8 col-md-9" *ngIf="!!selectedCompany"> 
            <div class="row">
                <div class="col-12 col-md-12" style="text-align: right;">
                    <img width="200px" [src]="getSelectedCompanyLogoSrc()" style="background-color: #e5e5f7; opacity: 0.7; background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 4px ), repeating-linear-gradient( #3c3d3e55, #3c3d3e );">
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row" style="text-align: right;">
                <div class="col-12 col-md-12" style="text-align: right;">
                    <button class="btn btn-success" (click)="uploadCompanyLogo()">Upload company logo</button>&nbsp;
                </div>
            </div>           
            <div class="row">&nbsp;</div>
            <div class="row">&nbsp;</div>
            <div class="row" style="background-color: white; padding-top: 15px; padding-bottom: 5px;">
                <div class="col-4 col-md-3">
                    <h5>Predefined reports</h5>
                </div>
                <div class="col-8 col-md-9" style="text-align: right;">
                    <button class="btn btn-success" (click)="addPredefinedReport()">Add</button>&nbsp;
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let report of selectedCompany.predefinedReports">
                            <td>{{report.description}}</td>
                            <td>
                                <div class="btn" style="padding: 0.1rem 0.1rem" title="Delete company">
                                    <div class="font-icon-wrapper-medium" (click)="deletePredefinedReport(report)">
                                        <i class="pe-7s-trash"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>         
            </div>
            <div class="row">&nbsp;</div>
            <div class="row" style="background-color: white; padding-top: 15px; padding-bottom: 5px;">
                <div class="col-4 col-md-3">
                    <h5>Power BI reports</h5>
                </div>
                <div class="col-8 col-md-9" style="text-align: right;">
                    <button class="btn btn-success" (click)="addPowerBIReport()">Add</button>&nbsp;
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let report of selectedCompany.powerBIReports">
                            <td>{{report.name}}</td>
                            <td>
                                <div class="btn" style="padding: 0.1rem 0.1rem" title="Delete company">
                                    <div class="font-icon-wrapper-medium" (click)="deletePowerBIReport(report)">
                                        <i class="pe-7s-trash"></i>
                                    </div>
                                </div>
                            </td>                            
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">&nbsp;</div>
            <div style="text-align: right;">
                <button class="btn btn-success" (click)="saveCompany()">Save company</button>
            </div>            
        </div>
    </div>
</div>