import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NgxSpinnerService } from 'ngx-spinner';
import { WamCustomSegment } from 'src/app/models/services/wamCustomSegment';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';

@Component({
  selector: 'app-custom-segment-attributes',
  templateUrl: './custom-segment-attributes.component.html',
  styleUrls: ['./custom-segment-attributes.component.sass']
})
export class CustomSegmentAttributesComponent implements OnInit {
  @ViewChild('matAutocompleteCustomSegments') inputAutocompleteCustomSegments: ElementRef<HTMLInputElement>;

  @Input() 
  form: FormGroup;

  @Input() 
  updateValueAndValidity: () => void;

  customSegments: WamCustomSegment[] = [];
  filteredCustomSegments: WamCustomSegment[] = [];
  newCustomSegment: string = '0';

  constructor(
    private audiencesServices: AudiencesService,
    private spinnerService: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinnerService.show("dialogSpinner");
    this.audiencesServices.getWamCustomSegments().subscribe(
      resp => {
        this.customSegments = resp;
        this.filteredCustomSegments = resp;
        
        this.onNewCustomSegmentSelectedChange();

        this.updateValueAndValidity();

        this.spinnerService.hide("dialogSpinner");
      }
    )
  }

  resetCustomSegmentsFilter = (): void => {
    this.inputAutocompleteCustomSegments.nativeElement.value = '';
    this.form.controls.customSegmentId.setValue(null);
    this.form.controls.customSegmentDescription.setValue(null);
    this.filteredCustomSegments = this.customSegments;
    this.updateValueAndValidity();
  }
    
  filterCustomSegments = (): void => {
    const filterValue = this.inputAutocompleteCustomSegments.nativeElement.value.toLowerCase();
    this.filteredCustomSegments = this.customSegments.filter(o => o.name.toLowerCase().includes(filterValue));
    this.updateValueAndValidity();
  }

  onCustomSegmentChange = ($event: MatAutocompleteSelectedEvent): void => {    
    this.inputAutocompleteCustomSegments.nativeElement.blur();
    this.form.controls.customSegmentDescription.setValue(this.inputAutocompleteCustomSegments.nativeElement.value);
    this.updateValueAndValidity();
  }

  getCustomSegmentDescription = (customSegmentId?: string): string => {
    return this.customSegments.find(x => x.id == customSegmentId)?.name;
  }

  onNewCustomSegmentSelectedChange = (): void => {
    this.form.controls.customSegmentId.setValue(null);    
    this.form.controls.customSegmentDescription.setValue(null);
    if (!!(+this.newCustomSegment))
      this.form.controls.customSegmentId.setValidators([]);
    else
      this.form.controls.customSegmentId.setValidators([Validators.required]);

    this.form.controls.customSegmentId.updateValueAndValidity();
    this.updateValueAndValidity();
  }  

  onCustomSegmentDescriptionChange = (): void => {
    this.updateValueAndValidity();
  }
  
}
