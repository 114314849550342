import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';
import { Company } from 'src/app/models/services/company';
import { DialogService } from 'src/app/services/core/dialog.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { InputNameDialogService } from 'src/app/services/core/input-name-dialog.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { forkJoin } from 'rxjs';
import { PowerBIReportsService } from 'src/app/services/insights/power-bi-reports.service';
import { PredefinedReportsService } from 'src/app/services/predefined-reports.service';
import { PowerBIReport } from 'src/app/models/services/powerBIReport';
import { PredefinedReport } from 'src/app/models/services/predefinedReport';
import { GenericIdNameSelectorDialogService } from 'src/app/services/dialogs/generic-id-name-selector-dialog.service';
import { GenericItem } from 'src/app/models/components/generic-item';
import { environment } from 'src/environments/environment';
import { CompanyLogoUploadDialogService } from 'src/app/services/dialogs/company-logo-upload-dialog.service';

@Component({
  selector: 'app-admin-companies',
  templateUrl: './admin-companies.component.html',
  styleUrls: ['./admin-companies.component.sass']
})
export class AdminCompaniesComponent implements OnInit, OnDestroy {
  // Flatten definition
  private flatten = (x) => x.reduce((a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []);
  
  heading: string = 'Companies';
  subheading: string = '';
  icon: string = 'pe-7s-box2 icon-gradient bg-midnight-bloom';

  companies: Company[];
  powerBIReports: PowerBIReport[];
  predefinedReports: PredefinedReport[];

  loadFinished: boolean = false;
  selectedCompany: Company;
  selectedCompanyId?: number;
  logoVersion: number = 0;

  constructor(
    public usersService: UsersService,
    private companiesService: CompaniesService,
    private powerBIReportsService: PowerBIReportsService,
    private predefinedService: PredefinedReportsService,
    private globalsService: GlobalsService,
    private inputNameDialogService: InputNameDialogService,
    private genericIdNameSelectorDialogService: GenericIdNameSelectorDialogService,
    private companyLogoUploadDialogService: CompanyLogoUploadDialogService,
    private globals: ThemeOptions,    
    private dialogService: DialogService,
    private spinnerService: NgxSpinnerService) { 

    }

  ngOnInit(): void {
    this.globals.adminMode = true;

    // Wait for all data received
    forkJoin([
      this.powerBIReportsService.getAll(),
      this.predefinedService.getAll()    
    ]).subscribe(resp => {
      this.powerBIReports = resp[0].sort((x, y) => x.name.toUpperCase() > y.name.toUpperCase() ? 1 : x.name.toUpperCase() < y.name.toUpperCase() ? -1 : 0);
      this.predefinedReports = resp[1].sort((x, y) => x.name.toUpperCase() > y.name.toUpperCase() ? 1 : x.name.toUpperCase() < y.name.toUpperCase() ? -1 : 0);

      this.loadFinished = true;
      this.spinnerService.hide();
    })    
    
    this.companies = this.globalsService.getCompanies().sort((x, y) => x.name.toUpperCase() > y.name.toUpperCase() ? 1 : x.name.toUpperCase() < y.name.toUpperCase() ? -1 : 0);    
  }
  
  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }

  onChangeCompany = (): void => {
    this.selectedCompany = this.companies.find(x => x.id == this.selectedCompanyId);
    this.logoVersion = 0;
    // TODO: remove temporary logos    
  }
  
  addCompany = (): void => {
    this.inputNameDialogService.show('New company', '', name => {
      this.spinnerService.show();
      let company: Company = {
        name: name
      };
      this.companiesService.addCompany(company).subscribe(() => {
        this.companiesService.getAll().subscribe(resp => {
          this.globalsService.setCompanies(resp);
          this.companies = resp;
          this.spinnerService.hide();
          this.dialogService.showMessage("Companies management", "Company added");
        });

      });
    });
  }

  saveCompany = (): void => {
    this.spinnerService.show();
    this.companiesService.updateCompany(this.selectedCompany).subscribe(() => {
      this.spinnerService.hide();
      this.dialogService.showMessage("Companies management", "Company updated");
    });
  }

  deleteCompany = (company: Company): void => {
    this.dialogService.showConfirmation(`Are you sure that you want to delete company '${company.name}'`, () => {
      this.spinnerService.show();
      this.companiesService.deleteCompany(company.id).subscribe(() => {
        this.companiesService.getAll().subscribe(resp => {
          this.globalsService.setCompanies(resp);
          this.companies = resp;
          this.spinnerService.hide();
          this.dialogService.showMessage("Companies management", "Company deleted");
        });      
      });
    });
  }

  addPredefinedReport = (): void => {
    this.genericIdNameSelectorDialogService.showSelector(
      'Select a predefined report',
      this.predefinedReports.map((x): GenericItem => { return { id: x.id, name: x.description }; }),
      this.selectedCompany.predefinedReports?.map((x): GenericItem => { return { id: x.id, name: x.name }; }),
      selectedItem => {
        var predefinedReport: PredefinedReport = { id: selectedItem.id, name: selectedItem.name, description: selectedItem.name };
        if(!this.selectedCompany.predefinedReports)
          this.selectedCompany.predefinedReports = [ predefinedReport ];
        else
          this.selectedCompany.predefinedReports.push(predefinedReport);
      }
    );
  }

  deletePredefinedReport = (report: PredefinedReport): void => {
    const index : number = this.selectedCompany.predefinedReports.findIndex((x) => x.id == report.id, 0);
    if (index > -1) {
      this.selectedCompany.predefinedReports.splice(index, 1);
    }    
  }

  addPowerBIReport = (): void => {
    this.genericIdNameSelectorDialogService.showSelector(
      'Select a Power BI report',
      this.powerBIReports.map((x): GenericItem => { return { id: x.id, name: x.name }; }),
      this.selectedCompany.powerBIReports?.map((x): GenericItem => { return { id: x.id, name: x.name }; }),
      selectedItem => {
        var powerBIReport: PowerBIReport = { id: selectedItem.id, name: selectedItem.name };
        if(!this.selectedCompany.powerBIReports)
          this.selectedCompany.powerBIReports = [ powerBIReport ];
        else
          this.selectedCompany.powerBIReports.push(powerBIReport);
      }
    );
  }

  deletePowerBIReport = (report: PredefinedReport): void => {
    const index : number = this.selectedCompany.powerBIReports.findIndex((x) => x.id == report.id, 0);
    if (index > -1) {
      this.selectedCompany.powerBIReports.splice(index, 1);
    }    
  }

  getSelectedCompanyLogoSrc = (): string => {
    return this.logoVersion > 0 ?
      `${environment.assetsServicesApiBaseUri}/companies/${this.selectedCompany.id}/logo/tmp?v=${this.logoVersion}` :
      `${environment.assetsServicesApiBaseUri}/companies/${this.selectedCompany.id}/logo`;    
  }

  uploadCompanyLogo = (): void => {
    this.companyLogoUploadDialogService.show(this.selectedCompany, () => { this.logoVersion++;});
  }
}
