import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Option, QueryBuilderConfig, Rule, RuleSet } from 'angular2-query-builder';
import { DialogService } from 'src/app/services/core/dialog.service';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';
import { TaxonomyItem } from '../../../models/services/taxonomiesConfig';
import { AudienceQueryCountRequest } from 'src/app/models/services/audienceQueryCount';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AudienceParametersDialogService } from 'src/app/services/dialogs/audience-parameters-dialog.service';
import { CreateAudienceRequest } from 'src/app/models/services/createAudience';
import { AudienceParameters } from 'src/app/models/services/audience';
import { UsersService } from 'src/app/services/users.service';
import { AudienceSelectorDialogService } from 'src/app/services/dialogs/audience-selector-dialog.service';
import { BaseTenantRequiredPageComponent } from 'src/app/components/core/base-tenant-required-page/base-tenant-required-page.component';
import { GlobalsService } from 'src/app/services/globals.service';
import { AudienceRuleBuilderDialogService } from 'src/app/services/dialogs/audience-rule-builder-dialog.service';
import { AudiencesBuilderConfigService } from 'src/app/services/audiences/audiences-builder-config.service';

@Component({
  selector: 'app-audiences-builder-v2',
  templateUrl: './audiences-builder-v2.component.html',
  styleUrls: ['./audiences-builder-v2.component.sass']
})

export class AudiencesBuilderV2Component extends BaseTenantRequiredPageComponent implements OnInit, DoCheck {
  // Flatten definition
  private flatten = (x) => x.reduce((a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []);

  // Public properties
  heading: string = 'Audiences builder';
  subheading: string = '';
  icon: string = 'pe-7s-radio icon-gradient bg-midnight-bloom';
  loadFinished: boolean = false;
  renderingQueryBuilder: boolean = false;
  environment = environment;
  dragging: boolean = false;
  query: RuleSet = {
    condition: 'and',
    rules: [
    ]
  };
  config: QueryBuilderConfig = {
    fields: {}
  }; // = queryBuilderConfig;
  customOperatorMap: { [key: string]: string[] } = {
    string: ['=', '!=', 'contains', 'like'],
    number: ['=', '!=', '>', '>=', '<', '<='],
    time: ['=', '!=', '>', '>=', '<', '<='],
    date: ['=', '!=', '>', '>=', '<', '<='],
    category: ['=', 'in'],
    boolean: ['='],
    audience: ['in', 'not in']
  };  
  taxonomy: TaxonomyItem[] = [];
  resultsCountData = null;
  segmentId: string;
  segmentName: string;
  segmentRecordCount: number;
  loading: boolean = false;

  private rulesetIds: Map<object, string> = new Map<object, string>();
  private resultsJSONQuery: string;

  constructor(
    public usersService: UsersService,
    private audiencesService: AudiencesService,
    private audiencesBuilderConfigService: AudiencesBuilderConfigService,
    private dialogService: DialogService,
    private audienceParametersDialogServiceService: AudienceParametersDialogService,
    private audienceSelectorDialogService: AudienceSelectorDialogService,
    private audienceRuleBuilderDialogService: AudienceRuleBuilderDialogService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    globalsService: GlobalsService,
    router: Router) {
      super(
        usersService.hasSelectedCompanyModuleRight('Audiences', 'AudienceBuilder'),
        globalsService, 
        router);
  }

  ngOnInit(): void {
    if (this.redirecting)
      return;

    this.spinnerService.show();
    this.segmentId = this.route.snapshot.paramMap.get('segmentId');
    this.segmentName = this.route.snapshot.paramMap.get('segmentName');
    this.segmentRecordCount = +this.route.snapshot.paramMap.get('segmentRecordCount');

    this.audiencesBuilderConfigService.getAudienceBuilderConfigV2().subscribe(
      resp => {
        this.config = resp.queryBuilderConfig;        
        this.taxonomy = resp.taxonomy;
        this.loadFinished = true;
        this.spinnerService.hide();
      },
      () => {
        this.spinnerService.hide();
      }
    )
  
  }

  ngDoCheck(): void {
    if (JSON.stringify(this.query) != this.resultsJSONQuery)
      this.resultsCountData = null;

    if (this.renderingQueryBuilder) {
      this.spinnerService.hide();
      this.renderingQueryBuilder = false;
    }
  }

  public showQuery = () => {
    this.dialogService.showError(JSON.stringify(this.query));
  }

  getIdFromRuleset = (ruleset: RuleSet): string => {
    let id: string = this.rulesetIds.get(ruleset);
    if (!id) {
      id = `rulesetContainer_${this.rulesetIds.size + 1}`;
      this.rulesetIds.set(ruleset, id);
    }

    return id;
  }

  refreshCount = (): void => {

    this.spinnerService.show();
    var request: AudienceQueryCountRequest = {
      query: this.query
    };

    this.audiencesService.getAudienceCounts(request).subscribe(
      resp => {
        this.resultsCountData = {
          name: "Total",
          color: "#93f291",
          children: [
            {
              value: resp.audienceCount,
              color: "#34a832"
            },
            {
              value: resp.totalCount - resp.audienceCount,
              color: "#93f291"
            }
          ]
        };
        this.resultsJSONQuery = JSON.stringify(this.query);
        this.spinnerService.hide();
      },
      () => {
        this.spinnerService.hide();
      }
    );
  }

  createAudience = (): void => {
    this.audienceParametersDialogServiceService.show(
      (audienceParameters: AudienceParameters) => {
        // Save audience
        this.spinnerService.show();
        let request: CreateAudienceRequest = {
          name: audienceParameters.name,
          categoryId: audienceParameters.categoryId,
          query: this.query          
        };
        if(!!audienceParameters.schedulingId && +audienceParameters.schedulingId > 0) {
          request.schedulingId = audienceParameters.schedulingId;
          request.schedulingEndDate = audienceParameters.schedulingEndDate
        }

        this.audiencesService.createAudience(request).subscribe(
          () => {
            this.spinnerService.hide();
            this.dialogService.showMessage("Create audience", "Audience created");
          },
          () => {
            this.spinnerService.hide();
          }
        );
      },
      () => { }
    );
  }

  getQueryFromAudience = (): void => {
    this.audienceSelectorDialogService.showAudienceSelector(
      (audienceId:number) => {
        this.spinnerService.show();
        this.audiencesService.getAudience(audienceId).subscribe(resp => {
          this.query = resp.query;
          this.spinnerService.hide();
        });
      },
      () => {}
    );
  } 

  addCustomRule = (ruleset: RuleSet, rulesetId: string) => {
    this.audienceRuleBuilderDialogService.show((rule: Rule) => {
      if (rulesetId === 'rulesetContainer_2') 
        this.query.rules.push(rule);
      else
        ruleset.rules.push(rule);
    }, 
    this.taxonomy);
  };

  getOptions(field: string): Option[] {
    if (this.config.getOptions) {
      return this.config.getOptions(field);
    }
    return this.config.fields[field].options || [];
  }

  getAudienceDescription = (id: number): string => {
    let result: string = this.config.fields.fieldAudiences.options.find(x => x.value == id).name;

    return 'Audience ' + result ?? '???????';
  }
}
