import { Component, OnDestroy, OnInit } from '@angular/core';
import { PublicisTableDataSource } from 'src/app/models/components/publicis-table';
import { ThemeOptions } from 'src/app/theme-options';
import { AdminService } from 'src/app/services/admin.service';
import { DataTransfer } from 'src/app/models/services/dataTransferInfo';
import { NgxSpinnerService } from 'ngx-spinner';
import { Data } from '@angular/router';
import { formatDate } from '@angular/common';
import { AudiencesFilesAttributes } from 'src/app/models/services/audiencesFilesAttributes.enum';
import { DataTransferInfoDialogService } from 'src/app/services/dialogs/data-transfer-info-dialog.service';

@Component({
  selector: 'app-admin-data-transfers',
  templateUrl: './admin-data-transfers.component.html',
  styleUrls: ['./admin-data-transfers.component.sass']
})
export class AdminDataTransfersComponent implements OnInit, OnDestroy {
  public heading: string = 'Data transfers list';
  public subheading: string = '';
  public icon: string = 'pe-7s-radio icon-gradient bg-midnight-bloom';

  private originalDataTransfers: DataTransfer[];
  public dataTransfersDataSource: PublicisTableDataSource = {
    tableDefinition: [
      {
        headerDescription: 'ID',
        enableSort: true,
        sortFieldName: 'wamDataTransfer.id'
      }, {
        headerDescription: 'Name',
        enableSort: true,
        sortFieldName: 'wamDataTransfer.name'
      }, {
        headerDescription: 'Status',
        enableSort: true,
        sortFieldName: 'wamDataTransfer.status'
      }, {
        headerDescription: 'DSP',
        enableSort: true,
        sortFieldName: 'wamDSP.name'
      }, {
        headerDescription: 'CPM',
        enableSort: true,
        sortFieldName: 'wamDataTransfer.cpm'      
      }, {
        headerDescription: 'Created',
        enableSort: true,
        sortFieldName: 'wamDataTransfer.createdDate'      
      }, {
        headerDescription: 'Expiration',
        enableSort: true,
        sortFieldName: 'wamDataTransfer.endDate'      
      }, {
        headerDescription: '',
        enableSort: false
      }
    ],
    data: [],
    enableSort: true,
    enablePagination: true,
    pageSize: 10
  };

  public dataTransfersFilter: string;

  constructor(
    private adminService: AdminService,
    private dataTransferInfoDialogService: DataTransferInfoDialogService,
    private globals: ThemeOptions,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.show();
    this.globals.adminMode = true;
    this.loadDataTransfers();
  }

  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }

  private loadDataTransfers = (): void => {
    this.adminService.getDataTransfers().subscribe(
      resp => {
        this.originalDataTransfers = resp.wamInfo.map(dt => ( { 
            ...dt, 
            audienceFiles: resp.publicisInfo.filter(x => (!!x.fileAttributes.find(y => y.attributeId == AudiencesFilesAttributes.CustomSegmentId && y.value == dt.wamCustomSegment?.id))) 
          })
        );
        this.filterDataTransfers(this.dataTransfersFilter);

        this.spinnerService.hide();
      },
      () => { this.spinnerService.hide(); }
    )
  }


  filterDataTransfers = (searchTerm: string): void => {
    this.dataTransfersFilter = searchTerm;

    // We need to build the full object in order to make the publicis-table component detect changes
    let cloned: PublicisTableDataSource = { ...this.dataTransfersDataSource};
    cloned.data =         
      !!searchTerm ? 
      this.originalDataTransfers.filter((x: DataTransfer) => { 
        return (
          x.wamDataTransfer.name.toUpperCase().indexOf(searchTerm.toUpperCase()) != -1 
        ); 
      } ) :
      this.originalDataTransfers;
    this.dataTransfersDataSource = cloned;
  }

  downloadWAMInfoCsv = (): void => {
    this.spinnerService.show();
    this.adminService.downloadDataTransfersWAMInfoCsv().subscribe(
      (result: Blob) => {
       
        let fileName: string = `${formatDate(Date.now(), 'yyyy-MM-dd hhmmss', 'ES')} DataTransfers WAM Info.csv`;
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = URL.createObjectURL(result);
        a.download = fileName;

        this.spinnerService.hide();

        a.click();
        URL.revokeObjectURL(a.href)
        a.remove();
      },
      () => {
        this.spinnerService.hide();
      });
  }

  downloadPublicisInfoCsv = (): void => {
    this.spinnerService.show();
    this.adminService.downloadDataTransfersPublicisInfoCsv().subscribe(
      (result: Blob) => {
       
        let fileName: string = `${formatDate(Date.now(), 'yyyy-MM-dd hhmmss', 'ES')} DataTransfers Publicis Info.csv`;
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = URL.createObjectURL(result);
        a.download = fileName;

        this.spinnerService.hide();

        a.click();
        URL.revokeObjectURL(a.href)
        a.remove();
      },
      () => {
        this.spinnerService.hide();
      });
  }

  showDataTransferInfo = (dataTransfer: DataTransfer): void => {
    this.dataTransferInfoDialogService.show(dataTransfer);
  }

}