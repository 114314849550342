import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Audience } from 'src/app/models/services/audience';
import { Tag } from 'src/app/models/services/tag';
import { AudiencesListFilter } from 'src/app/models/ui/audiencesListFilter';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';

@Component({
  selector: 'app-audience-list-filter-dialog',
  templateUrl: './audience-list-filter-dialog.component.html',
  styleUrls: ['./audience-list-filter-dialog.component.sass']
})
export class AudienceListFilterDialogComponent implements OnInit {
  public accept: () => void;
  public dismiss: () => void;
  public tags: Tag[];
  public filter: AudiencesListFilter;
  public textSearchFormControl: FormControl = new FormControl();
  public tagsSearch: Tag[] = [];

  constructor() { }

  ngOnInit(): void {
    this.textSearchFormControl.setValue(this.filter.textSearch);
    this.tagsSearch = [...this.filter.tagsSearch];
  }

  onAcceptClick = (): void  => {   
    this.filter.textSearch = this.textSearchFormControl.value;
    this.filter.tagsSearch = this.tagsSearch;
    this.accept();
  }  

  onResetFilterClick = (): void  => {   
    this.textSearchFormControl.setValue("");
    this.tagsSearch = [];
  }  

  onShowAllTagsClick = (): void => {
    if (this.tagsSearch.length > 0) {
      this.tagsSearch = [];
    }
  }

  onShowTagClick = (tag: Tag): void => {
    if (!this.isTagInFilter(tag))
      this.tagsSearch.push(tag);
    else
      this.tagsSearch.splice(this.tagsSearch.findIndex(x => x.id == tag.id), 1);
  }

  isTagInFilter = (tag: Tag): boolean => {
    return this.tagsSearch.findIndex(x => x.id == tag.id) >= 0;
  }

}


