<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
<div class="row" *ngIf="usersService.hasGlobalSectionRight('Admin')">
    <div class="col-md-5"></div>
    <div class="col-md-2">
        <app-page-title heading="Data transfers" subheading="Manage and monitor" icon="pe-7s-copy-file" class="btn"
            (click)="navigateTo('/admin/data-transfers')">
        </app-page-title>
    </div>
    <div class="col-md-5"></div><!--
    <div class="col-md-2">
        <app-page-title heading="Tools" subheading="Schedule audiences and more" icon="pe-7s-radio" class="btn"
            (click)="navigateTo('/audiences/scheduler')"></app-page-title>
    </div>
    <div class="col-md-2"></div>-->
</div>