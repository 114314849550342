
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AudienceCategory, AudienceParameters, AudienceScheduling, Scheduling } from 'src/app/models/services/audience';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-audience-parameters-dialog',
  templateUrl: './audience-parameters-dialog.component.html',
  styleUrls: ['./audience-parameters-dialog.component.sass']
})
export class AudienceParametersDialogComponent implements OnInit {

  public accept: (data: AudienceParameters) => void;
  public dismiss: () => void;
  public audienceParameters?: AudienceParameters;
  public isUpdate: boolean;

  currentSchedulingDescription: string = "No scheduling";
  audiencesSchedulings: Scheduling[] = [
    {
      id: 1,
      description: 'No scheduling'
    },
    {
      id: 2,
      description: 'Weekly'
    },
    {
      id: 3,
      description: 'Bi-weekly'
    },
    {
      id: 4,
      description: 'Monthly'
    },
    {
      id: 5,
      description: 'Each two days'
    }        
  ];  
  
  form: FormGroup;
  name: string;
  title: string = "Create audience"; 
  audiencesCategories: AudienceCategory[] = [];
  currentCategoryDescription: string = "Select a category";

  constructor(
    public usersService: UsersService,
    private audiencesServices: AudiencesService,
    private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group(
      {
        categoryId: ['', Validators.required],
        name: ['', Validators.required],
        schedulingId: [1, Validators.required],
        schedulingEndDate: ['']
      });
  }

  ngOnInit(): void {
    this.isUpdate = !!this.audienceParameters;
    this.audiencesServices.getAudiencesCategories().subscribe(
      resp => {
        this.audiencesCategories = resp;
        if (!!this.audienceParameters) {
          this.title = "Update audience";
          this.form.controls.name.setValue(this.audienceParameters.name);
          let audienceCategory: AudienceCategory = this.audiencesCategories.find(x => x.id == this.audienceParameters.categoryId);
          if (!!audienceCategory) {
            this.form.controls.categoryId.setValue(audienceCategory.id);
          }      
          let audienceScheduling: AudienceScheduling = this.audiencesSchedulings.find(x => x.id == this.audienceParameters.schedulingId);
          if (!!audienceScheduling) {
            this.form.controls.schedulingId.setValue(audienceScheduling.id);
            this.form.controls.schedulingEndDate.setValue(this.audienceParameters.schedulingEndDate);
          }      

        }
      }
    )
  }

  selectCategory = (category: AudienceCategory): void => {
    this.form.controls.categoryId.setValue(category.id);
  }

  selectScheduling = (scheduling: AudienceScheduling): void => {
    this.form.controls.schedulingId.setValue(scheduling.id);
  }

  onAccept = (): void => {    
    let result: AudienceParameters = {
      categoryId: +this.form.controls.categoryId.value,
      name: this.form.controls.name.value,
      schedulingId: !!this.form.controls.schedulingId.value ? +this.form.controls.schedulingId.value : 0
    };
    if (result.schedulingId > 0) {
      let date: Date = this.form.controls.schedulingEndDate.value;
      if (!!date) {
        if(!!(date as Date))
          date = new Date(date);  
        let year = date.getFullYear();
        let month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    
        result.schedulingEndDate = new Date(`${year}-${month}-${day}T00:00:00Z`)  
      }
    }

    this.accept(result);
  };

  onDismiss = (): void => {
    this.dismiss();
  }

  filterValidDates = (d: Date): boolean => {
    if (!!!d)
      return false;

    return d > new Date();
  }    
}


