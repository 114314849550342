import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-viewer-logan',
  templateUrl: './report-viewer-logan.component.html',
  styleUrls: ['./report-viewer-logan.component.sass']
})
export class ReportViewerLoganComponent implements OnInit {

  @Input()
  public selectedReport: any;

  constructor() { }

  ngOnInit(): void {
  }

}
