<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <div class="form-group row">
                <div class="col-md-6">
                    <mat-form-field>
                        <mat-label>Scheduling</mat-label>
                        <mat-select [formControl]="form.controls.schedulingId">
                            <mat-option *ngFor="let audienceScheduling of audiencesSchedulings" [value]="audienceScheduling.id">{{audienceScheduling.description}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.controls.schedulingId.errors && (form.controls.schedulingId.dirty || form.controls.schedulingId.touched)">Scheduling is required</mat-error>
                    </mat-form-field>    
                </div> 
                <div class="col-md-6" *ngIf="+form.controls.schedulingId.value > 1">
                    <mat-form-field>
                        <mat-label>Scheduling end date</mat-label>
                        <input matInput [matDatepicker]="picker" [formControl]="form.controls.schedulingEndDate" [value]="form.controls.schedulingEndDate.value" [matDatepickerFilter]="filterValidDates">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>              
                </div>
            </div>            
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onAccept()"
        [disabled]="form.invalid">Accept</button>
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">Cancel</button>
</div>