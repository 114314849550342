import { Injectable } from '@angular/core';
import { LookAlikeParametersTaxonomy } from '../models/services/lookAlikeTaxonomy';
import { LookAlike } from '../models/services/lookAlike';
import { environment } from 'src/environments/environment';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';
import { LookAlikeSourceTypes } from '../models/services/lookAlikeSourceTypes.enum';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class LookAlikesService {

  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService
  ) { }

  public calculateLookAlikesParameters(sourceType: LookAlikeSourceTypes, sourceIdentifier: string): Observable<void> {
    let url: string = `${this.getUrlBase(sourceType)}/${sourceIdentifier}/look-alike-parameters/calculate`;
    return this.httpService.postNoResponse(url, null, this.globalsService.getActiveCompany()?.id);
  }

  public getLookAlikesParameters(sourceType: LookAlikeSourceTypes, sourceIdentifier: string): Observable<Array<LookAlikeParametersTaxonomy>> {
    let url: string = `${this.getUrlBase(sourceType)}/${sourceIdentifier}/look-alike-parameters`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }
  
  public getLookAlikes(sourceType: LookAlikeSourceTypes, sourceIdentifier: string): Observable<Array<LookAlike>> {
    let url: string = `${this.getUrlBase(sourceType)}/${sourceIdentifier}/look-alikes`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }
  
  public createLookAlike(sourceType: LookAlikeSourceTypes, lookAlike: LookAlike): Observable<LookAlike> {
    let url: string = `${this.getUrlBase(sourceType)}/${lookAlike.sourceIdentifier}/look-alikes`;
    lookAlike.companyId = this.globalsService.getActiveCompany()?.id;
    return this.httpService.post(url, lookAlike, lookAlike.companyId);
  } 
  
  public removeLookAlike(sourceType: LookAlikeSourceTypes, lookAlike: LookAlike): Observable<void> {
    let url: string = `${this.getUrlBase(sourceType)}/${lookAlike.sourceIdentifier}/look-alikes/${lookAlike.id}`;
    lookAlike.companyId = this.globalsService.getActiveCompany()?.id;
    return this.httpService.deleteNoResponse(url, lookAlike.companyId);
  }

  public downloadLookAlikesParametersCsv(sourceType: LookAlikeSourceTypes, sourceIdentifier: string): Observable<Blob> {
    let url: string = `${this.getUrlBase(sourceType)}/${sourceIdentifier}/look-alike-parameters/csv`;
    return this.httpService.getFile(url, this.globalsService.getActiveCompany()?.id);
  }
  
  private getUrlBase = (sourceType: LookAlikeSourceTypes): string => {
    switch(sourceType) {
      case LookAlikeSourceTypes.Audience:
        return `${environment.lookAlikesServicesApiBaseUri}/audiences`;
      default:
        return `${environment.lookAlikesServicesApiBaseUri}/segments`;
    }
  }
}
