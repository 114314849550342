import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Audience } from 'src/app/models/services/audience';
import { Tag } from 'src/app/models/services/tag';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';
import { Observable, of } from 'rxjs'

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export function existingTagValidator(tags: Tag[]): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> | null => {
    let err: ValidationErrors = null;
    if (tags.findIndex(x => x.name == control.value) >= 0)
      err = { existingTag: { value: control.value } };

    return of(err);
  };
}

@Component({
  selector: 'app-audience-tags-dialog',
  templateUrl: './audience-tags-dialog.component.html',
  styleUrls: ['./audience-tags-dialog.component.sass']
})
export class AudienceTagsDialogComponent implements OnInit {

  public accept: () => void;
  public dismiss: () => void;
  public audience: Audience;
  public newTagFormControl: FormControl = new FormControl(); // It will be redefined later
  
  title: string = "Audience tags"; 
  allTags: Tag[];
  selectedTags: Tag[];
  nonSelectedTags: Tag[];
  matcher: MyErrorStateMatcher = new ErrorStateMatcher();

  constructor(
    private audiencesService: AudiencesService,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.show('dialogSpinner');
    this.audiencesService.getAllTags().subscribe((response: Tag[]) => {
      this.allTags = response;
      this.selectedTags = [...this.audience.tags];
      this.nonSelectedTags = response.filter(x => this.selectedTags.findIndex(y => y.id == x.id) == -1);
      this.newTagFormControl  = new FormControl('', Validators.required, existingTagValidator(this.allTags));
      this.spinnerService.hide('dialogSpinner');
    }, () =>  {
      this.spinnerService.hide('dialogSpinner');
    })
  }

  onAccept = (): void  => {   
    this.audience.tags = this.selectedTags;
    this.accept();
  }

  onDismiss = (): void => {
    this.dismiss();
  }

  acceptButtonEnabled = (): boolean => {
    return true;
  }

  removeTag = (tag: Tag): void => {
    this.nonSelectedTags.push(tag);
    this.selectedTags.splice(this.selectedTags.findIndex(x => x.name == tag.name), 1); // Maybe it's a new one which has no id
  }

  addTag = (tag: Tag): void => {
    this.selectedTags.push(tag);
    this.nonSelectedTags.splice(this.nonSelectedTags.findIndex(x => x.id == tag.id), 1);
  }

  addNewTag = (): void => {
    this.selectedTags.push({
      name: this.newTagFormControl.value
    });
    this.newTagFormControl.reset();
  }  
}