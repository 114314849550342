import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PowerBIPortalGroup } from 'src/app/models/services/powerBIPortalGroup';
import { PowerBIPortalReport } from 'src/app/models/services/powerBIPortalReport';
import { PowerBIReport } from 'src/app/models/services/powerBIReport';
import { PowerBIReportsService } from 'src/app/services/insights/power-bi-reports.service';

@Component({
  selector: 'app-powerbi-report-parameters-dialog',
  templateUrl: './powerbi-report-parameters-dialog.component.html',
  styleUrls: ['./powerbi-report-parameters-dialog.component.sass']
})
export class PowerBIReportParametersDialogComponent implements OnInit {
  form: FormGroup;
  name: string;
  title: string = "Power BI Report";
  message: string;
  powerBIPortalGroups: PowerBIPortalGroup[];
  powerBIPortalGroupReports: PowerBIPortalReport[];
  groupsSelectorText: string = "Select a workspace";
  reportsSelectorText: string = "Select a report";

  constructor(
    private powerBIReportsService: PowerBIReportsService,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService
  ) {
    this.spinnerService.show('PowerBIReportParametersDialogComponentSpinner');

    this.powerBIReportsService.getPowerBIPortalGroups().subscribe(x => {
      this.powerBIPortalGroups = x;
      this.spinnerService.hide('PowerBIReportParametersDialogComponentSpinner');
    });

    this.form = this.formBuilder.group(
      {
        groupId: ['', Validators.required],
        powerBIReportId: ['', Validators.required],
        name: ['', Validators.required]
      });
  }

  ngOnInit(): void {
  }

  onGroupChange = (group: PowerBIPortalGroup): void => {
    this.spinnerService.show('PowerBIReportParametersDialogComponentSpinner');
    this.form.controls.groupId.setValue(group.id);
    this.form.controls.powerBIReportId.setValue('');
    this.groupsSelectorText = group.name;    
    this.reportsSelectorText = "Select a report";    
    this.powerBIReportsService.getPowerBIPortalGroupReports(group.id).subscribe(x => {
      this.powerBIPortalGroupReports = x;
      this.spinnerService.hide('PowerBIReportParametersDialogComponentSpinner');
    });
  }

  onReportChange = (report: PowerBIPortalReport): void => {
    this.form.controls.powerBIReportId.setValue(report.id);    
    this.reportsSelectorText = report.name;
  }

  onAccept = (): void => {
    let result: PowerBIReport = {
      workspaceId: this.form.controls.groupId.value,
      powerBIId: this.form.controls.powerBIReportId.value,
      name: this.form.controls.name.value
    };

    this.accept(result);
  }

  public accept: (powerBIReport: PowerBIReport) => void;
  public dismiss: () => void;
}