<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" *ngIf="isReportConfigured(1)">
    <div class="col-md-12">
        <publicis-id-insights-idgraph-counts></publicis-id-insights-idgraph-counts>
    </div>
</div>
<div class="row">
    <div class="col-md-6" *ngIf="isReportConfigured(2)">
        <publicis-id-insights-gender-per-age-ranges></publicis-id-insights-gender-per-age-ranges>
    </div>
    <div class="col-md-6" *ngIf="isReportConfigured(3)">
        <publicis-id-insights-parental-status-per-household-sizes></publicis-id-insights-parental-status-per-household-sizes>
    </div>
</div>
<div class="row" *ngIf="isReportConfigured(4)">
    <div class="col-md-12">
        <publicis-id-insights-contextual-interests></publicis-id-insights-contextual-interests>
    </div>
</div>
<div class="row">
    <div class="col-md-6" *ngIf="isReportConfigured(5)">
        <publicis-id-insights-mobile-apps-interests></publicis-id-insights-mobile-apps-interests>
    </div>
    <div class="col-md-6" *ngIf="isReportConfigured(6)">
        <publicis-id-insights-profile-updates></publicis-id-insights-profile-updates>
    </div>
</div>
<div class="row" *ngIf="isReportConfigured(7)">
    <div class="col-md-12">
        <publicis-id-insights-purchase-intents></publicis-id-insights-purchase-intents>
    </div>
</div>

