import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { XandrAudiencesService } from 'src/app/services/audiences/xandr-audiences.service';
import { Account } from 'src/app/models/services/audiences/xandr/account';
import { Segment } from 'src/app/models/services/audiences/xandr/segment';

@Component({
  selector: 'app-xandr-attributes',
  templateUrl: './xandr-attributes.component.html',
  styleUrls: ['./xandr-attributes.component.sass']
})
export class XandrAttributesComponent implements OnInit {
    NonExistingSegmentName: ValidatorFn = (formControl: FormControl) => {
      const existingSegment = this.segments.find(x => x.shortName == formControl.value);
      if (!!existingSegment) {
        return { existingSegmentName: true };
      }
      return null;
    }

    @ViewChild('matAutocompleteAccounts') inputAutocompleteAccounts: ElementRef<HTMLInputElement>;
    @ViewChild('matAutocompleteSegments') inputAutocompleteSegments: ElementRef<HTMLInputElement>;
  
    @Input() 
    form: FormGroup;
  
    @Input() 
    updateValueAndValidity: () => void;

    @Input() 
    erisSegmentName: string;
  
    accounts: Account[] = [];
    filteredAccounts: Account[] = [];
    segments: Segment[] = [];
    filteredSegments: Segment[] = [];
    resultsExceeded: string = '';
    newSegment: string = '0';
  
    constructor(
      private xandrAudiencesServices: XandrAudiencesService,
      private spinnerService: NgxSpinnerService
    ) {
    }

    ngOnInit(): void {
      this.spinnerService.show("dialogSpinner");
      this.xandrAudiencesServices.getAccounts().subscribe(
        resp => {
          this.accounts = resp;
          this.onNewSegmentSelectedChange();
          
          this.spinnerService.hide("dialogSpinner");
          this.updateValueAndValidity();    
        }
      )
    }
   
    onAccountChange = ($event: MatAutocompleteSelectedEvent): void => {
      this.updateValueAndValidity();
      if(!!this.form.controls.accountId.value) {
        this.spinnerService.show("dialogSpinner");
        this.xandrAudiencesServices.getAccountSegments(this.form.controls.accountId.value).subscribe(
          resp => {
            this.segments = resp;
            this.spinnerService.hide("dialogSpinner");
            this.inputAutocompleteAccounts.nativeElement.blur();    
            this.updateValueAndValidity();    
          }
        )  
      }
    }
    
    getAccountDescription = (memberId?: number): string => {
      return this.accounts.find(x => x.memberId == memberId)?.accountName;
    }

    resetAccountsFilter = (): void => {
      this.inputAutocompleteAccounts.nativeElement.value = '';
      this.filteredAccounts = this.accounts;
      this.form.controls.accountId.setValue(null);
      this.updateValueAndValidity();
    }
  
    getSegmentDescription = (segmentId?: number): string => {
      return this.segments.find(x => x.id == segmentId)?.shortName;
    }
    
    resetSegmentsFilter = (): void => {
      this.inputAutocompleteSegments.nativeElement.value = '';
      this.form.controls.segmentId.setValue(null);
      this.form.controls.segmentName.setValue(null);
      this.filterSegments();
    }
  
    filterSegments = (): void => {
      const filterValue = this.inputAutocompleteSegments.nativeElement.value.toLowerCase();
      var segments = this.segments.filter(o => o.shortName.toLowerCase().includes(filterValue));
      var count = segments.length;
      if (count > 100) {
        segments = segments.slice(0, 99);
        this.resultsExceeded = "Showing first 100 segments, restrict filter for more results ";
        this.resultsExceeded += filterValue.length > 0 ? `(total items filtered ${count})` : `(total items ${count})`;
      } else
        this.resultsExceeded = '';

      this.filteredSegments = segments;
      this.updateValueAndValidity();
    }    
  
    filterAccounts = (): void => {
      const filterValue = this.inputAutocompleteAccounts.nativeElement.value.toLowerCase();
      this.filteredAccounts = this.accounts.filter(o => o.accountName.toLowerCase().includes(filterValue));
      this.updateValueAndValidity();
    }
 
    onSegmentChange = ($event: MatAutocompleteSelectedEvent): void => {    
      this.inputAutocompleteSegments.nativeElement.blur();
      this.form.controls.segmentName.setValue(this.inputAutocompleteSegments.nativeElement.value);
      this.updateValueAndValidity();
    }
  
    onSegmentDescriptionChange = (): void => {
      this.updateValueAndValidity();
    }

    onNewSegmentSelectedChange = (): void => {
      this.form.controls.segmentId.setValue(null);
      if (!!(+this.newSegment))
        this.form.controls.segmentName.setValidators([Validators.required, this.NonExistingSegmentName]);
      else
        this.form.controls.segmentName.setValidators([Validators.required]);
      this.form.controls.segmentName.setValue(!!(+this.newSegment) ? this.erisSegmentName : null);
      this.form.controls.segmentId.updateValueAndValidity();
      this.form.controls.segmentName.updateValueAndValidity();
      this.onAccountChange(null);
    }

  }
  