import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaxonomyItemSelectorDialogComponent } from 'src/app/components/dialogs/admin/taxonomy-item-selector-dialog/taxonomy-item-selector-dialog.component';
import { TaxonomyItem } from 'src/app/models/services/taxonomy';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class TaxonomyItemSelectorDialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public show = (treeData: TaxonomyItem[], acceptCallback: (selectedItem: TaxonomyItem) => void, rejectCallback?: () => void): void => {    
    var ref = this.modalService.open(TaxonomyItemSelectorDialogComponent, {
      size: 'xl'
    });
    var output: TaxonomyItem;

    // Get the OkDialog from the NgbModal object  
    let dialog: TaxonomyItemSelectorDialogComponent = ref.componentInstance as TaxonomyItemSelectorDialogComponent;

    // Set the dialog's properties
    dialog.accept = (data: TaxonomyItem) => {
      output = data;
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    dialog.treeData = treeData; 

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }
}
