import { Injectable } from '@angular/core';
import { PowerBIReport } from '../../models/services/powerBIReport';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/http.service';
import { Observable } from 'rxjs';
import { PowerBIPortalGroup } from '../../models/services/powerBIPortalGroup';
import { PowerBIPortalReport } from '../../models/services/powerBIPortalReport';

@Injectable({
  providedIn: 'root'
})
export class PowerBIReportsService {

  constructor(
    private httpService: HttpService
  ) { }

  public getAll(): Observable<PowerBIReport[]> {
    let url: string = `${environment.adminServicesApiBaseUri}/master/powerBIReports`;
    return this.httpService.get(url, undefined);
  }  

  public addPowerBIReport = (powerBIReport: PowerBIReport): Observable<void> => {
    let url: string = `${environment.adminServicesApiBaseUri}/master/powerBIReports`;
    return this.httpService.postNoResponse(url, powerBIReport, undefined);
  }    

  public getPowerBIPortalGroups(): Observable<PowerBIPortalGroup[]> {
    let url: string = `${environment.adminServicesApiBaseUri}/powerBIportal/groups`;
    return this.httpService.get(url, undefined);
  }  

  public getPowerBIPortalGroupReports(groupId:string): Observable<PowerBIPortalReport[]> {
    let url: string = `${environment.adminServicesApiBaseUri}/powerBIportal/groups/${groupId}/reports`;
    return this.httpService.get(url, undefined);
  }  

}
