<div class="card main-card mb-3">
    <div class="card-header">
        Household sizes per parental status {{ !!segmentId ? '(segment ' + segmentId + ')' : '' }}
    </div>
    <div class="card-body">
        <canvas baseChart
                [datasets]="chartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [plugins]="chartPlugins"
                [legend]="chartLegend"
                [chartType]="chartType">
        </canvas>
    </div>
</div>
