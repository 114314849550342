import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/services/user';
import { UserSelectorDialogService } from './dialogs/user-selector-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {

  constructor(
    private userSelectorDialogService: UserSelectorDialogService,
    private router: Router
    ) { 

    }

  public isImpersonationActive = (): boolean => {

    return !!sessionStorage.getItem('eris-impersonated-userId');
  }

  public getImpersonatedUserId = (): number => {

    return +sessionStorage.getItem('eris-impersonated-userId');
  }

  public impersonateUser = (): void => {
    this.userSelectorDialogService.showUserSelector((user: User) => {
      sessionStorage.setItem('eris-impersonated-userId', user.id.toString())
      this.navigateToRoot();
    })
  }
  
  public unimpersonateUser = (): void => {
    sessionStorage.removeItem('eris-impersonated-userId');
    this.navigateToRoot();
  }

  private navigateToRoot = (): void => {
    if (this.router.url == "/")
      window.location.reload();
    else
      window.location.href = "/";
  }
}