import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartnerQAReports } from 'src/app/models/services/partnerQAReports';
import { Partners } from 'src/app/models/services/partners.enum';
import { QAReport } from 'src/app/models/services/qaReport';
import { QAReportsService } from 'src/app/services/qa-reports.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';
import { EnumDeclaration, EnumType } from 'typescript';

@Component({
  selector: 'app-admin-qa-reports',
  templateUrl: './admin-qa-reports.component.html',
  styleUrls: ['./admin-qa-reports.component.sass']
})
export class AdminQAReportsComponent implements OnInit, OnDestroy {

  heading: string = 'QA Reports';
  subheading: string = '';
  icon: string = 'pe-7s-config icon-gradient bg-midnight-bloom';
  loadFinished: boolean = false;

  availablePartnersQAReports: PartnerQAReports[];
  selectedPartnerQAReports: PartnerQAReports;
  selectedReport: QAReport;
  selectedProviderDescription: string = 'Select a provider';
  selectedDateDescription: string = 'Select a date';
  partnersEnum = Partners;
  initialDate = new Date();

  constructor(
    public usersService: UsersService,
    private globals: ThemeOptions,
    private qaReportsService: QAReportsService,
    private spinnerService: NgxSpinnerService,
    private datePipe: DatePipe
  ) { 
  }

  ngOnInit(): void {
    this.globals.adminMode = true;
    this.loadAvailableReports();
  }

  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }

  loadAvailableReports = (): void => {
    this.spinnerService.show();

    this.qaReportsService.getAvailableReports().subscribe(resp => {
      this.availablePartnersQAReports = resp;
      this.loadFinished = true;
      this.spinnerService.hide();
    })
  }

  onPartnerSelected = (partnerQAReports: PartnerQAReports): void => { 
    if (this.selectedPartnerQAReports?.partner != partnerQAReports.partner) {
      this.selectedPartnerQAReports = partnerQAReports;
      this.selectedProviderDescription = this.getPartnerDescription(partnerQAReports.partner);
      this.selectedDateDescription = 'Select a date'      

      let lastReportDate: Date = new Date(this.selectedPartnerQAReports.reportDates[0]);
      this.loadReport(lastReportDate);
      //this.dateFormCtrl.setValue(lastReportDate);
      this.initialDate = lastReportDate;
    }
  }

  onReportDateSelected = (event: MatDatepickerInputEvent<Date>): void => { 
    var year = event.value.getFullYear();
    var month = event.value.getMonth() < 9 ? '0' + (event.value.getMonth() + 1) : event.value.getMonth() + 1;
    var day = event.value.getDate() < 10 ? '0' + event.value.getDate() : event.value.getDate();

    var date = new Date(`${year}-${month}-${day}T00:00:00Z`)
    this.loadReport(date);
  }

  loadReport = (date: Date): void => {
    this.spinnerService.show();
    this.qaReportsService.getReport(this.selectedPartnerQAReports.partner, date).subscribe(resp => {
      this.selectedReport = resp;
      this.selectedDateDescription = this.datePipe.transform(date);
      this.spinnerService.hide();      
    })
  }

  getPartnerDescription = (partner: Partners): string => {
    switch(partner) {
      case Partners.Carrefour:
        return 'Carrefour';
      case Partners.Zeotap:
        return 'Zeotap';  
      case Partners.Weborama:
        return 'Weborama';    
      case Partners.Afilead:
        return 'Afilead';
      case Partners.Citiservi:
        return 'Citiservi';
      case Partners.Fintonic:
        return 'Fintonic';
      case Partners.Logan:
        return 'Logan';
    }
  }

  filterValidDates = (d: Date): boolean => {
    if (!!!d)
      return false;

    return this.selectedPartnerQAReports.reportDates.findIndex(x => {
      var dt = new Date(x);
      return dt.getDate() == d.getDate() && dt.getMonth() == d.getMonth() && dt.getFullYear() == d.getFullYear();
    }) >= 0;
  }  
}
