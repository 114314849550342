<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="form">                    
                <div class="form-group row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>Audience category</mat-label>
                            <mat-select [formControl]="form.controls.categoryId">
                                <mat-option *ngFor="let audienceCategory of audiencesCategories" [value]="audienceCategory.id">{{audienceCategory.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.controls.categoryId.errors && (form.controls.categoryId.dirty || form.controls.categoryId.touched)">Category is required</mat-error>
                        </mat-form-field>   
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Audience name</mat-label>
                            <input type="text" matInput [formControl]="form.controls.name" placeholder="Ex. 'PID_...'" />
                            <mat-error *ngIf="form.controls.name.errors && (form.controls.name.dirty || form.controls.name.touched)">Audience name is required</mat-error>
                        </mat-form-field>           
                    </div> 
                </div>
                <div class="form-group row">
                    <div class="col-md-3" *ngIf="usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesScheduler') && !isUpdate">
                        <mat-form-field>
                            <mat-label>Scheduling</mat-label>
                            <mat-select [formControl]="form.controls.schedulingId">
                                <mat-option *ngFor="let audienceScheduling of audiencesSchedulings" [value]="audienceScheduling.id">{{audienceScheduling.description}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.controls.schedulingId.errors && (form.controls.schedulingId.dirty || form.controls.schedulingId.touched)">Scheduling is required</mat-error>
                        </mat-form-field>    
                    </div> 
                    <div class="col-md-9" *ngIf="usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesScheduler') && !isUpdate && +form.controls.schedulingId.value > 1">
                        <mat-form-field>
                            <mat-label>Scheduling end date</mat-label>
                            <input matInput [matDatepicker]="picker" [formControl]="form.controls.schedulingEndDate" [value]="form.controls.schedulingEndDate.value" [matDatepickerFilter]="filterValidDates">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-hint>DD/MM/YYYY</mat-hint>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>              
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onAccept()"
        [disabled]="form.invalid || form.untouched">Accept</button>
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">Cancel</button>
</div>