import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-viewer-fintonic',
  templateUrl: './report-viewer-fintonic.component.html',
  styleUrls: ['./report-viewer-fintonic.component.sass']
})
export class ReportViewerFintonicComponent implements OnInit {

  @Input()
  public selectedReport: any;

  constructor() { }

  ngOnInit(): void {
  }

}
