<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Logan QA Report - {{selectedReport.date | date}}</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>General data</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total records: {{selectedReport.totalDevices | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total IDFAs: {{selectedReport.totalIDFAs | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total AAIDs: {{selectedReport.totalAAIDs | number:'':'es' }}
</div>
<!-- -------------------------------------------------------- -->
<div class="row">&nbsp;</div>
<!-- -------------------------------------------------------- -->
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Apps info</b></h3>
</div>
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total different apps: {{selectedReport.totalDifferentApps | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total apps with no reported app name: {{selectedReport.totalMissingAppNames | number:'':'es' }}
</div>
<!-- -------------------------------------------------------- -->
<div class="row">&nbsp;</div>
<!-- -------------------------------------------------------- -->
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Devices with info per info type</b></h3>
</div>
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total devices with apps info: {{selectedReport.totalDevicesWithAppsInfo | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total devices with demographics info: {{selectedReport.totalDevicesWithDemographicsInfo | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total devices with interests info: {{selectedReport.totalDevicesWithInterestsInfo | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total devices with locations info: {{selectedReport.totalDevicesWithLocationsInfo | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total devices with POIs info: {{selectedReport.totalDevicesWithPOIsInfo | number:'':'es' }}
</div>