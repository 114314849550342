import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ADB2CUser } from 'src/app/models/services/adB2CUser';
import { User } from 'src/app/models/services/user';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-user-selector-dialog',
  templateUrl: './user-selector-dialog.component.html',
  styleUrls: ['./user-selector-dialog.component.sass']
})
export class UserSelectorDialogComponent implements OnInit {

  users: User[];
  title: string = "Select a user";
  selectedUserId: number;

  constructor(
    private usersService: UsersService,    
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinnerService.show('dialogSpinner');
    this.usersService.getAllUsers().subscribe(resp => {
      this.users = resp.sort((x, y) => x.name.toUpperCase() > y.name.toUpperCase() ? 1 : x.name.toUpperCase() < y.name.toUpperCase() ? -1 : 0);
      this.spinnerService.hide('dialogSpinner');
    });
  }

  public accept: (selectedUser: User) => void;
  public dismiss: () => void;

  public onAccept = (selectedUserId: number): void  => {   
    let selectedUser: User = this.users.find(x => x.id == selectedUserId);
    this.accept(selectedUser);
  }

  onDismiss = (): void => {
    this.dismiss();
  }
}
