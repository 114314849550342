<div class="card">
    <div class="card-body">
        <ngx-spinner name="dialogSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p style="color: white">Loading ...</p>
        </ngx-spinner>        
        <div class="row">
            <div class="col-md-12">
                <h3>Xandr segment parameters</h3>
                <section>
                    <mat-button-toggle-group [(ngModel)]="newSegment" name="newSegmentSelector" aria-label="New segment" (change)="onNewSegmentSelectedChange()">
                        <mat-button-toggle value="0">Existing segment</mat-button-toggle>
                        <mat-button-toggle value="1">New segment</mat-button-toggle>
                    </mat-button-toggle-group>        
                </section>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Account</mat-label>
                        <input #matAutocompleteAccounts type="text" placeholder="Pick one" aria-label="Account" matInput 
                            [formControl]="form.controls.accountId" [matAutocomplete]="auto" 
                            (input)="filterAccounts()" (focus)="filterAccounts()" (click)="resetAccountsFilter()">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getAccountDescription.bind(this)" (optionSelected)="onAccountChange($event)">
                            <mat-option *ngFor="let account of filteredAccounts" [value]="account.memberId">{{account.accountName}}</mat-option>
                        </mat-autocomplete>                    
                        <mat-error *ngIf="form.controls.accountId.errors && (form.controls.accountId.dirty || form.controls.accountId.touched)">Account is required</mat-error>
                    </mat-form-field>   
                </div>
                <div class="col-md-8" *ngIf="newSegment == '0'">
                    <mat-form-field *ngIf="segments.length > 0" style="width: 100%;">
                        <mat-label>Segment</mat-label>
                        <input #matAutocompleteSegments type="text" placeholder="Pick one" aria-label="Segment" matInput 
                        [formControl]="form.controls.segmentId" [matAutocomplete]="auto"
                        (input)="filterSegments()" (focus)="filterSegments()" (click)="resetSegmentsFilter()"
                        style="width: 100%;">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getSegmentDescription.bind(this)" (optionSelected)="onSegmentChange($event)">
                            <mat-option *ngFor="let segment of filteredSegments" [value]="segment.id">{{segment.shortName}}</mat-option>
                        </mat-autocomplete>                      
                        <mat-error *ngIf="form.controls.segmentName.errors && (form.controls.segmentName.dirty || form.controls.segmentName.touched)">Existing segment is required</mat-error>
                    </mat-form-field>   
                    <mat-label *ngIf="!!form.controls.accountId.value && segments.length == 0" style="width: 100%;">The account has no segments</mat-label>
                    <mat-label *ngIf="resultsExceeded != ''" style="width: 100%;">{{resultsExceeded}}</mat-label>
                </div>
                <div class="col-md-8" *ngIf="newSegment == '1'">
                    <mat-form-field style="width: 100%;">
                        <mat-label>New segment name</mat-label>
                        <input matInput [formControl]="form.controls.segmentName" placeholder="Ex. PID_..." (input)="onSegmentDescriptionChange()">
                        <mat-error *ngIf="form.controls.segmentName.errors?.required && (form.controls.segmentName.dirty || form.controls.segmentName.touched)">Segment name is required</mat-error>
                        <mat-error *ngIf="form.controls.segmentName.errors?.existingSegmentName && (form.controls.segmentName.dirty || form.controls.segmentName.touched)">The segment name exists in the selected account</mat-error>
                    </mat-form-field>
                </div> 
            </div>      
        </form>
    </div>
</div>
