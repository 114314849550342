<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon">
</app-page-title>
<div class="row">
    <div class="col-10 col-md-10 column-taxonomies">
        <table class="table table-striped">
            <tbody>
                <tr>
                    <td class="align-left">
                        <h2>Settings parameters</h2>
                        <br/>
                        <input type="radio" value="and" name="radioCondition" [(ngModel)]="selectedCondition" (change)="resultsCountData = null;">
                        <label for="and">
                            &nbsp;&nbsp;AND
                        </label>          
                        <br/> 
                        <input type="radio" value="or" name="radioCondition" [(ngModel)]="selectedCondition" (change)="resultsCountData = null;">
                        <label for="or">
                            &nbsp;&nbsp;OR
                        </label>                                                          
                    </td>
                    <td class="align-left">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td class="align-middle" style="width: 30%;"></td>
                                    <td class="align-middle" style="width: 20%;">
                                        Representation <i class="pe-7s-info" placement="top" ngbTooltip="Representation percentage of an attribute comparing the segment profiles number with the total number of profiles"></i>
                                        <!--percentage (from total matched profiles)--><br/>
                                        (Min. percentage)<br/>
                                        <mat-slider max="100" min="0" step="1" thumbLabel [(ngModel)]="minimumPercentageValue" (change)="refreshFilteredTaxonomies()">
                                        </mat-slider>    
                                        <input type="number" min="0" max="100" step="1" [(ngModel)]="minimumPercentageValue" (change)="refreshFilteredTaxonomies()">                                     
                                    </td>
                                    <td class="align-middle" style="width: 20%;">
                                        Affinity index <i class="pe-7s-info" placement="top" ngbTooltip="Ratio of the target audience rating to the base audience rating"></i>
                                        <!--percentage (from total matched profiles)--><br/>
                                        (Min. percentage)<br/>
                                        <mat-slider max="5000" min="0" step="10" thumbLabel [(ngModel)]="minimumAffinityIndex" (change)="refreshFilteredTaxonomies()">
                                        </mat-slider>                                          
                                        <input type="number" min="0" max="5000" step="10" [(ngModel)]="minimumAffinityIndex" (change)="refreshFilteredTaxonomies()">                                     
                                    </td>
                                    <td class="align-middle" style="width: 25%;"></td>
                                    <td class="align-middle" style="width: 5%;"></td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td class="align-left">
                        <h2>Selected attributes</h2>
                    </td>
                    <td class="align-left">
                    </td>
                </tr>
                <tr>
                    <td class="align-left" colspan="2">
                        <div *ngFor="let readableRule of currentReadableSelection"
                        style="margin: 3px; cursor: default;"
                        class="btn btn-secondary mt-2">
                        {{readableRule}}
                        </div>                        
                    </td>
                </tr>                             
                <ng-container *ngFor="let lookAlikeParametersTaxonomy of lookAlikeParametersTaxonomies">
                    <tr>
                        <td class="align-left">
                            <h2>{{lookAlikeParametersTaxonomy.name}}</h2>                            
                        </td>
                        <td style="text-align: right;">
                            <div class="btn-success badge badge-pill badge-warning" style="padding: 0.4rem 0.6rem; margin-left: 0.5rem;"
                                (click)="toggleCategoryVisibility(lookAlikeParametersTaxonomy)">
                                
                                <i class="pe-7s-angle-up" *ngIf="categoryNameVisible == lookAlikeParametersTaxonomy.name" style="font-size: 1.5rem;vertical-align: middle;"></i>
                                <i class="pe-7s-angle-down" *ngIf="categoryNameVisible != lookAlikeParametersTaxonomy.name" style="font-size: 1.5rem;vertical-align: middle;"></i>            
                            </div>  
                        </td>
                    </tr>
                    <ng-container *ngIf="categoryNameVisible == lookAlikeParametersTaxonomy.name">
                        <tr *ngFor="let parametersGroup of lookAlikeParametersTaxonomy.parametersGroups">
                            <td class="align-left">{{parametersGroup.name}}</td>
                            <td style="text-align: right;">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr *ngFor="let parameter of parametersGroup.parameters">
                                            <td class="align-middle" style="width: 30%;">{{ parameter.fieldDescription}}</td>
                                            <td class="align-middle" style="width: 20%;">{{ parameter.representationPercentage | number:'':'es' }}%</td>
                                            <td class="align-middle" style="width: 20%;">{{ parameter.affinityIndex }}ix</td>
                                            <td class="align-middle" style="width: 25%;"></td>
                                            <td class="align-middle" style="width: 5%;">
                                                <input type="checkbox" 
                                                    [(ngModel)]="parameter.selected"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="onAttributeCheckedChange()" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a class="main-page-link" (click)="showLessClicked(parametersGroup)" *ngIf="showLessVisible(parametersGroup)">Show less ...</a>
                                &nbsp;&nbsp;&nbsp;
                                <a class="main-page-link" (click)="showMoreClicked(parametersGroup)" *ngIf="showMoreVisible(parametersGroup)">Show more ...</a>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="col-2 col-md-2 column-results">
        <div class="card mb-2 results-container">
            <div class="card-header"
                style="text-transform: uppercase; font-size: 0.8rem; margin: 0.75rem 0; font-weight: bold; color: #033C73; position: relative;">
                <div *ngIf="!!resultsCountData">Total GraphId profiles: {{ totalProfiles | number:'':'es'}}</div>
            </div>
            <div *ngIf="!resultsCountData" class="text-center" style="height: 200px; margin: 12px;">
                <button class="btn btn-success" (click)="refreshCount()">Refresh count</button>
            </div>
            <div *ngIf="!!resultsCountData" class="text-center">                
                <app-circle-pack [data]="resultsCountData">
                </app-circle-pack>
            </div>
            <div *ngIf="!!resultsCountData">
                <div>
                    <span [style.background-color]="resultsCountData.children[0].color">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    {{sourceMatchedRecords | number:'':'es'}} profiles matched in {{ getSourceTypeDescription() }}
                </div>                
                <div>
                    <span [style.background-color]="resultsCountData.children[1].color">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    {{resultsCountData.children[0].value + resultsCountData.children[1].value | number:'':'es'}}
                    profiles in look alike
                </div>
            </div>
            <div class="text-center pt-2 pb-2" *ngIf="!!resultsCountData">
                <button class="btn btn-success" (click)="createLookAlike()">Create look alike</button>
            </div>
        </div>
    </div>
</div>