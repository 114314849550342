import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { IABMaster } from 'src/app/models/services/iab';
import { DialogService } from 'src/app/services/core/dialog.service';
import { IABMasterSelectorDialogService } from 'src/app/services/dialogs/iab-master-selector-dialog.service';
import { IABService } from 'src/app/services/iab.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';
import { timer } from 'rxjs';

@Component({
  selector: 'app-iab-master-maintenance',
  templateUrl: './iab-master-maintenance.component.html',
  styleUrls: ['./iab-master-maintenance.component.sass']
})
export class IABMasterMaintenanceComponent implements OnInit, OnDestroy {
  heading: string = 'IAB master maintenance';
  subheading: string = '';
  icon: string = 'pe-7s-graph2 icon-gradient bg-midnight-bloom';
  loadFinished: boolean = false;
  treeData: IABMaster[];
  selectedNode: IABMaster;
  form: FormGroup;
  locked: boolean = false;

  constructor(
    public usersService: UsersService,
    private iabService: IABService,
    private iabMasterSelectorDialogService: IABMasterSelectorDialogService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private globals: ThemeOptions,
    private spinnerService: NgxSpinnerService   
  ) { 
      this.form = this.formBuilder.group(
        {
          name: ['', Validators.required],
          description: ['', Validators.required]
        });
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.globals.adminMode = true;

    this.iabService.isMaintenanceLocked().subscribe(
      locked => {
        if (locked) {
          this.locked = true;
          // Wait and retry
          timer(2000).subscribe(() => this.ngOnInit());
        } else {
          this.locked = false;
          this.iabService.getIABMasterTree().subscribe(
            x => {
              this.treeData = x;
              this.spinnerService.hide();
              this.loadFinished = true;
            }
          )
        }

      }      
    );
  }

  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }

  onSelectedNodeChange = (node: IABMaster) => {
    this.selectedNode = {...node};
  }

  joinSelectedNode = (): void => {  
    this.dialogService.showConfirmation("Are you sure you want to join this IAB master element? \nThis action cannot be undone and all profiles, keywords, etc ... will be moved to the target one.",
      () => {
        this.iabMasterSelectorDialogService.show(
          this.treeData, 
          (selectedItem:IABMaster) => {
            var modifiedNode = {...this.selectedNode};
            this.selectedNode = null;
            this.loadFinished = false;
            this.spinnerService.show();          
            this.locked = true;
            this.iabService.joinIABMaster(modifiedNode.id, selectedItem.id).subscribe(
              () => {
                this.ngOnInit();
              }
            )
          }, () =>{      
          }
        ); 
      })
  }

  reparentSelectedNode = (): void => {  
    this.iabMasterSelectorDialogService.show(
      this.treeData, 
      (selectedItem:IABMaster) => {
        var modifiedNode = {...this.selectedNode};
        this.selectedNode = null;
        this.loadFinished = false;
        this.spinnerService.show();          
        modifiedNode.parentId = selectedItem.id;
        this.iabService.updateIABMaster(modifiedNode).subscribe(
          () => {
            this.selectedNode = null;
            this.dialogService.showMessage('IAB master maintenance', 'IAB master reparented successfully');
            this.ngOnInit();
          }
        )
      }, () =>{      
      }
    );      
  }

  saveSelectedNode = (): void => {  
    var modifiedNode = {...this.selectedNode};
    this.selectedNode = null;
    this.loadFinished = false;
    this.spinnerService.show();
    this.iabService.updateIABMaster(modifiedNode).subscribe(      
      () => {
        this.dialogService.showMessage('IAB master maintenance', 'IAB master saved successfully');        
        this.ngOnInit();
      }
    )
  }
}