<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div *ngIf="!usersService.hasSelectedCompanyModuleRight('Audiences', 'AudienceBuilder')">
    You have no rights to access this page
</div>
<div *ngIf="loadFinished && usersService.hasSelectedCompanyModuleRight('Audiences', 'AudienceBuilder')">
    <div class="row">
        <div class="col-10 col-md-10 column-query-builder">
            <div class="card query-builder-container">
                <query-builder [id]="getIdFromRuleset(query)" [(ngModel)]='query' [config]='config' [operatorMap]="customOperatorMap" [allowCollapse]="true">
                    <ng-container
                        *queryButtonGroup="let ruleset; let addRule=addRule; let addRuleSet=addRuleSet; let removeRuleSet=removeRuleSet">
                        <button type="button" mat-icon-button color="primary" (click)="addCustomRule(ruleset, getIdFromRuleset(ruleset))">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button type="button" mat-icon-button color="primary" *ngIf="addRuleSet" (click)="addRuleSet()">
                            <mat-icon>add_circle_outline</mat-icon>
                        </button>
                        <button type="button" mat-icon-button color="warn" *ngIf="removeRuleSet"
                            (click)="removeRuleSet()">
                            <mat-icon>remove_circle_outline</mat-icon>
                        </button>
                    </ng-container>
                    <ng-container *queryArrowIcon>
                        <mat-icon ngClass="mat-arrow-icon">chevron_right</mat-icon>
                    </ng-container>
                    <ng-container *queryRemoveButton="let rule; let removeRule=removeRule">
                        <button type="button" mat-icon-button color="warn" (click)="removeRule(rule)">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </ng-container>
                    <!---->
                    <ng-container *querySwitchGroup="let ruleset; let onChange=onChange">
                        <div class="q-switch-group q-transition"
                            style="display: flex; font-family: 'Lucida Grande',Tahoma,Verdana,sans-serif;">
                            <div>
                                <input type="radio" class="q-switch-radio" [(ngModel)]="ruleset.condition" value="and"
                                    style="position: absolute; clip: rect(0,0,0,0); height: 1px; width: 1px; border: 0; overflow: hidden;" />
                                <label (click)="ruleset.condition='and'" class="q-switch-label"
                                    style="padding:3px 8px; cursor: pointer;"
                                    [style.background]="ruleset.condition=='and' ? '#fff' : '#e4e4e4'"
                                    [style.border]="ruleset.condition=='and' ? '1px solid #619ed7' : '1px solid'"
                                    [style.color]="ruleset.condition=='and' ? '#3176b3' : ''">AND</label>
                            </div>
                            <div>
                                <input type="radio" class="q-switch-radio" [(ngModel)]="ruleset.condition" value="or"
                                    style="position: absolute; clip: rect(0,0,0,0); height: 1px; width: 1px; border: 0; overflow: hidden;" />
                                <label (click)="ruleset.condition='or'" class="q-switch-label"
                                    style="padding:3px 8px; cursor: pointer;"
                                    [style.background]="ruleset.condition=='or' ? '#fff' : '#e4e4e4'"
                                    [style.border]="ruleset.condition=='or' ? '1px solid #619ed7' : '1px solid'"
                                    [style.color]="ruleset.condition=='or' ? '#3176b3' : ''">OR</label>
                            </div>
                            <div [id]="getIdFromRuleset(ruleset)" [hidden]="!dragging" class="font-icon-wrapper"
                                style="margin-left: 100px; padding:3px 8px; width: 400px; border: 1px solid; background-color: #d3d3d3;"
                                dndDropzone (dndDrop)="onDrop($event)">
                                <i class="pe-7s-next-2"></i>
                                <p>Drop the condition here</p>
                            </div>
                        </div>
                    </ng-container>
                    <!--
                    <ng-container *querySwitchGroup="let ruleset; let onChange=onChange" style="background-color: aqua;"
                        cdkDropList (cdkDropListDropped)="drop($event)">
                        <div>
                            <mat-radio-group *ngIf="ruleset" [(ngModel)]="ruleset.condition"
                                (ngModelChange)="onChange($event)">
                                <mat-radio-button [style.padding.px]="10" value="and">And</mat-radio-button>
                                <mat-radio-button [style.padding.px]="10" value="or">Or</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </ng-container>
                -->
                    <!--
                    <ng-container *queryEntity="let rule; let entities=entities; let onChange=onChange">
                        <mat-form-field>
                            <mat-select [(ngModel)]="rule.entity" (ngModelChange)="onChange($event, rule)">
                                <mat-option *ngFor="let entity of entities" [value]="entity.value">
                                    {{entity.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-container
                        *queryField="let rule; let fields=fields; let onChange=onChange; let getFields = getFields">
                        <mat-form-field>
                            <mat-select [(ngModel)]="rule.field" (ngModelChange)="onChange($event, rule)">
                                <mat-option *ngFor="let field of getFields(rule.entity)" [value]="field.value">
                                    {{ field.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *queryOperator="let rule; let operators=operators; let onChange=onChange">
                        <mat-form-field [style.width.px]="90">
                            <mat-select [(ngModel)]="rule.operator" (ngModelChange)="onChange(rule)">
                                <mat-option *ngFor="let value of operators" [value]="value">
                                    {{ value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *queryInput="let rule; type: 'boolean'; let onChange=onChange">
                        <mat-checkbox [(ngModel)]="rule.value" (ngModelChange)="onChange()"></mat-checkbox>
                    </ng-container>
                    <ng-container
                        *queryInput="let rule; let field=field; let options=options; type: 'category'; let onChange=onChange">
                        <mat-form-field>
                            <mat-select [(ngModel)]="rule.value" (ngModelChange)="onChange()">
                                <mat-option *ngFor="let opt of options" [value]="opt.value">
                                    {{ opt.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *queryInput="let rule; type: 'date'; let onChange=onChange">
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="rule.value"
                                (ngModelChange)="onChange()">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </ng-container>
                    <ng-container
                        *queryInput="let rule; let options=options; type: 'multiselect'; let onChange=onChange">
                        <mat-form-field [style.width.px]="300">
                            <mat-select [(ngModel)]="rule.value" multiple (ngModelChange)="onChange()">
                                <mat-option *ngFor="let opt of options" [value]="opt.value">
                                    {{ opt.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *queryInput="let rule; let field=field; type: 'number'; let onChange=onChange">
                        <mat-form-field [style.width.px]="50">
                            <input matInput [(ngModel)]="rule.value" type="number" (ngModelChange)="onChange()">
                        </mat-form-field>
                    </ng-container>
                    <ng-container *queryInput="let rule; let field=field; type: 'string'; let onChange=onChange">
                        <mat-form-field>
                            <input matInput [(ngModel)]="rule.value" (ngModelChange)="onChange()">
                        </mat-form-field>
                    </ng-container>
                    <ng-container *queryInput="let rule; let field=field; type: 'textarea'; let onChange=onChange">
                        <mat-form-field>
                            <textarea matInput [(ngModel)]="rule.value" (ngModelChange)="onChange()">
            </textarea>
                        </mat-form-field>
                    </ng-container>
                    -->
                    <ng-container *queryInput="let rule; type: 'audience'">
                        <div style="display:inline-block;vertical-align:bottom;line-height:100%">
                            <label>{{getAudienceDescription(rule.value)}}</label>
                        </div>
                    </ng-container>                    
                </query-builder>
            </div>
        </div>
        <div class="col-2 col-md-2 column-results">
            <div class="card mb-2 results-container">
                <div class="card-header"
                    style="text-transform: uppercase; font-size: 0.8rem; margin: 0.75rem 0; font-weight: bold; color: #033C73; position: relative;">
                    <div *ngIf="!segmentId">
                        Results count<br />
                        Full Graph ID
                    </div>
                    <div *ngIf="!!segmentId" style="overflow-wrap: break-word">
                        Segment name: {{segmentName}}<br />
                        Segment records: {{segmentRecordCount}}
                    </div>
                </div>
                <div *ngIf="!resultsCountData && query.rules.length > 0" class="text-center"
                    style="height: 200px; margin: 12px;">
                    <button class="btn btn-success" (click)="refreshCount()">Refresh count</button>
                </div>                
                <div *ngIf="!!resultsCountData" class="text-center">
                    <app-circle-pack [data]="resultsCountData">
                    </app-circle-pack>
                </div>            
                <div *ngIf="!!resultsCountData">                
                    <div>
                        <span
                            [style.background-color]="resultsCountData.children[1].color">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        {{resultsCountData.children[0].value + resultsCountData.children[1].value | number:'':'es'}}
                        profiles
                    </div>                    
                    <div>
                        <span
                            [style.background-color]="resultsCountData.children[0].color">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        {{resultsCountData.children[0].value | number:'':'es'}} matched
                    </div>
                </div>
                <div class="text-center pt-2 pb-2" *ngIf="!!resultsCountData && resultsCountData.children[0].value > 0 && usersService.hasSelectedCompanyActionRight('Audiences', 'AudienceBuilder', 'Manage')">
                    <button class="btn btn-success" (click)="createAudience()">Create audience</button>
                </div>
                <div class="text-center pt-2 pb-2" *ngIf="usersService.hasSelectedCompanyActionRight('Audiences', 'AudiencesList', 'Manage')">
                    <button class="btn btn-success" (click)="getQueryFromAudience()">Get query from audience</button>
                </div>
                <div class="text-center pt-2 pb-2" *ngIf="!environment.production">
                    <button class="btn btn-success" (click)="showQuery()">Show
                        query</button>
                </div>
            </div>
        </div>
    </div>
</div>