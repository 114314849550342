<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="main-card card">
        <div class="card-body">
            <h2 class="card-title">General info</h2>  
            <div class="row">
                <div class="col-md-2">
                    <label>Identifier:</label>
                </div>
                <div class="col-md-10">
                    {{ audience.id}}
                </div>
            </div>   
            <div class="row">
                <div class="col-md-2">
                    <label>Name:</label>
                </div>
                <div class="col-md-10">
                    {{ audience.name}}
                </div>
            </div>   
            <div class="row">
                <div class="col-md-2">
                    <label>Category:</label>
                </div>
                <div class="col-md-10">
                    {{ audience.category?.name}}
                </div>
            </div>                       
            <div class="row">
                <div class="col-md-2">
                    <label>Created date:</label>
                </div>
                <div class="col-md-10">
                    {{ audience.created | date }}
                </div>
            </div>                                    
            <div class="row">
                <div class="col-md-2">
                    <label>Description:</label>
                </div>
                <div class="col-md-10">
                    {{ audience.description }}
                </div>
            </div>                                       
            <div class="row">
                <div class="col-md-2">
                    <label>Total profiles:</label>
                </div>
                <div class="col-md-10">
                    {{ audience.profilesCount | number:'':'es' }}
                </div>
            </div>        
            <div class="row">
                <div class="col-md-2">
                    <label>Profiles per identifier type:</label>
                </div>
                <div class="col-md-10">
                    <ng-container *ngFor="let item of audience.idGraphCounts | keyvalue">
                        <div *ngIf="item.value > 0 && item.key != '6'">{{ audiencesService.getIdGraphTypeDescription(+item.key) }}: {{ item.value |
                            number:'':'es'}}</div>
                    </ng-container>
                </div>
            </div>                
        </div>
    </div>
    <div class="row">&nbsp;</div>
    <div class="main-card card" *ngIf="!!audience.tags && audience.tags.length > 0">
        <div class="card-body">
            <h2 class="card-title">Tags</h2>  
            <div class="row">
                <div class="col-md-12">
                    <div *ngFor="let tag of audience.tags" class="badge badge-pill badge-primary" style="padding: 0.4rem 0.6rem; margin: 0.5rem">
                        <span>{{tag.name}}&nbsp;&nbsp;</span>
                        <mat-icon class="pe-7s-ticket" style="vertical-align:middle;"></mat-icon>          
                    </div>
                </div>
            </div> 
        </div>
    </div>  
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismiss()">Close</button>
</div>