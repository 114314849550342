import { Router } from '@angular/router';
import { GlobalsService } from 'src/app/services/globals.service';

export abstract class BaseTenantRequiredPageComponent {
  protected redirecting: boolean = false;

  constructor(
    hasLoadRights: boolean,
    globalsService: GlobalsService,
    router: Router) {
    if (!hasLoadRights || !globalsService.getActiveCompany()) {
      router.navigate(["/"]);
      this.redirecting = true;
    }
   }


}
