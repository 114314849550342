import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';
import { Partners } from '../models/services/partners.enum';
import { QAReport } from '../models/services/qaReport';
import { PartnerQAReports } from '../models/services/partnerQAReports';

@Injectable({
  providedIn: 'root'
})
export class QAReportsService {

  constructor(
    private httpService: HttpService
  ) { }

  public getAvailableReports(): Observable<PartnerQAReports[]> {
    let url: string = `${environment.adminServicesApiBaseUri}/qa_reports`;
    return this.httpService.get(url, undefined);
  }  

  public getReport(partner: Partners, date: Date): Observable<QAReport> {
    let url: string = `${environment.adminServicesApiBaseUri}/qa_reports/${partner}/${date.toISOString()}`;
    return this.httpService.get(url, undefined);
  }    
}
