import { Component, OnInit } from '@angular/core';
import { Audience } from 'src/app/models/services/audience';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';

@Component({
  selector: 'app-audience-info-dialog',
  templateUrl: './audience-info-dialog.component.html',
  styleUrls: ['./audience-info-dialog.component.sass']
})
export class AudienceInfoDialogComponent implements OnInit {
  public dismiss: () => void;
  public audience: Audience;

  title: string = "Audience info"; 

  private flatten = (x) => x.reduce((a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []);

  constructor(private audiencesService: AudiencesService) { }

  ngOnInit(): void {
  }

}


