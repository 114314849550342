<div class="card main-card mb-3">
    <div class="card-header">
        Top mobile apps interests {{ !!segmentId ? '(segment ' + segmentId + ')' : '' }}
    </div>
    <div class="card-body">
        <canvas baseChart
            [data]="chartData"
            [labels]="chartLabels"
            [options]="chartOptions"
            [legend]="chartLegend"
            [chartType]="chartType"
            [colors]="chartBackgroundColors">
        </canvas>
    </div>
</div>
