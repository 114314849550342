import { Component, Input, OnInit } from '@angular/core';
import { QAReport } from 'src/app/models/services/qaReport';

@Component({
  selector: 'app-report-viewer-weborama',
  templateUrl: './report-viewer-weborama.component.html',
  styleUrls: ['./report-viewer-weborama.component.sass']
})
export class ReportViewerWeboramaComponent implements OnInit {

  @Input()
  public selectedReport: any;

  constructor() { }

  ngOnInit(): void {
  }

}
