import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';
import { ErisProcessesInfo } from '../models/services/erisProcessesInfo';
import { ErisProcessQueueInfo } from '../models/services/erisProcessQueueInfo';
import { ErisManualProcessInfo } from '../models/services/erisManualProcessInfo';
import { PublicisAudience } from '../models/services/dataTransferInfo';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {

  constructor(
    private httpService: HttpService
  ) { }

  public getAllProcesses(): Observable<ErisProcessesInfo> {
    let url: string = `${environment.adminServicesApiBaseUri}/processes`;
    return this.httpService.get(url, undefined, true);
  }  

  public getProcessDetail(id: string): Observable<string[]> {
    let url: string = `${environment.adminServicesApiBaseUri}/processes/${id}`;
    return this.httpService.get(url, undefined);
  }    

  public getIngestionProcessesInfo(): Observable<ErisProcessQueueInfo[]> {
    let url: string = `${environment.adminServicesApiBaseUri}/ingestionProcesses`;
    return this.httpService.get(url, undefined, true);
  }  

  public getManualProcessesInfo(): Observable<ErisManualProcessInfo[]> {
    let url: string = `${environment.adminServicesApiBaseUri}/manualProcesses`;
    return this.httpService.get(url, undefined, true);
  }

  public getRunningAndFailedAudiences(): Observable<PublicisAudience[]> {
    let url: string = `${environment.adminServicesApiBaseUri}/audiences/runningAndFailed`;
    return this.httpService.get(url, undefined, true);
  }

  public isOptimizationEnabled(): Observable<boolean> {
    let url: string = `${environment.adminServicesApiBaseUri}/ingestionProcesses/optimization/enabled`;
    return this.httpService.get(url, undefined);
  }

  public enableOptimization(): Observable<void> {
    let url: string = `${environment.adminServicesApiBaseUri}/ingestionProcesses/optimization/enable`;
    return this.httpService.postNoResponse(url, undefined);
  }

  public disableOptimization(): Observable<void> {
    let url: string = `${environment.adminServicesApiBaseUri}/ingestionProcesses/optimization/disable`;
    return this.httpService.postNoResponse(url, undefined);
  }
  
  public enableProcessOptimization(processId: number): Observable<void> {
    let url: string = `${environment.adminServicesApiBaseUri}/ingestionProcesses/${processId}/optimization/enable`;
    return this.httpService.postNoResponse(url, undefined);
  }

  public disableProcessOptimization(processId: number): Observable<void> {
    let url: string = `${environment.adminServicesApiBaseUri}/ingestionProcesses/${processId}/optimization/disable`;
    return this.httpService.postNoResponse(url, undefined);
  } 
}
