import { Injectable } from '@angular/core';
import { Company } from '../models/services/company';
import { User } from '../models/services/user';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  private loggedUser: User;
  private activeCompany: Company;
  private companies: Company[];
  private allowedCompanies: Company[];
  private isStarting: boolean = true;
  private isPlaftormMaintenance: boolean = false;

  constructor() { }

  public setLoggedUser = (user: User) =>  {
    this.loggedUser = user;
    this.allowedCompanies = this.companies.filter(x => user.companiesUsersRights.findIndex(y => y.companyId == x.id) >= 0)
  }

  public getLoggedUser = (): User =>  {
    return this.loggedUser;
  }

  public setActiveCompany = (company: Company) =>  {
    this.activeCompany = company;
  }

  public getActiveCompany = (): Company =>  {
    return this.activeCompany;
  }

  public setCompanies = (companies: Company[]) =>  {
    this.companies = companies;
  }

  public getCompanies = (): Company[] =>  {
    return this.companies;
  }  

  public getAllowedCompanies = (): Company[] =>  {
    return this.allowedCompanies;
  }  

  public setIsStarting = (isStarting: boolean) =>  {
    this.isStarting = isStarting;
  }

  public getIsStarting = (): boolean =>  {
    return this.isStarting;
  }

  public setIsPlatformMaintenance = (isPlaftormMaintenance: boolean) =>  {
    this.isPlaftormMaintenance = isPlaftormMaintenance;
  }

  public getIsPlatformMaintenance = (): boolean =>  {
    return this.isPlaftormMaintenance;
  }
}
