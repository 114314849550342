import { Component, OnInit } from '@angular/core';
import { TaxonomyItem } from 'src/app/models/services/taxonomy';

@Component({
  selector: 'app-taxonomy-item-selector-dialog',
  templateUrl: './taxonomy-item-selector-dialog.component.html',
  styleUrls: ['./taxonomy-item-selector-dialog.component.sass']
})
export class TaxonomyItemSelectorDialogComponent implements OnInit {
  title: string = "Select new parent";
  
  public treeData: TaxonomyItem[];
  public selectedItem: TaxonomyItem;

  public accept: (selectedItem: TaxonomyItem) => void;
  public dismiss: () => void;

  constructor() { }

  ngOnInit(): void {
  }

  onSelectedNodeChange = (node: TaxonomyItem) => {
    this.selectedItem = node;
  }


}
