import { Injectable } from '@angular/core';
import { CompanyUserRight, UserRight } from '../models/services/right';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { User } from '../models/services/user';
import { GlobalsService } from './globals.service';
import { Company } from '../models/services/company';
import { ADB2CUser } from '../models/services/adB2CUser';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private httpService: HttpService,
    private loginService: LoginService,
    private globalsService: GlobalsService) { }

  public loadCurrentUserInfo = (): Observable<boolean> => {
    let instance: UsersService = this;
    return new Observable<boolean>(subscriber => {
      // Load companies just one time  
      let url: string = `${environment.coreServicesApiBaseUri}/users`;
      this.httpService.get<User>(url, undefined, true).subscribe({
        next(x: User) {
          instance.globalsService.setLoggedUser(x);
          subscriber.next(true);
        },
        error(err) { subscriber.error(err) },
        complete() { subscriber.complete() }
      });
    });
  }

  public getCurrentLoggedUser = (): Observable<User> => {
    let url: string = `${environment.coreServicesApiBaseUri}/users`;
    return this.httpService.get(url, undefined, true);
  }

  public hasSomeRights = (): boolean => {
    var loggedAccount = this.loginService.getLoggedAccount();
    return !!loggedAccount &&       
      !!loggedAccount?.idTokenClaims['extension_APIKey'] &&
      (
        this.globalsService.getLoggedUser()?.globalUsersRights?.length > 0 ||
        this.globalsService.getLoggedUser()?.companiesUsersRights?.length > 0
      );
  }

  public hasGlobalSectionRight = (section: string): boolean => {
    let loggedUser : User = this.globalsService.getLoggedUser();
    if (!loggedUser)
      return false;

    return loggedUser.globalUsersRights.findIndex((x: UserRight) => x.section == section ) >= 0;
  }

  public hasGlobalModuleRight = (section: string, module: string): boolean => {
    let loggedUser : User = this.globalsService.getLoggedUser();
    if (!loggedUser)
      return false;

    return loggedUser.globalUsersRights.findIndex((x: UserRight) => x.section == section && x.module == module ) >= 0;
  }

  public hasGlobalActionRight = (section: string, module: string, action: string): boolean => {
    let loggedUser : User = this.globalsService.getLoggedUser();
    if (!loggedUser)
      return false;
    
    return loggedUser.globalUsersRights.findIndex((x: UserRight) => x.section == section && x.module == module && x.action == action) >= 0;
  }

  public hasSelectedCompanySectionRight = (section: string): boolean => {
    let loggedUser : User = this.globalsService.getLoggedUser();
    let activeCompany : Company = this.globalsService.getActiveCompany();
    if (!loggedUser || !activeCompany)
      return false;
    
    return loggedUser.companiesUsersRights.findIndex((x: CompanyUserRight) => x.companyId == activeCompany.id && x.section == section ) >= 0;
  }

  public hasSelectedCompanyModuleRight = (section: string, module: string): boolean => {
    let loggedUser : User = this.globalsService.getLoggedUser();
    let activeCompany : Company = this.globalsService.getActiveCompany();
    if (!loggedUser || !activeCompany)
      return false;

    return loggedUser.companiesUsersRights.findIndex((x: CompanyUserRight) => x.companyId == activeCompany.id && x.section == section && x.module == module) >= 0;
  }

  public hasSelectedCompanyActionRight = (section: string, module: string, action: string): boolean => {
    let loggedUser : User = this.globalsService.getLoggedUser();
    let activeCompany : Company = this.globalsService.getActiveCompany();
    if (!loggedUser || !activeCompany)
      return false;
    
    return loggedUser.companiesUsersRights.findIndex((x: CompanyUserRight) => x.companyId == activeCompany.id && x.section == section && x.module == module && x.action == action) >= 0;    
  }

  public getAllUsers = (): Observable<User[]> => {
    let url: string = `${environment.adminServicesApiBaseUri}/users`;
    return this.httpService.get(url, undefined);
  }  

  public getAllADB2CUsers = (): Observable<ADB2CUser[]> => {
    let url: string = `${environment.adminServicesApiBaseUri}/adB2Cusers`;
    return this.httpService.get(url, undefined);
  }   

  public addUser = (adB2CUser: ADB2CUser): Observable<void> => {
    let url: string = `${environment.adminServicesApiBaseUri}/users`;
    return this.httpService.postNoResponse(url, adB2CUser, undefined);
  }    

  public getUser = (id: number): Observable<User> => {
    let url: string = `${environment.adminServicesApiBaseUri}/users/${id}`;
    return this.httpService.get(url, undefined);
  }   

  public updateUser = (user: User): Observable<void> => {
    let url: string = `${environment.adminServicesApiBaseUri}/users`;
    return this.httpService.putNoResponse(url, user, undefined);
  }    
  
  public copyUserRights = (sourceUserId: number, targetUserId: number): Observable<void> => {
    let url: string = `${environment.adminServicesApiBaseUri}/users/${targetUserId}/copyRightsFrom/${sourceUserId}`;
    return this.httpService.putNoResponse(url, null, undefined);
  }       


}
