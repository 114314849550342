import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/http.service';
import { Observable } from 'rxjs';
import { GlobalsService } from '../globals.service';
import { Account } from 'src/app/models/services/audiences/xandr/account';
import { Segment } from 'src/app/models/services/audiences/xandr/segment';

@Injectable({
  providedIn: 'root'
})
export class XandrAudiencesService {

  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService
  ) { }

  public getAccounts(): Observable<Account[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/xandr/accounts`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public getAccountSegments(memberId: number): Observable<Segment[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/xandr/accounts/${memberId}/segments`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

  public searchSegments(memberId: number, filter: string): Observable<Segment[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/xandr/accounts/${memberId}/segments/${filter}`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

}