<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Afilead QA Report - {{selectedReport.date | date}}</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>General data</b></h3>
</div>
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total emails: {{selectedReport.totalUniqueEmails | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total emails (MG Database): {{selectedReport.totalUniqueMGEmails | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total emails (MO Database): {{selectedReport.totalUniqueMOEmails | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total emails (OM Database): {{selectedReport.totalUniqueOMEmails | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total emails (PR Database): {{selectedReport.totalUniquePREmails | number:'':'es' }}
</div>
<!-- -------------------------------------------------------- -->
<div class="row">&nbsp;</div>
<!-- -------------------------------------------------------- -->
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Anomalies</b></h3>
</div>
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total emails non spanish (MG Database): {{selectedReport.totalUniqueMGEmailsNonSpanish | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total emails non spanish (MO Database): {{selectedReport.totalUniqueMOEmailsNonSpanish | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total emails non spanish (OM Database): {{selectedReport.totalUniqueOMEmailsNonSpanish | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total emails non spanish (PR Database): {{selectedReport.totalUniquePREmailsNonSpanish | number:'':'es' }}
</div>