import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NgxSpinnerService } from 'ngx-spinner';
import { MetaCustomAudience } from 'src/app/models/services/metaCustomAudience';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';

@Component({
  selector: 'app-meta-attributes',
  templateUrl: './meta-attributes.component.html',
  styleUrls: ['./meta-attributes.component.sass']
})
export class MetaAttributesComponent implements OnInit {
  @ViewChild('matAutocompleteAudiences') inputAutocompleteAudiences: ElementRef<HTMLInputElement>;
  
  @Input() 
  form: FormGroup;

  @Input() 
  updateValueAndValidity: () => void;

  @Input() 
  erisAudienceName: string;

  audiences: MetaCustomAudience[] = [];
  filteredAudiences: MetaCustomAudience[] = [];
  newCustomAudience: string = '0';

  constructor(
    private audiencesServices: AudiencesService,
    private spinnerService: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinnerService.show("dialogSpinner");
    this.audiencesServices.getMetaCustomAudiences().subscribe(
      resp => {
        this.audiences = resp;
        this.filteredAudiences = resp;
        if (!this.newCustomAudience)
          this.form.controls.audienceExternalId.setValue(this.filteredAudiences[0].id);  
        
        this.onNewAudienceSelectedChange();

        this.updateValueAndValidity();     

        this.spinnerService.hide("dialogSpinner");   
      }
    )
  }

  resetAudiencesFilter = (): void => {
    this.inputAutocompleteAudiences.nativeElement.value = '';
    this.form.controls.audienceExternalId.setValue(null);
    this.form.controls.audienceName.setValue(null);
    this.filteredAudiences = this.audiences;
    this.updateValueAndValidity();
  }
    
  filterAudiences = (): void => {
    const filterValue = this.inputAutocompleteAudiences.nativeElement.value.toLowerCase();
    this.filteredAudiences = this.audiences.filter(o => o.name.toLowerCase().includes(filterValue));
  }

  onAudienceChange = ($event: MatAutocompleteSelectedEvent): void => {    
    this.inputAutocompleteAudiences.nativeElement.blur();
    this.form.controls.audienceName.setValue(this.inputAutocompleteAudiences.nativeElement.value);
    this.updateValueAndValidity();
  }

  getAudienceDescription = (audienceId?: string): string => {
    return this.audiences.find(x => x.id == audienceId)?.name;
  }

  onNewAudienceSelectedChange = (): void => {
    this.form.controls.audienceExternalId.setValue(null);
    this.form.controls.audienceName.setValue(!!(+this.newCustomAudience) ? this.erisAudienceName : null);
    if (!!(+this.newCustomAudience))
      this.form.controls.audienceExternalId.setValidators([]);
    else
      this.form.controls.audienceExternalId.setValidators([Validators.required]);

    this.form.controls.audienceExternalId.updateValueAndValidity();
    this.updateValueAndValidity();
  }
}
