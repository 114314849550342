<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [searchEnabled]="false">
</app-page-title>
<!-- CODE EDITOR -->
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <ngs-code-editor [codeModel]="codeModel" style="height: 400px;"></ngs-code-editor>
            </div>    
        </div>
    </div>
</div>
<div class="row">&nbsp;</div>           
<!-- WIZARD BUTTONS -->
<div class="row">
    <div class="col-md-12" style="text-align: right;">
        <button type="button" class="btn btn-primary" style="margin-right:10px; width: 150px" (click)="getDataPreview()">Get data</button>
    </div>
</div> 
<div class="row">&nbsp;</div>
<div class="row" *ngIf="!!tableDataPreview">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Data preview (first {{tableDataPreview.preview.columns[0].data.length | number:'':'es' }} rows of {{tableDataPreview.totalCount | number:'':'es' }})</h3>
                <perfect-scrollbar style="height: 400px;" [autoPropagation]="true">
                    <div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" *ngFor="let column of tableDataPreview.preview.columns">
                                        {{column.header}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of tableDataPreview.preview.columns[0].data; index as rowIndex">
                                    <td *ngFor="let column of tableDataPreview.preview.columns; index as columnIndex">{{this.formatData(tableDataPreview.preview.columns[columnIndex].data[rowIndex], tableDataPreview.preview.columns[columnIndex].type)}}</td>
                                </tr>
                            </tbody>
                        </table>           
                    </div>
                </perfect-scrollbar>
                <div class="row">&nbsp;</div>
                <div class="row" *ngIf="!!tableDataPreview">
                    <div class="col-md-12" style="text-align: right">
                        <button type="button" class="btn btn-primary" style="margin-right:10px;" (click)="getData(pasteToClipboard)">Copy full result to clipboard</button>
                        <button type="button" class="btn btn-primary" style="margin-right:10px;" (click)="getData(downloadTSV)">Download as TSV file</button>
                        <button type="button" class="btn btn-primary" (click)="getData(downloadCSV)">Download as CSV file</button>
                    </div>
                </div>                     
            </div>
        </div>        
    </div>
</div>
<div class="row">&nbsp;</div>
