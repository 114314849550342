<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p [style]="allowLineBreaks ? 'white-space: pre;' : ''">{{message}}</p>
</div>
<div class="modal-footer">
    <button *ngFor="let buttonText of buttonsTexts" type="button" class="btn btn-secondary" (click)="close(buttonText)">{{buttonText}}</button>
    <button *ngIf="buttonRejectVisible" type="button" class="btn btn-secondary" (click)="dismiss('Reject button')">Reject</button>
</div>