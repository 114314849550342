import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { InsightsService } from 'src/app/services/insights/insights.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { SegmentsService } from 'src/app/services/segments.service';

@Component({
  selector: 'publicis-id-insights-gender-per-age-ranges',
  templateUrl: './insights-gender-per-age-ranges.component.html',
  styleUrls: ['./insights-gender-per-age-ranges.component.sass']
})
export class InsightsGenderPerAgeRangesComponent implements OnInit {
  @Input() segmentId: string;

  public chartOptions: ChartOptions = {
    responsive: true,
    scales: {xAxes: [{}], yAxes: [{
      ticks: {
        beginAtZero: true,
        callback: function(value, index, values) {
          return value.toLocaleString();
        }
      }      
    }
    ]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: -25,
        formatter: function(value, context) {
          return value > 0 ? value.toLocaleString() : '';
        }
      }
    },
    tooltips: {
      callbacks: {
            label: function(tooltipItem, data) {
                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                return value.toLocaleString();
            }
      }
    }
  };
  public chartLabels: Label[] = [];
  public chartType: ChartType = 'bar';
  public chartLegend = true;
  public chartPlugins = [pluginDataLabels];
  public chartData: ChartDataSets[] = [];  

  constructor(
    private insightsService: InsightsService,
    private segmentsService: SegmentsService) { }

  ngOnInit(): void {
    if (!this.segmentId) {
      this.insightsService.getGendersPerAgeRanges().subscribe(
        resp => {
          this.chartLabels = resp.labels;
          this.chartData = resp.dataSets;
        },
        () => {
        }
      )      
    }
    else {
      this.segmentsService.getGendersPerAgeRanges(this.segmentId).subscribe(
        resp => {
          this.chartLabels = resp.labels;
          this.chartData = resp.dataSets;
        },
        () => {
        }
      )            
    }
  }
}
