import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-custom-buttons',
  templateUrl: './dialog-custom-buttons.component.html',
  styleUrls: ['./dialog-custom-buttons.component.sass']
})
export class DialogCustomButtonsComponent implements OnInit {
  title: string
  message: string;
  buttonsTexts: string[];
  buttonRejectVisible: boolean;
  allowLineBreaks: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  public dismiss: (result: string) => void;
  public close: (result: string) => void;

}
