import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'src/app/services/core/dialog.service';
import { InputNameDialogService } from 'src/app/services/core/input-name-dialog.service';
import { ThemeOptions } from 'src/app/theme-options';
import { LookAlikeParametersTaxonomy, LookAlikeParametersGroup, LookAlikeParameter, MaxParameterIndexAllowed } from 'src/app/models/services/lookAlikeTaxonomy';
import { SegmentsService } from 'src/app/services/segments.service';
import { LookAlike } from 'src/app/models/services/lookAlike';
import { Rule, RuleSet } from 'angular2-query-builder';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';
import { Segment } from 'src/app/models/services/segment';
import { LookAlikeSourceTypes } from 'src/app/models/services/lookAlikeSourceTypes.enum';
import { LookAlikesService } from 'src/app/services/look-alikes.service';
import { AudienceQueryCountRequest } from 'src/app/models/services/audienceQueryCount';
import { Audience } from 'src/app/models/services/audience';
import { GlobalsService } from 'src/app/services/globals.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-new-look-alike',
  templateUrl: './new-look-alike.component.html',
  styleUrls: ['./new-look-alike.component.sass']
})
export class NewLookAlikeComponent implements OnInit {
  // Flatten definition
  private flatten = (x) => x.reduce((a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []);

  heading: string = 'New look alike';
  subheading: string = '';
  icon: string = 'pe-7s-users icon-gradient bg-midnight-bloom';
  
  sourceType: LookAlikeSourceTypes;
  sourceIdentifier: string;
  sourceMatchedRecords: number;
  lookAlikeParametersTaxonomiesUnfiltered: Array<LookAlikeParametersTaxonomy>;
  lookAlikeParametersTaxonomiesGlobalFiltered: Array<LookAlikeParametersTaxonomy>;
  lookAlikeParametersTaxonomies: Array<LookAlikeParametersTaxonomy>;
  resultsCountData = null;
  minimumPercentageValue: number = 40;
  minimumAffinityIndex: number = 150;
  totalProfiles: number = 0;
  selectedCondition: string = 'and';
  maxParametersIndexesAllowed: MaxParameterIndexAllowed[] = [];
  categoryNameVisible: string;
  currentSelection: RuleSet;
  currentReadableSelection: string[];

  constructor(
    private segmentsServices: SegmentsService,
    private lookAlikesServices: LookAlikesService,
    private audiencesServices: AudiencesService,
    private globals: ThemeOptions,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private inputNameDialogService: InputNameDialogService,
    private spinnerService: NgxSpinnerService,
    private globalsService: GlobalsService,
    private usersService: UsersService,
    private router: Router) { }


  ngOnInit(): void {
    // If no active company navigate to root
    if (!this.globalsService.getActiveCompany()) {
      this.router.navigate(["/"]);
      return;
    }    
    this.spinnerService.show();
    this.globals.backEnabled = true;
    this.sourceIdentifier = this.route.snapshot.paramMap.get('sourceIdentifier');
    this.route.data.subscribe(data => {
      this.sourceType = data.sourceType;
      switch(this.sourceType) {
        case LookAlikeSourceTypes.Segment:
          // If no rights navigate to root
          if(!this.usersService.hasSelectedCompanySectionRight('Segments')) {
            this.router.navigate(["/"]);
            return;
          }          
          this.segmentsServices.get(this.sourceIdentifier).subscribe(
            (data: Segment) => {
              this.sourceMatchedRecords = data.matchedRecords;        
            }      
          );            
          this.globals.backLabel = "Back to segment look alikes";          
          break;

        default:
          // If no rights navigate to root
          if(!this.usersService.hasSelectedCompanyActionRight('Audiences', 'AudiencesList', 'Manage')) {
            this.router.navigate(["/"]);
            return;
          }          
          var audienceId = +this.sourceIdentifier;
          this.audiencesServices.getAudience(audienceId).subscribe(
            (data: Audience) => {
              this.sourceMatchedRecords = data.profilesCount;        
            }      
          );            
          this.globals.backLabel = "Back to audience look alikes";
          break;
      }
    });    
  
    this.lookAlikesServices.getLookAlikesParameters(this.sourceType, this.sourceIdentifier).subscribe(
      (data: Array<LookAlikeParametersTaxonomy>) => {
        this.spinnerService.hide();
        this.lookAlikeParametersTaxonomiesUnfiltered = data;        
        this.refreshFilteredTaxonomies();
      },
      () => {
        this.spinnerService.hide();
      }      
    );
  }

  ngOnDestroy(): void {
    this.globals.backEnabled = false;
    this.globals.backLabel = "";
  }
  
  refreshCount = (): void => {
    var conditions = this.currentSelection;
    if (!conditions || conditions.rules.length == 0) {
      this.dialogService.showMessage('Lookalike', 'You must select at least one condition');
      return;
    }
      
    this.spinnerService.show();
    let request: AudienceQueryCountRequest = {
      lookAlikeSourceType: this.sourceType,
      lookAlikeSourceIdentifier: this.sourceIdentifier,
      query: conditions
    }; 
    this.audiencesServices.getAudienceCounts(request).subscribe((data) => {
      this.totalProfiles = data.totalCount;
      this.resultsCountData = {
        name: "Total",
        color: "#93f291",
        children: [
          {
            value: this.sourceMatchedRecords,
            color: "#34a832"
          },
          {
            value: data.audienceCount - this.sourceMatchedRecords,
            color: "#93f291"
          }
        ]
      };
      this.spinnerService.hide();
    });
  }

  createLookAlike = (): void => {
    this.inputNameDialogService.show(
      "Create look alike",
      "Are you sure you want to create a look alike?",
      (name: string) => {
        // Save audience
        this.spinnerService.show();
        let lookAlike: LookAlike = {
            sourceType: this.sourceType,
            sourceIdentifier: this.sourceIdentifier,
            name: name,
            conditions: this.currentSelection
        }

        this.lookAlikesServices.createLookAlike(this.sourceType, lookAlike).subscribe(
          () => {
            this.spinnerService.hide();
            this.dialogService.showMessage("Create look alike", "Look alike created");
            // TODO - subscribe on close to perform location.back()
          },
          () => {
            this.spinnerService.hide();
          }
        );
      },
      () => { }
    );
  }  

  refreshCurrentSelection = (): void => {
    var parameterGroups: LookAlikeParametersGroup[] = this.flatten(this.lookAlikeParametersTaxonomies.map(x => x.parametersGroups));
    var selection: LookAlikeParameter[] = this.flatten(parameterGroups.map(y => y.parameters.filter(z => z.selected)));
    this.currentSelection = {
      condition: this.selectedCondition,
      rules: selection.map((x: LookAlikeParameter): Rule => this.getRuleFromLookAlikeParameter(x))
    };
    this.currentReadableSelection = selection.map((x: LookAlikeParameter): string => {
      var parameterTaxonomy = this.lookAlikeParametersTaxonomies.find(pt => pt.parametersGroups.findIndex(pg => pg.parameters.findIndex(p => p.fieldName == x.fieldName && p.fieldValue == x.fieldValue ) >= 0) >= 0);
      var parameterGroup = parameterTaxonomy.parametersGroups.find(pg => pg.parameters.findIndex(p => p.fieldName == x.fieldName && p.fieldValue == x.fieldValue ) >= 0);

      if (x.fieldName.startsWith("fieldSegment") || x.fieldName.startsWith("fieldCluster"))
        return `${parameterTaxonomy.name} \\ ${parameterGroup.name} is ${x.fieldDescription} affinity is higher or equal than 10`;
      
        return `${parameterTaxonomy.name} \\ ${parameterGroup.name} is ${x.fieldDescription}`;
    });
  }

  getRuleFromLookAlikeParameter = (parameter: LookAlikeParameter): Rule => {
    if (parameter.fieldName.startsWith("fieldSegment") || parameter.fieldName.startsWith("fieldCluster")) {
        return {
          field: parameter.fieldName + parameter.fieldValue,
          operator: ">=",
          value: "10"
        };
    } else {
        return {
          field: parameter.fieldName,
          operator: "=",
          value: parameter.fieldValue.toString()
        };

    }
  }

  refreshFilteredTaxonomies = (): void => {
    this.lookAlikeParametersTaxonomiesGlobalFiltered = this.lookAlikeParametersTaxonomiesUnfiltered.map((x): LookAlikeParametersTaxonomy => {
      return {
        name: x.name,
        parametersGroups: x.parametersGroups.map((y): LookAlikeParametersGroup => {
          return {
            name: y.name,
            parameters: y.parameters.filter((z): boolean => {
              return z.representationPercentage >= this.minimumPercentageValue &&
              z.affinityIndex >= this.minimumAffinityIndex;
            }).sort((x, y) => x.affinityIndex < y.affinityIndex ? 1 : x.affinityIndex > y.affinityIndex ? -1 : 0) as Array<LookAlikeParameter>
          };
        }).filter((y): boolean => {
          return y.parameters.length > 0;
        }) as (Array<LookAlikeParametersGroup>)
      };
    }).filter((x): boolean => {
      return x.parametersGroups.length > 0;
    }) as Array<LookAlikeParametersTaxonomy>;

    this.lookAlikeParametersTaxonomies = 
      this.lookAlikeParametersTaxonomiesGlobalFiltered.map((x): LookAlikeParametersTaxonomy => {
        return {
          name: x.name,
          parametersGroups: x.parametersGroups.map((y): LookAlikeParametersGroup => {
            return {
              name: y.name,
              parameters: y.parameters.filter((z, index): boolean => {
                var maxParameterIndexAllowedStored = this.maxParametersIndexesAllowed.find(maxParam => maxParam.parameterGroupName == y.name);
                var maxParameterIndexAllowed = !!maxParameterIndexAllowedStored ? maxParameterIndexAllowedStored.value : 4;
                return index <= maxParameterIndexAllowed;
              }) as Array<LookAlikeParameter>
            };
          }).filter((y): boolean => {
            return y.parameters.length > 0;
          }) as (Array<LookAlikeParametersGroup>)
        };
      }).filter((x): boolean => {
        return x.parametersGroups.length > 0;
      }) as Array<LookAlikeParametersTaxonomy>;
  }

  getSourceTypeDescription = (): string => {
    switch (this.sourceType) {
      case LookAlikeSourceTypes.Segment:
        return "segment";
        case LookAlikeSourceTypes.Audience:
          return "audience";        
    }
  }

  showMoreClicked = (parametersGroup: LookAlikeParametersGroup): void => {
    var maxParameterIndexAllowedStored = this.maxParametersIndexesAllowed.find(x => x.parameterGroupName == parametersGroup.name);
    if (!!maxParameterIndexAllowedStored)
      maxParameterIndexAllowedStored.value += 5;
    else
      this.maxParametersIndexesAllowed.push({
        parameterGroupName: parametersGroup.name,
        value: 9
      });

    this.refreshFilteredTaxonomies();
  }

  showLessClicked = (parametersGroup: LookAlikeParametersGroup): void => {
    var maxParameterIndexAllowedStored = this.maxParametersIndexesAllowed.find(x => x.parameterGroupName == parametersGroup.name);
    if (!!maxParameterIndexAllowedStored && maxParameterIndexAllowedStored.value > 5) {
      maxParameterIndexAllowedStored.value -= 5;
      this.refreshFilteredTaxonomies();
    }
  }

  showMoreVisible = (parametersGroup: LookAlikeParametersGroup): boolean => {
    var originalParameters = 
      this.lookAlikeParametersTaxonomiesGlobalFiltered
        .find(x => x.name == this.categoryNameVisible)
        .parametersGroups
          .find(x => x.name == parametersGroup.name)
          .parameters;          
    var maxParameterIndexAllowedStored = this.maxParametersIndexesAllowed.find(x => x.parameterGroupName == parametersGroup.name);
    var currentMaxIndex = !!maxParameterIndexAllowedStored ? maxParameterIndexAllowedStored.value : 4;

    return currentMaxIndex < originalParameters.length - 1;
  }

  showLessVisible = (parametersGroup: LookAlikeParametersGroup): boolean => {
    var maxParameterIndexAllowedStored = this.maxParametersIndexesAllowed.find(x => x.parameterGroupName == parametersGroup.name);
    var currentMaxIndex = !!maxParameterIndexAllowedStored ?maxParameterIndexAllowedStored.value : 4;

    return currentMaxIndex > 4;
  }

  toggleCategoryVisibility  = (category: LookAlikeParametersTaxonomy): void => {
    this.categoryNameVisible = (this.categoryNameVisible != category.name) ? category.name : undefined;
  }  

  onAttributeCheckedChange = (): void => {    
    this.refreshCurrentSelection();
    this.resultsCountData = null;
  }
}
