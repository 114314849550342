import { Component, OnInit } from '@angular/core';
import { IABMaster } from 'src/app/models/services/iab';

@Component({
  selector: 'app-iab-master-selector-dialog',
  templateUrl: './iab-master-selector-dialog.component.html',
  styleUrls: ['./iab-master-selector-dialog.component.sass']
})
export class IabMasterSelectorDialogComponent implements OnInit {
  title: string = "Select new parent";
  
  public treeData: IABMaster[];
  public selectedItem: IABMaster;

  public accept: (selectedItem: IABMaster) => void;
  public dismiss: () => void;

  constructor() { }

  ngOnInit(): void {
  }

  onSelectedNodeChange = (node: IABMaster) => {
    this.selectedItem = node;
  }


}
