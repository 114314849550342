import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-root-admin',
  templateUrl: './root-admin.component.html',
  styleUrls: ['./root-admin.component.sass']
})
export class RootAdminComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    public usersService: UsersService,
    private globals: ThemeOptions) { }

  ngOnInit(): void {
    this.globals.adminMode = true;
  }

  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }  
  navigateTo = (path: string): void => {
    this.router.navigate([path]);
  } 

}
