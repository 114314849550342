import { Component, OnInit } from '@angular/core';
import { Rule } from 'angular2-query-builder';
import { TaxonomyItem } from 'src/app/models/services/taxonomiesConfig';

@Component({
  selector: 'app-attribute-value-audience-overlap-selector-dialog',
  templateUrl: './attribute-value-audience-overlap-selector-dialog.component.html',
  styleUrls: ['./attribute-value-audience-overlap-selector-dialog.component.sass']
})
export class AttributeValueAudienceOverlapSelectorDialogComponent implements OnInit {
  public taxonomyItem: TaxonomyItem;
  public accept: (rule: Rule) => void;
  public dismiss: () => void;
    
  title: string = "Select audience overlap"; 
  operators: string[] = ['in', 'not in'];
  selectedOperator: string = 'in';

  constructor() {
  }

  ngOnInit(): void {    
  }

  onAccept = (): void => {
    this.accept({
      field: this.taxonomyItem.field,
      operator: this.selectedOperator,
      value: this.taxonomyItem.value
    });
  };

  onDismiss = (): void => {
    this.dismiss();
  }

  acceptButtonEnabled = (): boolean => {
    return !!this.selectedOperator;
  }

  onSelectOperator = (operator: string): void => {
    this.selectedOperator = operator;
  }

}


