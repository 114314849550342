import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PowerBIReportParametersDialogComponent } from 'src/app/components/dialogs/admin/powerbi-report-parameters-dialog/powerbi-report-parameters-dialog.component';
import { PowerBIReport } from 'src/app/models/services/powerBIReport';

@Injectable({
  providedIn: 'root'
})
export class PowerBIReportParametersDialogService {

  constructor(
    private modalService: NgbModal
  ) { }

  public showDialog = (acceptCallback: (selectedPowerBIReport: PowerBIReport) => void, rejectCallback?: () => void): void => {
    var ref = this.modalService.open(PowerBIReportParametersDialogComponent, {
      backdrop: 'static'
    });

    let output: PowerBIReport;

    // Get the OkDialog from the NgbModal object  
    let dialog: PowerBIReportParametersDialogComponent = ref.componentInstance as PowerBIReportParametersDialogComponent;

    // Set the dialog's properties
    dialog.accept = (selectedPowerBIReport: PowerBIReport) => {      
      output = selectedPowerBIReport;
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }

}