<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
  (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <a [class]="getRootLinkClass()" (click)="navigateToRoot()">
      <div class="page-title-heading">ERIS ID Portal</div>
    </a>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Main Navigation</div>
                </ng-template>
              </ngb-panel>
              <!-- SEGMENTS -->
              <ngb-panel id="segmentsMenu" *ngIf="usersService.hasSelectedCompanySectionRight('Segments') && !globals.adminMode">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-copy-file"></i>
                    <span class="vsm-title">Segments</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="/segments/file-upload" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span class="vsm-title">Upload
                            datafile</span></a>
                      </div>
                      <div class="vsm-item"><a routerLink="/segments/list" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span class="vsm-title">Segments
                            list</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!-- AUDIENCES -->
              <ngb-panel id="audiencesMenu" *ngIf="usersService.hasSelectedCompanySectionRight('Audiences') && !globals.adminMode">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-radio"></i>
                    <span class="vsm-title">Audiences</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngIf="usersService.hasSelectedCompanyModuleRight('Audiences', 'AudienceBuilder')">
                        <a routerLink="/audiences/builder" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Build an audience</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesList')">
                        <a routerLink="/audiences/list" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Audiences list</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesScheduler')">
                        <a routerLink="/audiences/scheduler" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                          class="vsm-title">Audience scheduler</span></a>
                    </div>                      
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!-- INSIGHTS -->
              <ngb-panel id="insightsMenu" *ngIf="usersService.hasSelectedCompanySectionRight('Insights') && !globals.adminMode">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Insights</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="/insights/general" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                            class="vsm-title">General insights</span></a>
                      </div>
                      <div class="vsm-item"><a routerLink="/insights/custom" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link" 
                        *ngIf="globalsService.getActiveCompany()?.powerBIReports?.length > 0"><span
                          class="vsm-title">Custom insights</span></a>
                      </div>
                      <div class="vsm-item"><a routerLink="/insights/nielsen-data" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link" 
                        *ngIf="usersService.hasGlobalModuleRight('Insights', 'Nielsen data')"><span
                          class="vsm-title">Nielsen data</span></a>
                      </div>                      
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!-- ADMIN -->
              <ngb-panel id="dataTransfersMenu" *ngIf="usersService.hasGlobalSectionRight('Admin') && globals.adminMode">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Data transfers</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="/admin/data-transfers" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                            class="vsm-title">Data transfers</span></a>
                      </div>
                      <div class="vsm-item"><a routerLink="/admin/data-transfers-report" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                          class="vsm-title">Data transfers report</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>              
              <ngb-panel id="masterDataMaintenanceMenu" *ngIf="usersService.hasGlobalSectionRight('Admin') && globals.adminMode">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Master data maintenance</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngIf="usersService.hasGlobalSectionRight('Admin') && globals.adminMode">
                        <a routerLink="admin/master/powerbi-reports" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                            class="vsm-title">PowerBI reports</span></a>
                      </div>
                      <div class="vsm-item" *ngIf="usersService.hasGlobalSectionRight('Admin') && globals.adminMode">
                        <a routerLink="admin/master/iab" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                            class="vsm-title">IAB master</span></a>
                      </div>
                      <div class="vsm-item" *ngIf="usersService.hasGlobalSectionRight('Admin') && globals.adminMode">
                        <a routerLink="admin/master/taxonomy" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                            class="vsm-title">ERIS taxonomy</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>  
              <ngb-panel id="adminToolsMenu" *ngIf="usersService.hasGlobalSectionRight('Admin') && globals.adminMode">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Platform management</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngIf="usersService.hasGlobalActionRight('Admin', 'UserManagement', 'Manage')">
                        <a routerLink="/admin/user-rights" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                            class="vsm-title">User rights</span></a>
                      </div>
                      <div class="vsm-item" *ngIf="usersService.hasGlobalActionRight('Admin', 'CompaniesManagement', 'Manage')">
                        <a routerLink="/admin/companies" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                          class="vsm-title">Companies</span></a>
                      </div>
                      <div class="vsm-item"><a routerLink="/admin/processes" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                          class="vsm-title">Processes</span></a>
                      </div>                  
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> 
              <ngb-panel id="reportsMenu" *ngIf="usersService.hasGlobalSectionRight('Admin') && globals.adminMode">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Reports</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="/admin/qa-reports" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                          class="vsm-title">QA Reports</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>      
              <ngb-panel id="utilsMenu" *ngIf="usersService.hasGlobalSectionRight('Admin') && globals.adminMode">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Utils</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="/admin/sql-runner" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                          class="vsm-title">SQL runner</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>                                              
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter"
              *ngIf="sampleMenus">
              <!-- TEMPLATE PANELS SAMPLES -->
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">TEMPLATE PANELS SAMPLES</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Bootstrap Components</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="pagesMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-browser"></i>
                    <span class="vsm-title">Pages</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/pages/login-boxed" class="vsm-link"><span
                            class="vsm-title">Login Boxed</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/pages/register-boxed" class="vsm-link"><span
                            class="vsm-title">Register Boxed</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/pages/forgot-password-boxed"
                          class="vsm-link"><span class="vsm-title">Forgot Password Boxed</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="elementsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-diamond"></i>
                    <span class="vsm-title">Elements</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/buttons-standard" class="vsm-link">
                          <span class="vsm-title">Standard Buttons</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                            class="vsm-title">Dropdowns</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/icons" class="vsm-link"><span
                            class="vsm-title">Icons</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/cards" class="vsm-link"><span
                            class="vsm-title">Cards</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/list-group" class="vsm-link"> <span
                            class="vsm-title">List Groups</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/timeline" class="vsm-link"> <span
                            class="vsm-title">Timeline</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="componentsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-car"></i>
                    <span class="vsm-title">Components</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <!-- <div class="vsm-item">
                          <a routerLinkActive="active-item" routerLink="/components/tabs" class="vsm-link"><span
                            class="vsm-title">Tabs</span></a>
                        </div> -->
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/accordions" class="vsm-link"> <span
                            class="vsm-title">Accordions</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/modals" class="vsm-link"> <span
                            class="vsm-title">Modals</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/progress-bar" class="vsm-link"> <span
                            class="vsm-title">Progress Bar</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/tooltips-popovers" class="vsm-link">
                          <span class="vsm-title">Tooltips &amp; Popovers</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/carousel" class="vsm-link"> <span
                            class="vsm-title">Carousel</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/pagination" class="vsm-link"> <span
                            class="vsm-title">Pagination</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="formElementsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-helm"></i>
                    <span class="vsm-title">Form Elements</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/forms/controls" class="vsm-link"><span
                            class="vsm-title">Controls</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/forms/layouts" class="vsm-link"><span
                            class="vsm-title">Layouts</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="tablesMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-safe"></i>
                    <span class="vsm-title">Tables</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/tables/bootstrap" class="vsm-link"> <span
                            class="vsm-title">Bootstrap 4 Tables</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Dashboard Boxes</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="pagesMenu3">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Chart Boxes</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/widgets/chart-boxes-3" class="vsm-link">
                          <span class="vsm-title">Variation 3</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <div *ngIf="sampleMenus">
              <div class="vsm-header">Charts</div>
              <div class="vsm-item">
                <a routerLink="/charts/chartjs" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-graph2"></i>
                  <span class="vsm-title">ChartJS</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>