<div class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" src="./assets/images/avatars/1.jpg" alt="" class="rounded-circle">
                </div>
              </span>
            </button>
            <div ngbDropdownMenu>
              <!-- Portal / Admin switch -->
              <a routerLink="/admin" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" *ngIf="usersService.hasGlobalSectionRight('Admin') && !globals.adminMode">
                <button class="dropdown-item">Go to admin section</button>
              </a>              
              <a routerLink="/" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" *ngIf="globals.adminMode">
                <button class="dropdown-item">Go to portal</button>
              </a>              
              <!--<button class="dropdown-item">View profile</button>-->
              <button class="dropdown-item" *ngIf="globalsService.getLoggedUser()?.companies?.length > 0"
                (click)="companySelectorService.showCompanySelector()">Change active
                company</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" *ngIf="!impersonationService.isImpersonationActive() && usersService.hasGlobalActionRight('Admin', 'UserManagement', 'Manage')" (click)="impersonationService.impersonateUser()">Impersonate user</button>
              <button class="dropdown-item" *ngIf="impersonationService.isImpersonationActive()" (click)="impersonationService.unimpersonateUser()">Stop impersonation</button>
              <button class="dropdown-item" (click)="loginService.logout()">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>