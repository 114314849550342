import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseTenantRequiredPageComponent } from 'src/app/components/core/base-tenant-required-page/base-tenant-required-page.component';
import { GlobalsService } from 'src/app/services/globals.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-segment-insights',
  templateUrl: './segment-insights.component.html',
  styleUrls: ['./segment-insights.component.sass']
})
export class SegmentInsightsComponent extends BaseTenantRequiredPageComponent implements OnInit, OnDestroy {
  public heading: string = 'Segment insights';
  public subheading: string = '';
  public icon: string = 'pe-7s-plane icon-gradient bg-midnight-bloom';

  public segmentId: string;

  constructor(
    private route: ActivatedRoute,
    private globals: ThemeOptions,
    globalsService: GlobalsService,
    usersService: UsersService,
    router: Router) { 
    super(
      usersService.hasSelectedCompanySectionRight('Segments'),
      globalsService, 
      router);    
  }  

  ngOnInit(): void {
    this.segmentId = this.route.snapshot.paramMap.get('segmentId');    
    this.globals.backLabel = "Back to segments list";
    this.globals.backEnabled = true;
  }

  ngOnDestroy(): void {
    this.globals.backEnabled = false;
    this.globals.backLabel = "";
  }

}
