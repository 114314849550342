import { Component, OnInit } from '@angular/core';
import { Rule } from 'angular2-query-builder';
import { TaxonomyItem } from 'src/app/models/services/taxonomiesConfig';

@Component({
  selector: 'app-attribute-value-affinity-selector-dialog',
  templateUrl: './attribute-value-affinity-selector-dialog.component.html',
  styleUrls: ['./attribute-value-affinity-selector-dialog.component.sass']
})
export class AttributeValueAffinitySelectorDialogComponent implements OnInit {
  public taxonomyItem: TaxonomyItem;
  public accept: (rule: Rule) => void;
  public dismiss: () => void;
    
  title: string = "Select attribute affinity"; 
  operators: string[] = ['=', '!=', '>', '>=', '<', '<='];
  values: number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
  selectedOperator: string = '>';
  selectedValue: number = 10;

  constructor() {
  }

  ngOnInit(): void {    
  }

  onAccept = (): void => {
    this.accept({
      field: this.taxonomyItem.field,
      operator: this.selectedOperator,
      value: this.selectedValue
    });
  };

  onDismiss = (): void => {
    this.dismiss();
  }

  acceptButtonEnabled = (): boolean => {
    return !!this.selectedOperator && !!this.selectedValue;
  }

  onSelectOperator = (operator: string): void => {
    this.selectedOperator = operator;
  }

  onSelectValue = (value: number): void => {
    this.selectedValue = value;
  }

}


