import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { InsightsService } from 'src/app/services/insights/insights.service';
import { SegmentsService } from 'src/app/services/segments.service';

@Component({
  selector: 'publicis-id-insights-mobile-apps-interests',
  templateUrl: './insights-mobile-apps-interests.component.html',
  styleUrls: ['./insights-mobile-apps-interests.component.sass']
})
export class InsightsMobileAppsInterestsComponent implements OnInit {
  @Input() segmentId: string;

  public chartLabels: Label[] = [];
  public chartBackgroundColors: Color[] = [
    { backgroundColor: ['#e69ba2', '#febe89', '#ffe082', '#93d3a1', '#7fbcff', '#e69ba2', '#febe89', '#ffe082', '#93d3a1', '#7fbcff'] }
  ];
  public chartData: SingleDataSet = [];
  public chartLegend = false;
  public chartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {xAxes: [{
      ticks: {
        beginAtZero: true,
        callback: function(value, index, values) {
          return value.toLocaleString();
        }
      }       
    }], yAxes: [{     
    }
    ]},
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
        formatter: function(value, context) {
          return value.toLocaleString();
        }
      }
    },
    tooltips: {
      callbacks: {
            label: function(tooltipItem, data) {
                var value = data.datasets[0].data[tooltipItem.index];
                return value.toLocaleString();
            }
      }
    }
  };
  public chartType: ChartType = 'horizontalBar';

  constructor(
    private insightsService: InsightsService,
    private segmentsService: SegmentsService) { }

  ngOnInit(): void {
    if (!this.segmentId) {
      this.insightsService.getMobileAppsInterests().subscribe(
        resp => {
          this.chartLabels = resp.labels;
          this.chartData = resp.dataSet;
        },
        () => {
        }
      )      
    }
    else {
      this.segmentsService.getMobileAppsInterests(this.segmentId).subscribe(
        resp => {
          this.chartLabels = resp.labels;
          this.chartData = resp.dataSet;
        },
        () => {
        }
      )            
    }
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
