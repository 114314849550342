import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IabMasterSelectorDialogComponent } from 'src/app/components/dialogs/admin/iab-master-selector-dialog/iab-master-selector-dialog.component';
import { IABMaster } from 'src/app/models/services/iab';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class IABMasterSelectorDialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public show = (treeData: IABMaster[], acceptCallback: (selectedItem: IABMaster) => void, rejectCallback?: () => void): void => {    
    var ref = this.modalService.open(IabMasterSelectorDialogComponent, {
      size: 'xl'
    });
    var output: IABMaster;

    // Get the OkDialog from the NgbModal object  
    let dialog: IabMasterSelectorDialogComponent = ref.componentInstance as IabMasterSelectorDialogComponent;

    // Set the dialog's properties
    dialog.accept = (data: IABMaster) => {
      output = data;
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    dialog.treeData = treeData; 

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }
}
