<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div ngbRadioGroup name="nonSelectedItems" [(ngModel)]="selectedItemId">
        <div style="padding: 2px;" *ngFor="let item of nonSelectedItems">
            <div class="btn-group-lg btn-group btn-group-toggle">
                <label ngbButtonLabel class="btn-focus">
                    <input ngbButton type="radio" [value]="item.id">
                    <div>{{item.name}}</div>                    
                </label>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button [disabled]="!selectedItemId" type="button" class="btn btn-secondary"
        (click)="onAccept(selectedItemId)">Accept</button>
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">Cancel</button>
</div>