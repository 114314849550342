import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyLogoUploadDialogComponent } from 'src/app/components/dialogs/admin/company-logo-upload-dialog/company-logo-upload-dialog.component';
import { Company } from 'src/app/models/services/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyLogoUploadDialogService {

  constructor(
    private modalService: NgbModal
  ) { }

  public show = (company: Company, acceptCallback: () => void, rejectCallback?: () => void): void => {
    var ref = this.modalService.open(CompanyLogoUploadDialogComponent, {
      backdrop: 'static'
    });

    // Get the OkDialog from the NgbModal object  
    let dialog: CompanyLogoUploadDialogComponent = ref.componentInstance as CompanyLogoUploadDialogComponent;

    // Set the dialog's properties
    dialog.company = company;
    dialog.accept = () => {      
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback();
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }

}
