import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/http.service';
import { Observable, of, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalsService } from '../globals.service';
import { AudiencesBuilderConfiguration, AudiencesBuilderConfigurationV2 } from '../../models/services/taxonomiesConfig';
import { IndexedDbService } from '../core/indexed-db.service';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudiencesBuilderConfigService {
  private cachedCommonTaxonomyV2: AudiencesBuilderConfigurationV2;
  private commonTaxonomyV2StoredRefreshPeriodMs: number = 3600000; // 1 hour
  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService,
    private indexedDbService: IndexedDbService
  ) { }

  public getAudienceBuilderConfig(): Observable<AudiencesBuilderConfiguration> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/configuration/general`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public getAudienceBuilderTransactionalConfig(): Observable<AudiencesBuilderConfiguration> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/configuration/transactional`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public getAudienceBuilderConfigV2(): Observable<AudiencesBuilderConfigurationV2> {
    let _this: AudiencesBuilderConfigService = this;
    return new Observable<AudiencesBuilderConfigurationV2>(subscriber => {
      forkJoin([
        this.getAudienceBuilderCommonConfigV2(),
        this.getAudienceBuilderAudiencesConfigV2()
      ]).subscribe({
        next(resp: [AudiencesBuilderConfigurationV2, AudiencesBuilderConfigurationV2]) {
          let config:AudiencesBuilderConfigurationV2  = {
            taxonomy: resp[0].taxonomy.concat(resp[1].taxonomy),
            queryBuilderConfig: {
              fields: {...resp[0].queryBuilderConfig.fields, ...resp[1].queryBuilderConfig.fields}
            }
          };
          subscriber.next(config);        
        },
        error(err) { 
          subscriber.error(err);
        },
        complete() { 
          subscriber.complete();
          timer(_this.commonTaxonomyV2StoredRefreshPeriodMs).subscribe(() => _this.raiseCommonTaxonomyV2StoredRefresh());
        }
      });
    });
  }  

  private getAudienceBuilderCommonConfigV2(): Observable<AudiencesBuilderConfigurationV2> {
    if (!!this.cachedCommonTaxonomyV2)
      return of(this.cachedCommonTaxonomyV2);

    return new Observable<AudiencesBuilderConfigurationV2>(subscriber => {
      this.indexedDbService.get<AudiencesBuilderConfigurationV2>('cache', 'objects', 'commonConfigV2').subscribe(x => {
        if (!!x) {
          this.cachedCommonTaxonomyV2 = x;
          subscriber.next(x);
        } else {
          let url: string = `${environment.audiencesServicesApiBaseUri}/configuration/v2/common`;
          this.httpService.get<AudiencesBuilderConfigurationV2>(url, this.globalsService.getActiveCompany()?.id).pipe(
            tap(x => this.cachedCommonTaxonomyV2 = x),            
            tap(x => this.saveCommonTaxonomyV2(x))
          ).subscribe(x => {
            subscriber.next(x);
          }, (error) => {
            subscriber.error(error);
          }, () => {
            subscriber.complete();
          });
        }
      }, (error) => {
        subscriber.error(error);
      }, () => {
        if (!!this.cachedCommonTaxonomyV2)
          subscriber.complete();
      });
    });
  }  

  private getAudienceBuilderAudiencesConfigV2(): Observable<AudiencesBuilderConfigurationV2> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/configuration/v2/audiences`;
    return this.httpService.get<AudiencesBuilderConfigurationV2>(url, this.globalsService.getActiveCompany()?.id);
  }

  private saveCommonTaxonomyV2 = (config: AudiencesBuilderConfigurationV2) => {
    this.indexedDbService.save('cache', 'objects', 'commonConfigV2', config).subscribe();
  };    

  raiseCommonTaxonomyV2StoredRefresh = () => {
    let url: string = `${environment.audiencesServicesApiBaseUri}/configuration/v2/common`;
    this.httpService.get<AudiencesBuilderConfigurationV2>(url, this.globalsService.getActiveCompany()?.id).subscribe(
      x => {
        this.cachedCommonTaxonomyV2 = x;            
        this.indexedDbService.delete('cache', 'objects', 'commonConfigV2').subscribe(y => {
          this.saveCommonTaxonomyV2(x);
        });
      },
      () => {},
      () => {
        // Raise next refresh
        timer(this.commonTaxonomyV2StoredRefreshPeriodMs).subscribe(() => this.raiseCommonTaxonomyV2StoredRefresh());
      });
  };    

}
