import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseTenantRequiredPageComponent } from 'src/app/components/core/base-tenant-required-page/base-tenant-required-page.component';
import { GlobalsService } from 'src/app/services/globals.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.sass']
})
export class InsightsComponent extends BaseTenantRequiredPageComponent implements OnInit { 

  public heading: string = 'General insights';
  public subheading: string = '';
  public icon: string = 'pe-7s-graph2 icon-gradient bg-midnight-bloom';

  constructor(
    private globalsService: GlobalsService,
    usersService: UsersService,
    router: Router) { 
    super(
      usersService.hasSelectedCompanySectionRight('Insights'),
      globalsService, 
      router);    
  }

  ngOnInit(): void {
  }

  isReportConfigured = (reportId: number): boolean => {    
    return this.globalsService.getActiveCompany()?.predefinedReports?.findIndex(x => x.id == reportId) >= 0;
  }
}
