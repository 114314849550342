import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Audience } from 'src/app/models/services/audiences/amazon/audience';
import { Advertiser } from 'src/app/models/services/audiences/amazon/advertiser';
import { Profile } from 'src/app/models/services/audiences/amazon/profile';
import { AmazonAudiencesService } from 'src/app/services/audiences/amazon-audiences.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-amazon-attributes',
  templateUrl: './amazon-attributes.component.html',
  styleUrls: ['./amazon-attributes.component.sass']
})
export class AmazonAttributesComponent implements OnInit {
  @ViewChild('matAutocompleteProfiles') inputAutocompleteProfiles: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocompleteAdvertisers') inputAutocompleteAdvertisers: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocompleteAudiences') inputAutocompleteAudiences: ElementRef<HTMLInputElement>;

  @Input() 
  form: FormGroup;

  @Input() 
  updateValueAndValidity: () => void;

  @Input() 
  erisAudienceName: string;

  profiles: Profile[] = [];
  filteredProfiles: Profile[] = [];
  advertisers: Advertiser[] = [];
  filteredAdvertisers: Advertiser[] = [];
  audiences: Audience[] = [];
  filteredAudiences: Audience[] = [];
  newAudience: string = '0';

  constructor(
    private amazonAudiencesServices: AmazonAudiencesService,
    private spinnerService: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinnerService.show("dialogSpinner");
    this.amazonAudiencesServices.getProfiles().subscribe(
      resp => {
        this.profiles = resp;

        this.onNewAudienceSelected();

        this.spinnerService.hide("dialogSpinner");        
      }
    )
  }

  onProfileChange = ($event: MatAutocompleteSelectedEvent): void => {
    this.spinnerService.show("dialogSpinner");
    this.resetAdvertiserControls([]);
    let _this: AmazonAttributesComponent = this;
    this.amazonAudiencesServices.getAdvertisers(+$event.option.value).subscribe(
      resp => {
        this.advertisers = resp;
        this.spinnerService.hide("dialogSpinner");
        this.inputAutocompleteProfiles.nativeElement.blur();        
      }
    )
  }

  getProfileDescription = (profileId?: number): string => {
    return this.profiles.find(x => x.profileId == profileId)?.accountInfo.name;
  }

  resetProfilesFilter = (): void => {
    this.inputAutocompleteProfiles.nativeElement.value = '';
    this.resetProfileControls(this.profiles);
  }

  filterProfiles = (): void => {
    const filterValue = this.inputAutocompleteProfiles.nativeElement.value.toLowerCase();
    this.resetProfileControls(this.profiles.filter(o => o.accountInfo.name.toLowerCase().includes(filterValue)));
  }

  onAdvertiserChange = ($event: MatAutocompleteSelectedEvent): void => {      
    this.resetAudienceControls([]);  
    if (!(+this.newAudience)) {
      this.spinnerService.show("dialogSpinner");
      this.amazonAudiencesServices.getAudiences(+this.form.controls.profileId.value, BigInt($event.option.value)).subscribe(
        resp => {
          this.audiences = resp;          
          this.spinnerService.hide("dialogSpinner");
          this.inputAutocompleteAdvertisers.nativeElement.blur();          
          this.updateValueAndValidity();
        }
      )
    }
  }

  getAdvertiserDescription = (advertiserId?: number): string => {
    return this.advertisers.find(x => x.advertiserId == advertiserId)?.name;
  }
  
  resetAdvertisersFilter = (): void => {
    this.inputAutocompleteAdvertisers.nativeElement.value = '';
    this.resetAdvertiserControls(this.advertisers);
  }

  filterAdvertisers = (): void => {
    const filterValue = this.inputAutocompleteAdvertisers.nativeElement.value.toLowerCase();
    this.resetAdvertiserControls(this.filteredAdvertisers = this.advertisers.filter(o => o.name.toLowerCase().includes(filterValue)));
  }

  getAudienceDescription = (audienceId?: string): string => {
    let audience: Audience = this.audiences.find(x => x.audienceId == audienceId);
    return !!audience ? `${audience.audienceId} - ${audience.audienceName}` : '';
  }
  
  resetAudiencesFilter = (): void => {
    this.inputAutocompleteAudiences.nativeElement.value = '';
    this.resetAudienceControls(this.audiences);
  }
    
  filterAudiences = (): void => {
    const filterValue = this.inputAutocompleteAudiences.nativeElement.value.toLowerCase();
    this.resetAudienceControls(this.audiences.filter(o => `${o.audienceId} - ${o.audienceId}`.toLowerCase().includes(filterValue)));
    if (filterValue != this.form.controls.audienceName.value?.toLowerCase())
    {
      this.form.controls.audienceName.setValue(null);
      this.updateValueAndValidity();  
    }  
  }

  onAudienceChange = ($event: MatAutocompleteSelectedEvent): void => {    
    this.inputAutocompleteAdvertisers.nativeElement.blur();
    this.form.controls.audienceName.setValue(this.inputAutocompleteAudiences.nativeElement.value);
    this.updateValueAndValidity();
  }

  onNewAudienceSelected = (): void => {
    this.form.controls.audienceId.setValue(null);
    this.form.controls.audienceName.setValue(this.erisAudienceName);
    if (!!(+this.newAudience))
      this.form.controls.audienceId.setValidators([]);
    else
      this.form.controls.audienceId.setValidators([Validators.required]);

    this.form.controls.audienceId.updateValueAndValidity();
    this.form.controls.audienceName.updateValueAndValidity();
    this.updateValueAndValidity();
  }

  resetProfileControls = (filteredProfiles: Profile[]): void => {
    this.filteredProfiles = filteredProfiles;
    if (filteredProfiles == this.profiles)
      this.form.controls.profileId.setValue(null);
    if (!!this.inputAutocompleteAdvertisers)
      this.inputAutocompleteAdvertisers.nativeElement.value = '';

    this.advertisers = [];
    this.resetAdvertiserControls([]);
  }

  resetAdvertiserControls = (filteredAdvertisers: Advertiser[]): void => {
    this.filteredAdvertisers = filteredAdvertisers;
    if (filteredAdvertisers == this.advertisers || filteredAdvertisers.length == 0)
      this.form.controls.advertiserId.setValue(null);          
    if (!!this.inputAutocompleteAudiences)
      this.inputAutocompleteAudiences.nativeElement.value = '';

    this.audiences = [];
    this.resetAudienceControls([]);
  }
  
  resetAudienceControls = (filteredAudiences: Audience[]): void => {
    this.filteredAudiences = filteredAudiences;
    if (filteredAudiences == this.audiences)
      this.form.controls.audienceId.setValue(null);
    if (!(+this.newAudience))
      this.form.controls.audienceName.setValue(null);

    this.form.controls.audienceId.updateValueAndValidity();
    this.form.controls.audienceName.updateValueAndValidity();
    this.updateValueAndValidity();
  }
}
