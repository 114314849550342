import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Rule, RuleSet } from 'angular2-query-builder';
import { AudienceRuleBuilderDialogComponent } from 'src/app/components/dialogs/audiences-builder/audience-rule-builder-dialog/audience-rule-builder-dialog.component';
import { TaxonomyItem } from 'src/app/models/services/taxonomiesConfig';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class AudienceRuleBuilderDialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public show = (acceptCallback: (rule: Rule) => void, taxonomy: TaxonomyItem[]): void => {    
    var ref = this.modalService.open(AudienceRuleBuilderDialogComponent, { size: 'xl' });
    var output: Rule;

    // Get the OkDialog from the NgbModal object  
    let dialog: AudienceRuleBuilderDialogComponent = ref.componentInstance as AudienceRuleBuilderDialogComponent;

    // Set the dialog's properties
    dialog.accept = (rule: Rule) => {
      output = rule;
      ref.close(1); 
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    dialog.taxonomy = taxonomy;

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          break;
      }
    })
  }
}
