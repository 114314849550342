import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTransferInfoDialogComponent } from 'src/app/components/dialogs/admin/data-transfer-info-dialog/data-transfer-info-dialog.component';
import { DataTransfer } from 'src/app/models/services/dataTransferInfo';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class DataTransferInfoDialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public show = (dataTransfer: DataTransfer): void => {    
    var ref = this.modalService.open(DataTransferInfoDialogComponent, { size: 'xl' });

    // Get the OkDialog from the NgbModal object  
    let dialog: DataTransferInfoDialogComponent = ref.componentInstance as DataTransferInfoDialogComponent;

    dialog.dataTransfer = dataTransfer;

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

  }
}
