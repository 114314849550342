<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p [style]="allowLineBreaks ? 'white-space: pre;' : ''">{{message}}</p>
</div>
<div class="modal-footer">
    <button *ngIf="button1Visible" type="button" class="btn btn-secondary" (click)="close(1)">{{button1Text}}</button>
    <button *ngIf="button2Visible" type="button" class="btn btn-secondary" (click)="close(2)">{{button2Text}}</button>
</div>