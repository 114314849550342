import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TargetPlatformSelectorDialogComponent } from 'src/app/components/dialogs/audiences/target-platform-selector-dialog/target-platform-selector-dialog.component';
import { Audience, AudienceFile } from 'src/app/models/services/audience';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class TargetPlatformSelectorDialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public show = (audience: Audience, send: boolean, acceptCallback: (data: AudienceFile) => void, rejectCallback?: () => void): void => {    
    var ref = this.modalService.open(TargetPlatformSelectorDialogComponent, { size: 'xl' });
    var output: AudienceFile;

    // Get the OkDialog from the NgbModal object  
    let dialog: TargetPlatformSelectorDialogComponent = ref.componentInstance as TargetPlatformSelectorDialogComponent;
    
    // Set the dialog's properties
    dialog.audience = audience;
    dialog.send = send;
    dialog.accept = (data: AudienceFile) => {
      output = data;
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }
}
