import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SegmentFile } from 'src/app/models/services/segmentFile';
import { DialogService } from 'src/app/services/core/dialog.service';
import { SegmentFileStatus } from 'src/app/models/services/segmentFileStatus.enum';
import { SegmentFilesService } from 'src/app/services/segment-files.service';
import { timer } from 'rxjs';
import { MatchingParametersDialogService } from 'src/app/services/dialogs/matching-parameters-dialog.service';
import { MatchingParameters } from 'src/app/models/services/matchingParameters';
import { BaseTenantRequiredPageComponent } from 'src/app/components/core/base-tenant-required-page/base-tenant-required-page.component';
import { GlobalsService } from 'src/app/services/globals.service';
import { UsersService } from 'src/app/services/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends BaseTenantRequiredPageComponent implements OnInit, OnDestroy {
  public heading: string = 'Segment files upload';
  public subheading: string = '';
  public icon: string = 'pe-7s-copy-file icon-gradient bg-midnight-bloom';

  files: any[] = [];
  activePolling: boolean = false;
  uploadingFile: boolean = false;

  constructor(
    private segmentFilesService: SegmentFilesService,
    private dialogService: DialogService,
    private matchingParametersDialogService: MatchingParametersDialogService,
    globalsService: GlobalsService,
    usersService: UsersService,
    router: Router) { 
    super(
      usersService.hasSelectedCompanySectionRight('Segments'),
      globalsService, 
      router);    
  }    

  ngOnInit(): void {
    if(this.redirecting)
      return;
      
    this.activePolling = true;
    this.loadUploadedFiles();
  }

  ngOnDestroy(): void {
    this.activePolling = false;
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event) {
    this.prepareFilesList($event.target.files);
    $event.target.value = '';
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    let data: SegmentFile = this.files[index].segmentFileData;
    this.dialogService.showConfirmation(
      `Are you sure to remove "${data.fileName}"?`,
      () => {
        this.segmentFilesService.delete(data.id).subscribe(
          () => {
            this.files.splice(index, 1);
          });
      },
      () => { });
  }

  /**
   * Process file from files list according current status
   * @param index (File index)
   */
  processStatus(index: number) {
    let segmentFile: SegmentFile = this.files[index].segmentFileData;
    switch (segmentFile?.status) {
      case SegmentFileStatus.Valid:
        this.matchingParametersDialogService.show(
          segmentFile,
          (matchingParameters: MatchingParameters) => {
            this.segmentFilesService.sendToMatch(segmentFile.id, matchingParameters).subscribe(
              () => {
                this.files.splice(index, 1);
              });
          },
          () => { }
        );
    };

  }

  /**
 * Process file from files list according current status
 * @param index (File index)
 */
  getStatusStyle(index: number) {
    let data: SegmentFile = this.files[index].segmentFileData;
    switch (data?.status) {
      case SegmentFileStatus.NonValid:
        return "pe-7s-attention";
      case SegmentFileStatus.Valid:
        return "pe-7s-paper-plane";
      default:
        return "pe-7s-hourglass fa-pulse";
    };
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);

      // Upload
      this.uploadFile(item);
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private uploadFile = (file: any): void => {
    this.uploadingFile = true;

    this.segmentFilesService.upload(file).subscribe(
      resp => {
        if (resp.type === HttpEventType.Response) {
          let response = resp as HttpResponse<SegmentFile>;
          file.segmentFileData = response.body;
          this.uploadingFile = false;
          console.log('Upload complete');
        }
        if (resp.type === HttpEventType.UploadProgress) {

          let percentDone: number = Math.round(100 * resp.loaded / resp.total);
          file.progress = percentDone;

          console.log('Progress ' + percentDone + '%');
        }
      });
  }

  private loadUploadedFiles = (): void => {
    this.segmentFilesService.getAll().subscribe(
      resp => {
        console.log(`Uploading: ${this.uploadingFile}`)
        if (!this.uploadingFile) {
          // Rebind files
          this.files = [];
          resp.forEach(segmentFile => {
            this.files.push({
              name: segmentFile.fileName,
              size: segmentFile.size,
              progress: 100,
              segmentFileData: segmentFile
            });
          });
        }
        // Next iteration
        if (this.activePolling)
          timer(1000).subscribe(() => this.loadUploadedFiles());
      }
    )
  }

}
