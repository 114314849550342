import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Segment } from 'src/app/models/services/segment';
import { SegmentStatus } from 'src/app/models/services/segmentStatus.enum';
import { SegmentsService } from 'src/app/services/segments.service';
import { timer } from 'rxjs';
import { DialogService } from 'src/app/services/core/dialog.service';
import { PublicisTableDataSource } from 'src/app/models/components/publicis-table';
import { LookAlikesService } from 'src/app/services/look-alikes.service';
import { LookAlikeSourceTypes } from 'src/app/models/services/lookAlikeSourceTypes.enum';
import { BaseTenantRequiredPageComponent } from 'src/app/components/core/base-tenant-required-page/base-tenant-required-page.component';
import { GlobalsService } from 'src/app/services/globals.service';
import { UsersService } from 'src/app/services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-segments-list',
  templateUrl: './segments-list.component.html',
  styleUrls: ['./segments-list.component.sass']
})
export class SegmentsListComponent extends BaseTenantRequiredPageComponent implements OnInit, OnDestroy {
  public heading: string = 'Segments list';
  public subheading: string = '';
  public icon: string = 'pe-7s-copy-file icon-gradient bg-midnight-bloom';

  private originalSegments: Segment[];
  private activePolling: boolean = false;
  public segmentsDataSource: PublicisTableDataSource = {
    tableDefinition: [
      {
        headerDescription: 'Segment',
        enableSort: true,
        sortFieldName: 'fileName'
      }, {
        headerDescription: 'Created',
        enableSort: true,
        sortFieldName: 'createdOn'      
      }, {
        headerDescription: 'Identifiers uploaded',
        enableSort: true,
        sortFieldName: 'uploadedRecords'      
      }, {
        headerDescription: 'Matched profiles',
        enableSort: true,
        sortFieldName: 'matchedRecords'      
      }, {
        headerDescription: '',
        enableSort: false      
      }, {
        headerDescription: '',
        enableSort: false      
      }       
    ],
    data: [],
    enableSort: true,
    enablePagination: true,
    pageSize: 10
  };
  public segmentsFilter: string;

  constructor(    
    private segmentsService: SegmentsService,
    private lookAlikesService: LookAlikesService,
    private router: Router,
    private dialogService: DialogService,
    globalsService: GlobalsService,
    usersService: UsersService,
    private spinnerService: NgxSpinnerService) { 
    super(
      usersService.hasSelectedCompanySectionRight('Segments'),
      globalsService, 
      router);    
  }  

  ngOnInit(): void {
    if(this.redirecting)
      return;
      
    this.activePolling = true;
    this.loadSegments();
  }

  ngOnDestroy(): void {
    this.activePolling = false;
  }

  private loadSegments = (): void => {
    // Show spinner just first time
    if(!this.originalSegments)
      this.spinnerService.show();

    this.segmentsService.getAll().subscribe(
      resp => {
        this.originalSegments = resp;
        this.filterSegments(this.segmentsFilter);
        // Next iteration
        if (this.activePolling)
          timer(1000).subscribe(() => this.loadSegments());
          this.spinnerService.hide();
      }, () => {
        this.spinnerService.hide();
      }
    )
  }

  getStatusStyle = (segment: Segment): string => {
    switch (segment?.status) {
      case SegmentStatus.Success:
        return "pe-7s-check";
      case SegmentStatus.Error:
        return "pe-7s-attention";
      default:
        return "pe-7s-hourglass fa-pulse";
    };
  }

  getStatusLabel = (segment: Segment): string => {
    switch (segment?.status) {
      case SegmentStatus.Success:
          return "Done";
      case SegmentStatus.Error:
        return "Error";
      case SegmentStatus.IdentifiersFileCreated:
        return "Identifiers extracted, matching profiles ...";
      case SegmentStatus.CalculatingLookAlikePercentages:
        return "Calculating look alikes parameters ...";
      case SegmentStatus.CalculatingSegmentInsights:
        return "Calculating segment insights ...";
      default:
        return "Extracting identifiers ...";
    };
  }

  viewInsights = (segment: Segment): void => {
    this.router.navigate([ '/segments', segment.id, 'insights' ]);
  }

  calculateLookAlikesParameters = (segment: Segment): void => {
    this.dialogService.showConfirmation(
      `Are you sure to calculate "${segment.fileName}" look alike parameters?`,
      () => {
        this.lookAlikesService.calculateLookAlikesParameters(LookAlikeSourceTypes.Segment, segment.id).subscribe(
          () => { });
      },
      () => { });
  }

  showLookAlikes = (segment: Segment): void => {
    this.router.navigate([ '/segments', segment.id, 'look-alikes' ]);
  }

  deleteSegment = (segment: Segment): void => {
    this.dialogService.showConfirmation(
      `Are you sure to remove "${segment.fileName}"?\n If the segment has related elements (look alikes, audiences, ...)\n  they will be removed too.`,
      () => {
        this.segmentsService.delete(segment.id).subscribe(
          () => {
            let index: number = this.segmentsDataSource.data.findIndex(x => x.id == segment.id)
            this.segmentsDataSource.data.splice(index, 1);
          });
      },
      () => { });
  }

  calculateInsights = (segment: Segment): void => {
    this.dialogService.showConfirmation(
      `Are you sure to ${segment.segmentInsightsCalculated ? 'refresh' : 'calculate'} "${segment.fileName}" insights?`,
      () => {
        this.segmentsService.calculateSegmentInsights(segment.id).subscribe(
          () => { });
      },
      () => { });
  }  

  filterSegments = (searchTerm: string): void => {
    this.segmentsFilter = searchTerm;
    // We need to build the full object in order to make the publicis-table component detect changes
    let cloned: PublicisTableDataSource = { ...this.segmentsDataSource};
    cloned.data =         
      !!searchTerm ? 
      this.originalSegments.filter((x: Segment) => { return x.fileName.toUpperCase().indexOf(searchTerm.toUpperCase()) != -1; } ) :
      this.originalSegments;
    this.segmentsDataSource = cloned;
  }  
}
