import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InputNameDialogComponent } from '../../components/core/input-name-dialog/input-name-dialog.component';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class InputNameDialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public show = (title: string, confirmationMessage: string, acceptCallback: (name: string) => void, rejectCallback?: () => void): void => {
    var ref = this.modalService.open(InputNameDialogComponent);
    var outputName: string;

    // Get the OkDialog from the NgbModal object  
    let dialog: InputNameDialogComponent = ref.componentInstance as InputNameDialogComponent;

    // Set the dialog's properties
    dialog.title = title;
    dialog.message = confirmationMessage;
    dialog.accept = (name: string) => {
      outputName = name;
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(outputName);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }
}
