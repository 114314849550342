import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ADB2CUser } from 'src/app/models/services/adB2CUser';
import { UsersService } from 'src/app/services/users.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-ad-b2c-user-selector-dialog',
  templateUrl: './ad-b2c-user-selector-dialog.component.html',
  styleUrls: ['./ad-b2c-user-selector-dialog.component.sass']
})
export class AdB2cUserSelectorDialogComponent implements OnInit {

  adB2CUsers: ADB2CUser[];
  title: string = "Select an AD B2C user";
  selectedAdB2CUserId: string;

  constructor(
    private usersService: UsersService,    
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinnerService.show('dialogSpinner');
    forkJoin([
      this.usersService.getAllADB2CUsers(),
      this.usersService.getAllUsers()
    ]).subscribe(resp => {
      // AD users not in the current environment
      this.adB2CUsers = resp[0]
        .filter((x) => resp[1].findIndex(y => y.adB2CId == x.id) < 0)
        .sort((x, y) => x.name.toUpperCase() > y.name.toUpperCase() ? 1 : x.name.toUpperCase() < y.name.toUpperCase() ? -1 : 0);
      this.spinnerService.hide('dialogSpinner');
    });
  }

  public accept: (selectedAdB2CUser: ADB2CUser) => void;
  public dismiss: () => void;

  public onAccept = (selectedAdB2CUserId: string): void  => {   
    let selectedAdB2CUser: ADB2CUser = this.adB2CUsers.find(x => x.id == selectedAdB2CUserId);
    this.accept(selectedAdB2CUser);
  }

  onDismiss = (): void => {
    this.dismiss();
  }
}
