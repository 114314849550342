<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Weborama QA Report - {{selectedReport.date | date}}</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>General data</b></h3>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Total records: {{selectedReport.totalRecords | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Non valid WAM uids (length &lt;&gt; 22):: {{selectedReport.nonValidIdentifiers | number:'':'es' }}
</div>  
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Unique identities: {{selectedReport.uniqueIdentifiersRecords | number:'':'es' }}
</div>                 
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Identities with no wam section: {{selectedReport.identifiersNoWamSection | number:'':'es' }}
</div>         
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Identities with no profile section: {{selectedReport.identifiersNoProfileSection | number:'':'es' }}
</div>            
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Identities with no profile section and no techno attributes: {{selectedReport.identifiersNoProfileSectionAndNoTechnoSection | number:'':'es' }}
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Identities with no profile section and just ISP techno attribute: {{selectedReport.identifiersNoProfileSectionAndOnlyISPInTechnoSection | number:'':'es' }}
</div>
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h3><b>Anomalies</b></h3>
</div>
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h4><b>Countries (non Spain) with profiles</b></h4>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Top 20 countries (non Spain) with profiles
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Country</th>
                <th scope="col">Occurrences</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let top20Item of selectedReport.anomalies.nonSpanishProfiles.top20; index as i">
                <td>{{ top20Item.country }}</td>
                <td>{{ top20Item.count | number:'':'es' }}</td>
            </tr>
        </tbody>
    </table>        
</div>          
<div class="row">&nbsp;</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <h4><b>Other cookie types</b></h4>
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    Top 20 other cookie types
</div>
<div class="row" style="padding-left: 15px; padding-top: 10px">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Country</th>
                <th scope="col">Occurrences</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let top20Item of selectedReport.anomalies.otherCookieTypes.top20; index as i">
                <td>{{ top20Item.cookieType }}</td>
                <td>{{ top20Item.count | number:'':'es' }}</td>
            </tr>
        </tbody>
    </table>        
</div>      