import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-name-dialog',
  templateUrl: './input-name-dialog.component.html',
  styleUrls: ['./input-name-dialog.component.sass']
})
export class InputNameDialogComponent implements OnInit {

  form: FormGroup;
  name: string;
  title: string;
  message: string;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required]
      });
  }

  ngOnInit(): void {
  }

  public accept: (name: string) => void;
  public dismiss: () => void;
}