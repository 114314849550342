import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatchingParametersDialogComponent } from 'src/app/components/blocks/segments/matching-parameters-dialog/matching-parameters-dialog.component';
import { MatchingParameters } from 'src/app/models/services/matchingParameters';
import { SegmentFile } from 'src/app/models/services/segmentFile';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class MatchingParametersDialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public show = (segmentFile: SegmentFile, acceptCallback: (data: MatchingParameters) => void, rejectCallback?: () => void): void => {    
    var ref = this.modalService.open(MatchingParametersDialogComponent, {
      size: 'xl'
    });
    var output: MatchingParameters;

    // Get the OkDialog from the NgbModal object  
    let dialog: MatchingParametersDialogComponent = ref.componentInstance as MatchingParametersDialogComponent;

    // Set the dialog's properties
    dialog.accept = (data: MatchingParameters) => {
      output = data;
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    dialog.segmentFile = segmentFile; 
    dialog.refreshDataPreview();

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }
}
