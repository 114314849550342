import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';
import { HttpEvent } from '@angular/common/http';
import { SegmentFile } from '../models/services/segmentFile';
import { MatchingParameters } from '../models/services/matchingParameters';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class SegmentFilesService {

  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService
  ) { }

  public upload = (file: any): Observable<HttpEvent<SegmentFile>> => {
    let formData = new FormData();
    formData.append("file", file);

    let url: string = `${environment.filesServicesApiBaseUri}/files`
    return this.httpService.postWithProgress(url, formData, this.globalsService.getActiveCompany()?.id);
  }

  public delete(id: string): Observable<SegmentFile> {
    let url: string = `${environment.filesServicesApiBaseUri}/files/${id}`;
    return this.httpService.delete(url, this.globalsService.getActiveCompany()?.id)
  }

  public sendToMatch(id: string, data: MatchingParameters): Observable<SegmentFile> {
    let url: string = `${environment.filesServicesApiBaseUri}/files/${id}/match`;
    return this.httpService.post(url, data, this.globalsService.getActiveCompany()?.id)
  }

  public getAll(): Observable<Array<SegmentFile>> {
    let url: string = `${environment.filesServicesApiBaseUri}/files`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id)
  }
}