import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass']
})
export class DialogComponent implements OnInit {

  title: string
  message: string;
  button1Visible: boolean;
  button1Text: string;
  button2Visible: boolean;
  button2Text: string;
  allowLineBreaks: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  public dismiss: (result: string) => void;
  public close: (result: string) => void;

}
