import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AudienceSchedulingParametersDialogComponent } from 'src/app/components/dialogs/audiences/audience-scheduling-parameters-dialog/audience-scheduling-parameters-dialog.component';
import { UsersService } from '../users.service';
import { DialogService } from '../core/dialog.service';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class AudienceSchedulingParametersDialogService {
  lastErrorCloseResult: string;

  constructor(
    private modalService: NgbModal,
    private usersService: UsersService,
    private dialogService: DialogService
  ) { }

  public show = (acceptCallback: (schedulingId: number, endDate?: Date) => void, rejectCallback?: () => void, schedulingId?: number, endDate?: Date): void => {    
    if (!this.usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesScheduler'))
      this.dialogService.showMessage('Audiences scheduling', 'User has not rights to schedule audiences on this company');

    let ref = this.modalService.open(AudienceSchedulingParametersDialogComponent);
    let outputSchedulingId: number;
    let outputEndDate: Date;

    // Get the OkDialog from the NgbModal object  
    let dialog: AudienceSchedulingParametersDialogComponent = ref.componentInstance as AudienceSchedulingParametersDialogComponent;

    // Set the dialog's properties
    dialog.accept = (schedulingId: number, endDate?: Date) => {
      outputSchedulingId = schedulingId;
      outputEndDate = endDate;
      ref.close(1); 
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    dialog.currentAudienceSchedulingId = schedulingId;
    dialog.currentAudienceSchedulingDate = endDate;
    
    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(outputSchedulingId, outputEndDate);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }
}
