import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Audience, AudienceParameters } from 'src/app/models/services/audience';
import { CreateAudienceRequest } from 'src/app/models/services/createAudience';
import { LookAlike } from 'src/app/models/services/lookAlike';
import { LookAlikeSourceTypes } from 'src/app/models/services/lookAlikeSourceTypes.enum';
import { Segment } from 'src/app/models/services/segment';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';
import { DialogService } from 'src/app/services/core/dialog.service';
import { AudienceParametersDialogService } from 'src/app/services/dialogs/audience-parameters-dialog.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { LookAlikesService } from 'src/app/services/look-alikes.service';
import { SegmentsService } from 'src/app/services/segments.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-look-alikes-list',
  templateUrl: './look-alikes-list.component.html',
  styleUrls: ['./look-alikes-list.component.sass']
})
export class LookAlikesListComponent implements OnInit {
  public heading: string = '';
  public subheading: string = '';
  public icon: string = 'pe-7s-users icon-gradient bg-midnight-bloom';

  public sourceType: LookAlikeSourceTypes;
  public sourceIdentifier: string;
  public navigationUrlBase: string;
  public lookAlikes: Array<LookAlike>;

  constructor(
    private globals: ThemeOptions,
    private route: ActivatedRoute,
    private segmentsServices: SegmentsService,
    private lookAlikesServices: LookAlikesService,
    private audiencesServices: AudiencesService,
    private dialogService: DialogService,
    private audienceParametersDialogService: AudienceParametersDialogService,
    private spinnerService: NgxSpinnerService,
    private globalsService: GlobalsService,
    private usersService: UsersService,
    private router: Router) { }

  ngOnInit(): void {
    // If no active company navigate to root
    if (!this.globalsService.getActiveCompany()) {
      this.router.navigate(["/"]);
      return;
    }

    this.spinnerService.show();
    this.globals.backEnabled = true;
    this.sourceIdentifier = this.route.snapshot.paramMap.get('sourceIdentifier');
    this.route.data.subscribe(data => {
      this.sourceType = data.sourceType;
      switch(this.sourceType) {
        case LookAlikeSourceTypes.Segment:
          // If no rights navigate to root
          if(!this.usersService.hasSelectedCompanySectionRight('Segments')) {
            this.router.navigate(["/"]);
            return;
          }
          this.segmentsServices.get(this.sourceIdentifier).subscribe(
            (data: Segment) => {
              this.heading = `Segment '${data.fileName}' look alikes`;
            },
            () => {
            }      
          );             
          this.globals.backLabel = "Back to segments list";       
          this.navigationUrlBase = "/segments";  
          break;

        default:
          // If no rights navigate to root
          if(!this.usersService.hasSelectedCompanyActionRight('Audiences', 'AudiencesList', 'Manage')) {
            this.router.navigate(["/"]);
            return;
          }          
          var audienceId = +this.sourceIdentifier;
          this.audiencesServices.getAudience(audienceId).subscribe(
            (data: Audience) => {
              this.heading = `Audience '${data.name}' look alikes`;      
            }      
          );            
          this.globals.backLabel = "Back to audiences list";
          this.navigationUrlBase = "/audiences";  
          break;
      }
    });
 
    this.lookAlikesServices.getLookAlikes(this.sourceType, this.sourceIdentifier).subscribe(
      (data: Array<LookAlike>) => {
        this.spinnerService.hide();
        this.lookAlikes = data;
      },
      () => {
        this.spinnerService.hide();
      }      
    );    
  }

  ngOnDestroy(): void {
    this.globals.backEnabled = false;
    this.globals.backLabel = "";
  }

  getStatusStyle = (index: number): string => {
        return "pe-7s-check";
  }

  getStatusLabel = (index: number): string => {
        return "Done";
  }

  createAudience = (index: number): void => {
    let lookAlike: LookAlike = this.lookAlikes[index];
    this.audienceParametersDialogService.show(
      (audienceParameters: AudienceParameters) => {
        // Save audience
        this.spinnerService.show();
        let request: CreateAudienceRequest = {
          name: audienceParameters.name,
          categoryId: audienceParameters.categoryId,
          query: lookAlike.conditions,
          lookAlikeSourceType: this.sourceType,
          lookAlikeSourceIdentifier: this.sourceIdentifier,
          schedulingId: audienceParameters.schedulingId,
          schedulingEndDate: audienceParameters.schedulingEndDate
        };
        this.audiencesServices.createAudience(request).subscribe(
          () => {
            this.spinnerService.hide();
            this.dialogService.showMessage("Create audience", "Audience created");
          },
          () => {
            this.spinnerService.hide();
          }
        );
      },
      () => { }
    );  
  }

  deleteLookAlike = (index: number): void => {
    let data: LookAlike = this.lookAlikes[index];
    this.dialogService.showConfirmation(
      `Are you sure to remove "${data.name}"?`,
      () => {        
        this.lookAlikesServices.removeLookAlike(this.sourceType, data).subscribe(
          () => {
            this.lookAlikes.splice(index, 1);
          });        
      },
      () => { });
  }  

  downloadLookAlikeParametersCsv = (): void => {
    this.spinnerService.show();
    this.lookAlikesServices.downloadLookAlikesParametersCsv(this.sourceType,this.sourceIdentifier).subscribe(
      (result: Blob) => {
        /*
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          window.navigator.msSaveOrOpenBlob(csvData, file_name);
        } else { // for Non-IE (chrome, firefox etc.)*/
        let fileName: string = `parameters ${ this.sourceIdentifier }.csv`;
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = URL.createObjectURL(result);
        a.download = fileName;

        this.spinnerService.hide();

        a.click();
        URL.revokeObjectURL(a.href)
        a.remove();
      },
      () => {
        this.spinnerService.hide();
      });
  }  
}
