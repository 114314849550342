import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Audience } from 'src/app/models/services/audience';
import { AudiencesStatus } from 'src/app/models/services/audiencesStatus.enum';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';

@Component({
  selector: 'app-audience-selector-dialog',
  templateUrl: './audience-selector-dialog.component.html',
  styleUrls: ['./audience-selector-dialog.component.sass']
})
export class AudienceSelectorDialogComponent implements OnInit {

  audiences: Audience[];
  filteredAudiences: Audience[] = [];
  title: string = "Select an audience";
  selectedAudienceId: number;
  audiencesFilter: string;
  searchLaunched: boolean = false;
  showArchivedAudiences: boolean = false;
  
  constructor(
    private audiencesService: AudiencesService,    
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinnerService.show('dialogSpinner');
    this.audiencesService.getAudiences().subscribe(resp => {
      this.audiences = resp;
      this.spinnerService.hide('dialogSpinner');
    });
  }

  public accept: (audienceId: number) => void;
  public dismiss: () => void;

  onAccept = (): void  => {   
    this.accept(this.selectedAudienceId);
  }

  onDismiss = (): void => {
    this.dismiss();
  }

  onSearch = (searchBoxName: string, searchTerm: string): void => {
    this.audiencesFilter = searchTerm;
    this.searchLaunched = true;
    var filteredAudiences = 
      !!searchTerm ? 
      this.audiences.filter((x: Audience) => x.name.toUpperCase().indexOf(searchTerm.toUpperCase()) != -1) :
      this.audiences;

    this.filteredAudiences =         
      this.showArchivedAudiences ? 
      filteredAudiences :
      filteredAudiences.filter((x: Audience) => x.status != AudiencesStatus.Archived);
  }

  onShowArchivedAudiencesChange = (): void => {
    if (this.searchLaunched)
    this.onSearch('', this.audiencesFilter);
  }
}
