import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PublicisTableDataSource } from 'src/app/models/components/publicis-table';

@Component({
  selector: 'app-publicis-table',
  templateUrl: './publicis-table.component.html',
  styleUrls: ['./publicis-table.component.sass']
})
export class PublicisTableComponent implements OnInit {

  @Input() 
  dataSource: PublicisTableDataSource;

  data: Array<any>;
  currentSortFieldName: string;
  currentSortDefaultFieldValue: string;
  isCurrentSortAscending: boolean;
  currentPage: number = 1;
  rowsCount: number = 0;

  constructor() { }

  ngOnInit(): void {
    if (!this.dataSource.pageSize)
      this.dataSource.pageSize = 10;
  }

  ngOnChanges(changes: SimpleChanges) {        
    // If server side, doesn't raise changes, it will do for a second time
    if (!!this.dataSource.serverSideConfiguration) {
      this.data = this.dataSource.data;
      this.rowsCount = this.dataSource.serverSideConfiguration.totalCount;
      return;
    }
      
    this.rowsCount = this.dataSource.data.length;
    if(changes.dataSource.currentValue != changes.dataSource.previousValue || changes.currentPage.currentValue != changes.currentPage.previousValue) {
      this.setPage();
    }
  }

  setPage = (page?: number): void => {
    var targetPage = !!page ? page : this.currentPage;

    // Server side
    if (!!this.dataSource.serverSideConfiguration)
    {
      this.dataSource.serverSideConfiguration.getDataCallback(targetPage, this.currentSortFieldName, this.isCurrentSortAscending ? "ASC" : "DESC");
      return;
    }

    // Client side
    let allData: Array<any> = this.dataSource.data;
    if (!!this.currentSortFieldName) {
      allData = this.dataSource.data.sort((a, b): number => {
        var result: number;
        if (this.getSortingValue(a) < this.getSortingValue(b)) {
          result = -1;
        } else {
          if (this.getSortingValue(a) > this.getSortingValue(b)) {
            result =  1;
          } else {
            result = 0
          }
        }

        return this.isCurrentSortAscending ? result : -result;
      });
    } 
    
    if((targetPage - 1) * this.dataSource.pageSize > allData.length) {
      this.currentPage = 1;
      targetPage = 1;
    }      
    this.data = 
      this.dataSource.enablePagination ? 
      allData.slice((targetPage - 1) * this.dataSource.pageSize, targetPage * this.dataSource.pageSize) :
      allData;
  }

  sortData = (sortFieldName: string, sortDefaultFieldValue: string): void => {
    if (this.currentSortFieldName == sortFieldName) {
      this.isCurrentSortAscending = !this.isCurrentSortAscending;      
    } else {
      this.currentSortFieldName = sortFieldName;
      this.currentSortDefaultFieldValue = sortDefaultFieldValue;
      this.isCurrentSortAscending = true;
    }
    this.setPage();
  }

  showPagination = (): boolean => {
    return 
      !!this.dataSource.enablePagination &&
      (
        (!this.dataSource.serverSideConfiguration && this.dataSource.data?.length > this.dataSource.pageSize) ||
        (!!this.dataSource.serverSideConfiguration && this.dataSource.serverSideConfiguration.totalCount > this.dataSource.pageSize)
      );
  }

  private getSortingValue = (item: any): any => {
    if(this.currentSortFieldName.indexOf(".") < 0)
      return item[this.currentSortFieldName] != undefined ? 
        item[this.currentSortFieldName] : 
        ( !!this.currentSortDefaultFieldValue ? this.currentSortDefaultFieldValue : '');

    let fieldParts: string[] = this.currentSortFieldName.split(".");
    let result: any = item;
    let i: number = 0;
    while(i < fieldParts.length && !!result) {
      result = result[fieldParts[i]]
      i++;
    }

    if (result == undefined)
      result = !!this.currentSortDefaultFieldValue ? this.currentSortDefaultFieldValue : '';

    return result;
  }

}
