<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <eris-iab-master-tree [data]="treeData" (onSelectedNodeChange)="onSelectedNodeChange($event)">        
    </eris-iab-master-tree>    
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="accept(selectedItem)"
        [disabled]="!selectedItem">Accept</button>
    <button type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
</div>

