import { Injectable } from '@angular/core';
import { PredefinedReport } from '../models/services/predefinedReport';
import { environment } from 'src/environments/environment';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PredefinedReportsService {

  constructor(
    private httpService: HttpService
  ) { }

  public getAll(): Observable<PredefinedReport[]> {
    let url: string = `${environment.coreServicesApiBaseUri}/predefinedReports`;
    return this.httpService.get(url, undefined);
  }  
}
