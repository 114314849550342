<div style="padding-top: 10px; display: flex !important; justify-content: right !important;">
    <ngb-pagination (pageChange)="setPage($event)" [collectionSize]="rowsCount" [pageSize]="dataSource.pageSize" [(page)]="currentPage" 
                    [maxSize]="5" [boundaryLinks]="true">
    </ngb-pagination>
</div>
<table class="table table-striped">
    <thead>
        <tr>
            <th scope="col" [style]="!!headerStyle ? headerStyle : ''" *ngFor="let fieldDefinition of dataSource.tableDefinition">
                <ng-container *ngIf="fieldDefinition.enableSort"><a href="javascript:;" (click)="sortData(fieldDefinition.sortFieldName, fieldDefinition.sortDefaultFieldValue)">{{fieldDefinition.headerDescription}}</a></ng-container>
                <ng-container *ngIf="!fieldDefinition.enableSort">{{fieldDefinition.headerDescription}}</ng-container>
                <ng-container *ngIf="!!currentSortFieldName && fieldDefinition.sortFieldName == currentSortFieldName">
                    <i class="pe-7s-angle-up" *ngIf="isCurrentSortAscending" style="font-size: 1.5rem;vertical-align: middle;"></i>
                    <i class="pe-7s-angle-down" *ngIf="!isCurrentSortAscending" style="font-size: 1.5rem;vertical-align: middle;"></i>    
                </ng-container>
            </th>
        </tr>
    </thead>
    <tbody>
        <ng-content></ng-content>
    </tbody>
</table>
<!-- *ngIf="showPagination()" -->
<div  style="display: flex !important; justify-content: right !important;">
    <ngb-pagination (pageChange)="setPage($event)" [collectionSize]="rowsCount" [pageSize]="dataSource.pageSize" [(page)]="currentPage" 
                    [maxSize]="5" [boundaryLinks]="true">
    </ngb-pagination>
</div>