import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { InsightsService } from 'src/app/services/insights/insights.service';

@Component({
  selector: 'publicis-id-insights-profile-updates',
  templateUrl: './insights-profile-updates.component.html',
  styleUrls: ['./insights-profile-updates.component.sass']
})
export class InsightsProfileUpdatesComponent implements OnInit {

  public chartLabels: Label[] = [];
  public chartData: SingleDataSet = [];
  public chartLegend = false;
  public chartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {xAxes: [{}], yAxes: [{
      ticks: {
        beginAtZero: true,
        callback: function(value, index, values) {
          return value.toLocaleString();
        }
      }      
    }
    ]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: function(value, context) {
          return value.toLocaleString();
        }
      }
    },
    tooltips: {
      callbacks: {
            label: function(tooltipItem, data) {
                var value = data.datasets[0].data[tooltipItem.index];
                return value.toLocaleString();
            }
      }
    }
  };
  public chartType: ChartType = 'bar';

  constructor(private insightsService: InsightsService) { }

  ngOnInit(): void {
    this.insightsService.getProfileUpdates().subscribe(
      resp => {
        this.chartLabels = resp.labels;
        this.chartData = resp.dataSet;
      },
      () => {
      }
    )    
  }
  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
