import { Injectable } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponent } from '../../components/core/dialog/dialog.component';
import { DialogCustomButtonsComponent } from 'src/app/components/core/dialog-custom-buttons/dialog-custom-buttons.component';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class DialogCustomButtonsService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public showMessage = (title: string, message: string, buttonsTexts: string[], acceptCallback: (buttonText: string) => void, rejectCallback?: () => void, options: NgbModalOptions = null, allowLineBreaks: boolean = false, showRejectButton: boolean = true): void => {
    var ref = this.modalService.open(DialogCustomButtonsComponent, options);
    var buttonText: string;

    // Get the OkDialog from the NgbModal object  
    let dialog: DialogCustomButtonsComponent = ref.componentInstance as DialogCustomButtonsComponent;

    // Set the dialog's properties
    dialog.title = title;
    dialog.message = message;
    dialog.buttonsTexts = buttonsTexts;
    dialog.buttonRejectVisible = showRejectButton;
    dialog.allowLineBreaks = allowLineBreaks;

    dialog.close = (result) => {
      buttonText = result;
      ref.close(1);
    };

    dialog.dismiss = (result) => {
      ref.dismiss(result);
    };

    ref.result.then((result) => {
      this.lastErrorCloseResult = `Closed with: ${result}`;
    }, (reason) => {
      this.lastErrorCloseResult = `Dismissed ${this.getDismissReason(reason)}`;
    })
    
    ref.result.then((result) => {
      switch (result) {
        case 1:
          if (!!acceptCallback)
            acceptCallback(buttonText);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })    
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
