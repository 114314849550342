import { QueryBuilderConfig, Rule, RuleSet } from "angular2-query-builder";

export interface AudiencesBuilderConfiguration {
    taxonomies: TaxonomyGroup[];
    queryBuilderConfig: QueryBuilderConfig;
}

export interface TaxonomyRule {
    uniqueId: string;
    description: string;
    rule: RuleSet | Rule;
}

export interface Taxonomy {
    name: string;
    taxonomyRules: TaxonomyRule[]
}

export interface TaxonomyGroup {
    name: string;
    taxonomies: Taxonomy[];
    searchableTaxonomies: Taxonomy[];
    foundTaxonomies: Taxonomy[];
}

// V2
export interface TaxonomyRule {
    uniqueId: string;
    description: string;
    rule: RuleSet | Rule;
}

export enum TaxonomyItemValueTypes {
    String = 1,
    Number = 2,
    Affinity = 3,
    Date = 4,
    Audience = 5
}

export interface TaxonomyItem {
    name: string;
    isOnlyGroup: boolean;
    field?: string;
    value?: string;
    valueType?:  TaxonomyItemValueTypes;
    childs?: TaxonomyItem[];    
    profilesCount?: number; 
    identifiersCounts: IdentifierCount[];
}

export interface IdentifierCount {
    idGraphTypeId: number;
    identifierCount: number;
}

export interface AudiencesBuilderConfigurationV2 {
    taxonomy: TaxonomyItem[];
    queryBuilderConfig: QueryBuilderConfig;
}
