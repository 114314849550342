<div *ngIf="loadFinished && usersService.hasGlobalSectionRight('Admin')" style="position:relative;left:30px;width: 95%;">
    <div class="row">&nbsp;</div>
    <div class="row" style="text-align: right;">
        <div class="col-md-11"></div>
        <div class="col-md-1 text-right">
            <div *ngIf="isOptimizationEnabled" (click)="disableOptimization()" class="font-icon-wrapper bg-premium-dark btn" style="border: none;">
                <i class="fa fa-fw" aria-hidden="true" title="stop-circle" style="color: red;"></i>
                <p style="color: red;">Disable optimization</p>
            </div>         
            <div *ngIf="!isOptimizationEnabled" (click)="enableOptimization()" class="font-icon-wrapper bg-premium-dark btn" style="border: none;">
                <i class="fa fa-fw" aria-hidden="true" title="play-circle" style="color: green;"></i>
                <p style="color: green;">Enable optimization</p>
            </div>         
        </div>
    </div>
    <div class="row">&nbsp;</div>    
    <h3>Identities</h3>
    <div class="row">
        <div class="card mb-3 widget-content bg-premium-dark" [@.disabled]="true" style="margin:5px; width: 32.5%;" *ngFor="let queueProcessInfo of identitiesQueueProcessesInfo">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left" style="width: 100% !important">
                    <div class="widget-heading">{{queueProcessInfo.partnerDescription}}</div>
                    <div class="widget-heading">{{queueProcessInfo.queueName}}</div>
                    <div class="widget-subheading">{{getIdentititesProcessDescription(queueProcessInfo)}}</div>
                    <div class="widget-subheading" *ngIf="queueProcessInfo.approximateMessageCount > 0">Estimated time to finish: {{queueProcessInfo.estimatedTimeToFinish}}</div>
                    <label class="widget-heading"><input type="checkbox" [checked]="queueProcessInfo.enabled" (change)="queueProcessInfoEnabledChange(queueProcessInfo)"/>&nbsp;{{queueProcessInfo.enabled ? "Enabled" : "Disabled"}}</label>
                </div>
                <div class="widget-content-right" style="margin-left:10px; text-align: center;">
                    <div class="widget-numbers text-warning">
                        <span>{{queueProcessInfo.approximateMessageCount * queueProcessInfo.processMaxBatchSize | number:'':'es'}}</span>
                        <br>
                        <br>
                    </div>
                    <div class="font-icon-wrapper" style="border: none;" *ngIf="getProcessStatus(queueProcessInfo) == 2">
                        <i class="fa fa-fw" aria-hidden="true" title="stop-circle" style="color: red;"></i>
                        <p style="color: red;">Stopped</p>
                    </div> 
                    <div class="font-icon-wrapper" style="border: none;" *ngIf="getProcessStatus(queueProcessInfo) == 1">
                        <i class="fa fa-fw" aria-hidden="true" title="play-circle" style="color: green;"></i>
                        <p style="color: green;">Running</p>
                    </div>                          
                </div>
            </div>
        </div>    
    </div>
    <h3>Last 5 minutes progress</h3>
    <div class="row">
        <canvas baseChart width="400" height="100"
        [datasets]="identitiesChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins">
    </canvas>
    </div>
    <br/>
    <br/>
    <div>
        <h3>Identities / Attributes plain tables</h3>
        <div class="row">
            <div class="card mb-3 widget-content bg-premium-dark" style="margin:5px; width: 32.5%;" *ngFor="let queueProcessInfo of attributesQueueProcessesInfo">
                <div class="widget-content-wrapper text-white">
                    <div class="widget-content-left" style="width: 100% !important">
                        <div class="widget-heading">{{queueProcessInfo.partnerDescription}}</div>
                        <div class="widget-heading">{{queueProcessInfo.queueName}}</div>
                        <div class="widget-subheading">{{getAttributesProcessDescription(queueProcessInfo)}}</div>
                        <div class="widget-subheading" *ngIf="queueProcessInfo.approximateMessageCount > 0">Estimated time to finish: {{queueProcessInfo.estimatedTimeToFinish}}</div>
                        <label class="widget-heading"><input type="checkbox" [checked]="queueProcessInfo.enabled" (change)="queueProcessInfoEnabledChange(queueProcessInfo)"/>&nbsp;{{queueProcessInfo.enabled ? "Enabled" : "Disabled"}}</label>
                    </div>
                    <div class="widget-content-right" style="margin-left:10px; text-align: center;">
                        <div class="widget-numbers text-warning">
                            <span>{{queueProcessInfo.approximateMessageCount * queueProcessInfo.processMaxBatchSize | number:'':'es'}}</span>
                            <br>
                            <br>
                        </div>
                        <div class="font-icon-wrapper" style="border: none;" *ngIf="getProcessStatus(queueProcessInfo) == 2">
                            <i class="fa fa-fw" aria-hidden="true" title="stop-circle" style="color: red;"></i>
                            <p style="color: red;">Stopped</p>
                        </div> 
                        <div class="font-icon-wrapper" style="border: none;" *ngIf="getProcessStatus(queueProcessInfo) == 1">
                            <i class="fa fa-fw" aria-hidden="true" title="play-circle" style="color: green;"></i>
                            <p style="color: green;">Running</p>
                        </div>                        
                    </div>                    
                </div>
            </div>
        </div>
        <h3>Last 5 minutes progress</h3>
        <div class="row">
            <canvas baseChart width="400" height="100"
            [datasets]="attributesChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [plugins]="lineChartPlugins">
        </canvas>        
    </div>    
</div>