import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AudienceScheduling, Scheduling } from 'src/app/models/services/audience';

@Component({
  selector: 'app-audience-scheduling-parameters-dialog',
  templateUrl: './audience-scheduling-parameters-dialog.component.html',
  styleUrls: ['./audience-scheduling-parameters-dialog.component.sass']
})
export class AudienceSchedulingParametersDialogComponent implements OnInit {
  public accept: (schedulingId: number, endDate?: Date) => void;
  public dismiss: () => void;
  public currentAudienceSchedulingId?: number;
  public currentAudienceSchedulingDate?: Date;
  
  form: FormGroup;
  name: string;
  title: string = "Audience scheduling"; 
  audiencesSchedulings: Scheduling[] = [
    {
      id: 1,
      description: 'No scheduling'
    },
    {
      id: 2,
      description: 'Weekly'
    },
    {
      id: 3,
      description: 'Bi-weekly'
    },
    {
      id: 4,
      description: 'Monthly'
    },
    {
      id: 5,
      description: 'Each two days'
    }        
  ];

  constructor(
    private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group(
      {
        schedulingId: ['', Validators.required],
        schedulingEndDate: ['']
      });
  }

  ngOnInit(): void {
    if (!!this.currentAudienceSchedulingId) {
      let audienceScheduling: AudienceScheduling = this.audiencesSchedulings.find(x => x.id == this.currentAudienceSchedulingId);
      if (!!audienceScheduling) {
        this.form.controls.schedulingId.setValue(audienceScheduling.id);
        this.form.controls.schedulingEndDate.setValue(this.currentAudienceSchedulingDate);
      }      
    }
  }

  onAccept = (): void => {
    let date: Date = this.form.controls.schedulingEndDate.value;
    let schedulingEndDateUTC: Date;
    if (!!date) {
      if(!!(date as Date))
        date = new Date(date);      
      let year = date.getFullYear();
      let month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  
      schedulingEndDateUTC = new Date(`${year}-${month}-${day}T00:00:00Z`)  
    } 

    this.accept(this.form.controls.schedulingId.value, schedulingEndDateUTC);
  };

  onDismiss = (): void => {
    this.dismiss();
  }

  filterValidDates = (d: Date): boolean => {
    if (!!!d)
      return false;

    return d > new Date();
  }  
}