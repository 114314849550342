import { Component, OnInit } from '@angular/core';
import { Rule } from 'angular2-query-builder';
import { FormControl } from '@angular/forms';
import { TaxonomyItem } from 'src/app/models/services/taxonomiesConfig';

@Component({
  selector: 'app-attribute-value-date-selector-dialog',
  templateUrl: './attribute-value-date-selector-dialog.component.html',
  styleUrls: ['./attribute-value-date-selector-dialog.component.sass']
})
export class AttributeValueDateSelectorDialogComponent implements OnInit {
  public taxonomyItem: TaxonomyItem;
  public accept: (rule: Rule) => void;
  public dismiss: () => void;
    
  title: string = "Select attribute affinity"; 
  operators: string[] = ['=', '!=', '>', '>=', '<', '<='];
  selectedOperator: string = '>';
  dateFormCtrl: FormControl = new FormControl(new Date());

  constructor() {
  }

  ngOnInit(): void {    
  }

  onAccept = (): void => {
    let date: Date = this.dateFormCtrl.value;
    this.accept({
      field: this.taxonomyItem.field,
      operator: this.selectedOperator,
      value: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` 
    });
  };

  onDismiss = (): void => {
    this.dismiss();
  }

  acceptButtonEnabled = (): boolean => {
    return !!this.selectedOperator && !!this.dateFormCtrl.value;
  }

  onSelectOperator = (operator: string): void => {
    this.selectedOperator = operator;
  }

}


