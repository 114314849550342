import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanySelectorService } from 'src/app/services/dialogs/company-selector.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { ImpersonationService } from 'src/app/services/impersonation.service';
import { LoginService } from 'src/app/services/login.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from '../../../../../theme-options';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  constructor(
    public loginService: LoginService,
    public usersService: UsersService,
    public impersonationService: ImpersonationService,
    public globalsService: GlobalsService,
    public companySelectorService: CompanySelectorService,
    public globals: ThemeOptions,
    public router: Router) {
  }

  ngOnInit() {
  }

  navigateToPortalSection = (): void => {
    this.router.navigate(["/"]);
  }

  navigateToAdminSection = (): void => {
    this.router.navigate(['/admin/data-transfers', { }]);
  }

}
