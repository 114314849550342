import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AudienceInfoDialogComponent } from 'src/app/components/dialogs/audiences/audience-info-dialog/audience-info-dialog.component';
import { DataTransferInfoDialogComponent } from 'src/app/components/dialogs/admin/data-transfer-info-dialog/data-transfer-info-dialog.component';
import { Audience } from 'src/app/models/services/audience';
import { DataTransfer } from 'src/app/models/services/dataTransferInfo';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class AudienceInfoDialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public show = (audience: Audience): void => {    
    var ref = this.modalService.open(AudienceInfoDialogComponent, { size: 'xl' });

    // Get the OkDialog from the NgbModal object  
    let dialog: AudienceInfoDialogComponent = ref.componentInstance as AudienceInfoDialogComponent;

    dialog.audience = audience;

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

  }
}
