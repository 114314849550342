import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-no-rights',
  templateUrl: './no-rights.component.html',
  styleUrls: ['./no-rights.component.sass']
})
export class NoRightsComponent implements OnInit {

  constructor(public userService: LoginService) { }

  ngOnInit(): void {
  }

}
