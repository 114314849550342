<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
<div class="row">&nbsp;</div>
<div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-2" *ngIf="usersService.hasSelectedCompanySectionRight('Segments')">
        <app-page-title heading="Segments" subheading="Upload and management" icon="pe-7s-copy-file" class="btn"
            (click)="navigateTo('/segments/file-upload')">
        </app-page-title>
    </div>
    <div class="col-md-2"></div>
    <div class="col-md-2" *ngIf="usersService.hasSelectedCompanySectionRight('Audiences')">
        <app-page-title heading="Audiences" subheading="Create and activate" icon="pe-7s-radio" class="btn"
            (click)="navigateTo('/audiences/builder')"></app-page-title>
    </div>
    <div class="col-md-2"></div>
    <div class="col-md-2" *ngIf="usersService.hasSelectedCompanySectionRight('Insights')">
        <app-page-title heading="Insights" subheading="Explore and discover" icon="pe-7s-graph2" class="btn"
            (click)="navigateTo('/insights/general')"></app-page-title>
    </div>
    <div class="col-md-1"></div>
</div>