import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
})
export class SearchBoxComponent implements OnInit {
  @Input()
  public searchBoxName: string;

  @Input()
  public search: (searchBoxName: string, searchTerm: string) => void;

  @Input()
  public change: (searchBoxName: string, searchTerm: string) => void;

  public isActive: any;
  searchTerm: string = '';

  constructor() { }

  ngOnInit() {
  }

  onClickSearch = (): void => {
    if (this.isActive)
      this.search(this.searchBoxName, this.searchTerm);
    else
      this.isActive = true;
  }

  onClickClose = (): void => {
    this.searchTerm = '';
    this.search(this.searchBoxName, this.searchTerm);
    this.isActive = false;
  }
}
