import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IdGraphTypes } from 'src/app/models/services/idGraphTypes.enum';
import { MatchingParameters } from 'src/app/models/services/matchingParameters';
import { SegmentFile } from 'src/app/models/services/segmentFile';

@Component({
  selector: 'app-matching-parameters-dialog',
  templateUrl: './matching-parameters-dialog.component.html',
  styleUrls: ['./matching-parameters-dialog.component.sass']
})
export class MatchingParametersDialogComponent implements OnInit {

  public accept: (data: MatchingParameters) => void;
  public dismiss: () => void;

  formStep1: FormGroup;
  formStep2: FormGroup;
  name: string;
  title: string = "Send segment to match"; 
  currentStep: number = 1;
  segmentFile: SegmentFile;
  sampleDataRows: any = {
    header: [],
    data: [[]]
  }
  idGraphTypes: any = IdGraphTypes;
  idGraphTypesKeys: string[] = Object.keys(IdGraphTypes);

  constructor(private formBuilder: FormBuilder) {
    this.formStep1 = this.formBuilder.group(
      {
        hasHeader: [true, Validators.required],
        fieldSeparator: ['']
      });
    this.formStep2 = this.formBuilder.group(
      {
        segmentName: ['', Validators.required],
        identifierColumnIndex: [1, Validators.required],        
        idGraphTypeId: [IdGraphTypes.HashedEmail, Validators.required]
      });
  }

  ngOnInit(): void {
  }

  onAccept = (): void => {
    this.accept({
      segmentFileId: this.segmentFile.id,
      hasHeader: this.formStep1.controls.hasHeader.value,
      fieldSeparator: this.formStep1.controls.fieldSeparator.value,
      name: this.formStep2.controls.segmentName.value,
      identifierColumnIndex: this.formStep2.controls.identifierColumnIndex.value,
      idGraphTypeId: +this.formStep2.controls.idGraphTypeId.value
    });
  };

  onDismiss = (): void => {
    this.dismiss();
  }

  onChangeHashHeader = (): void => {
    this.refreshDataPreview();
  }

  onChangeFieldSeparator = (): void => {
    this.refreshDataPreview();
  }  
  
  refreshDataPreview = (): void => {
    var hasHeader = this.formStep1.controls.hasHeader.value;
    var fieldSeparator = this.formStep1.controls.fieldSeparator.value;
    if (fieldSeparator == '') {
      if (hasHeader) {
        this.sampleDataRows.header = [ this.segmentFile.firstRows[0] ];
        this.sampleDataRows.data = this.segmentFile.firstRows.slice(1).map((item) => { return [ item ]; });
      } else {      
        this.sampleDataRows.header = [ 'Column 1' ];
        this.sampleDataRows.data = this.segmentFile.firstRows.map((item) => { return [ item ]; });
      } 
    } else {
      var splittedRows = this.segmentFile.firstRows.map((item) => { return item.split(fieldSeparator); });
      if (hasHeader) {
        this.sampleDataRows.header = splittedRows[0];
        this.sampleDataRows.data = splittedRows.slice(1);
      } else {      
        this.sampleDataRows.header = splittedRows[0].map(((item, index) => { return `Column ${index + 1}`; }));
        this.sampleDataRows.data = splittedRows;
      } 
    }
  }

}
