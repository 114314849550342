import { Component, OnInit } from '@angular/core';
import { Audience } from 'src/app/models/services/audience';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';
import { timer, Subscription } from 'rxjs';
import { PublicisTableDataSource } from 'src/app/models/components/publicis-table';
import { UsersService } from 'src/app/services/users.service';
import { AudienceSchedulingParametersDialogService } from 'src/app/services/dialogs/audience-scheduling-parameters-dialog.service';
import { DialogService } from 'src/app/services/core/dialog.service';
import { BaseTenantRequiredPageComponent } from 'src/app/components/core/base-tenant-required-page/base-tenant-required-page.component';
import { Router } from '@angular/router';
import { GlobalsService } from 'src/app/services/globals.service';
import { AudiencesStatus } from 'src/app/models/services/audiencesStatus.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { AudiencesListFilterDialogService } from 'src/app/services/dialogs/audiences-list-filter-dialog.service';
import { AudiencesListFilter } from 'src/app/models/ui/audiencesListFilter';
import { Tag } from 'src/app/models/services/tag';

@Component({
  selector: 'app-audiences-scheduler',
  templateUrl: './audiences-scheduler.component.html',
  styleUrls: ['./audiences-scheduler.component.sass']
})
export class AudiencesSchedulerComponent extends BaseTenantRequiredPageComponent implements OnInit {
  public heading: string = 'Audiences list';
  public subheading: string = '';
  public icon: string = 'pe-7s-radio icon-gradient bg-midnight-bloom';

  refreshTimerSubscription: Subscription;
  private activePolling: boolean = false;
  public tags: Tag[];
  private originalAudiences: Audience[];
  public audiencesDataSource: PublicisTableDataSource = {
    tableDefinition: [
      {
        headerDescription: 'ID',
        enableSort: true,
        sortFieldName: 'id'
      }, {
        headerDescription: 'Category',
        enableSort: true,
        sortFieldName: 'category.name'
      },{
        headerDescription: 'Audience',
        enableSort: true,
        sortFieldName: 'name'
      }, {
        headerDescription: 'Created',
        enableSort: true,
        sortFieldName: 'created'      
      }, {
        headerDescription: 'Refreshed',
        enableSort: true,
        sortFieldName: 'updated'      
      }, {
        headerDescription: 'End date',
        enableSort: true,
        sortFieldName: 'audienceScheduling.endDate'      
      }, {        
        headerDescription: 'Scheduling',
        enableSort: true,
        sortFieldName: 'audienceScheduling.scheduling.description',
        sortDefaultFieldValue: 'Non scheduled'
      }, {
        headerDescription: '',
        enableSort: false      
      }      
    ],
    data: [],
    enableSort: true,
    enablePagination: true,
    pageSize: 10
  };

  public audiencesFilter: AudiencesListFilter = {
    textSearch: undefined,
    tagsSearch: []
  };

  constructor(
    public usersService: UsersService,
    private dialogService: DialogService,
    private audiencesService: AudiencesService,
    private audienceSchedulingParametersDialogService: AudienceSchedulingParametersDialogService,
    private audiencesListFilterDialogService: AudiencesListFilterDialogService,
    private spinnerService: NgxSpinnerService,
    globalsService: GlobalsService,
    router: Router) {
      super(
        usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesScheduler'),
        globalsService, 
        router);
            
     }

  ngOnInit(): void {
    if(this.redirecting)
      return;

    this.activePolling = true;
    this.loadAudiences();
  }

  ngOnDestroy(): void {
    this.stopRefreshPolling();
  }

  private loadAudiences = (): void => {
    if(!this.originalAudiences)
      this.spinnerService.show();

    this.audiencesService.getAudiences().subscribe(
      resp => {
        this.originalAudiences = resp.filter(x => x.status != AudiencesStatus.Archived);
        this.filterAudiences();
        
        console.log("Audiences load success")
    
        this.startRefreshPolling();

        this.spinnerService.hide();
      }
    ), () => {
      console.log("Audiences load error")

      this.startRefreshPolling();
      this.spinnerService.hide();
    }

    this.audiencesService.getAllTags().subscribe(x => this.tags = x);
  }

  scheduleAudienceRefresh = (audience: Audience): void => {
    this.stopRefreshPolling();
    this.audienceSchedulingParametersDialogService.show(
      (schedulingId: number, endDate?: Date) => {
        this.spinnerService.show();
        this.audiencesService.updateScheduling(audience.id, schedulingId, endDate).subscribe(
          () => {
            this.dialogService.showMessage("Update audience", "Audience updated");
            this.loadAudiences();            
          });
      }, () => {
        this.startRefreshPolling();
        this.spinnerService.hide();
      }, 
      audience.audienceScheduling?.schedulingId, 
      audience.audienceScheduling?.endDate
    );
  }

  showFilterDialog =  () : void => {
    this.audiencesListFilterDialogService.show(this.tags, this.audiencesFilter, () => {   
      if (!!this.audiencesDataSource.serverSideConfiguration) {
        this.loadAudiences();
      }
      else {
        this.filterAudiences();
      }
    }, () =>{} );  
  }

  isFilterApplied =  () : boolean => {
    return !!this.audiencesFilter.textSearch || this.audiencesFilter.tagsSearch.length > 0;  
  }

  /*
  filterAudiences = (searchTerm: string): void => {
    this.audiencesFilter = searchTerm;

    // We need to build the full object in order to make the publicis-table component detect changes
    let cloned: PublicisTableDataSource = { ...this.audiencesDataSource};
    cloned.data =         
      !!searchTerm ? 
      this.originalAudiences.filter((x: Audience) => { 
        return (
          x.category.name.toUpperCase().indexOf(searchTerm.toUpperCase()) != -1 ||
          x.name.toUpperCase().indexOf(searchTerm.toUpperCase()) != -1 ||
          (
            !!x.audienceScheduling?.scheduling?.description ?
              x.audienceScheduling?.scheduling.description.toUpperCase().indexOf(searchTerm.toUpperCase()) != -1 :
              "NON SCHEDULED".indexOf(searchTerm.toUpperCase()) != -1
          )
        ); 
      } ) :
      this.originalAudiences;
    this.audiencesDataSource = cloned;
  }
  */

  filterAudiences = (): void => {
    // We need to build the full object in order to make the publicis-table component detect changes
    let cloned: PublicisTableDataSource = { ...this.audiencesDataSource};
    cloned.data =         
      !!this.audiencesFilter.textSearch ? 
      this.originalAudiences
        .filter(this.isAudienceInTagsFilter)
        .filter((x: Audience) => { 
          return (
            x.category.name.toUpperCase().indexOf(this.audiencesFilter.textSearch.toUpperCase()) != -1 ||
            x.name.toUpperCase().indexOf(this.audiencesFilter.textSearch.toUpperCase()) != -1 ||
            x.id.toString().indexOf(this.audiencesFilter.textSearch.toUpperCase()) != -1 ||
            (
              !!x.audienceScheduling?.scheduling?.description ?
                x.audienceScheduling?.scheduling.description.toUpperCase().indexOf(this.audiencesFilter.textSearch.toUpperCase()) != -1 :
                "NON SCHEDULED".indexOf(this.audiencesFilter.textSearch.toUpperCase()) != -1
            )
          ); 
        } ) :
      this.originalAudiences.filter(this.isAudienceInTagsFilter);
    this.audiencesDataSource = cloned;
  }  

  private isAudienceInTagsFilter = (audience: Audience): boolean => {
    if(this.audiencesFilter.tagsSearch.length == 0)
      return true;

    if(!audience.tags)
      return false;

    let result: boolean = false;
    for(const tag of audience.tags) {
      if(this.audiencesFilter.tagsSearch.findIndex(x => x.id == tag.id) >= 0) {
        result = true;
        break;
      }
    };

    return result;
  }

  stopRefreshPolling = (): void => {    
    if (!!this.refreshTimerSubscription)
      this.refreshTimerSubscription.unsubscribe();
    
    this.activePolling = false;

    console.debug("stopRefreshPolling executed")
  }

  startRefreshPolling = () : void => {
    this.stopRefreshPolling();
    this.activePolling = true;
    this.refreshTimerSubscription = timer(2000).subscribe(() => this.loadAudiences());

    console.debug("startRefreshPolling executed")
  }    
}
