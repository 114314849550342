<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon">
</app-page-title>
<div class="card">
    <div class="card-body row">
        <div class="col-lg-6 text-left">
            <!--<app-search-box></app-search-box>-->
        </div>
        <div class="col-lg-6 text-right">
            <a class="vsm-link">
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="downloadLookAlikeParametersCsv()">
                    Download parameters CSV
                </button>
            </a>            
            &nbsp;&nbsp;&nbsp;
            <a [routerLink]="[navigationUrlBase, sourceIdentifier, 'look-alikes', 'new' ]" routerLinkActive="active-item"
                [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success">
                    New look alike
                </button>
            </a>
        </div>
    </div>
</div>
<table class="table table-striped">
    <thead>
        <tr>
            <th scope="col" style="width: 10%;">#</th>
            <th scope="col" style="width: 20%;">Name</th>
            <th scope="col" style="width: 40%;">Description</th>
            <th scope="col" style="width: 10%;"></th>
            <th scope="col" style="width: 20%;"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let lookAlike of lookAlikes; index as i">
            <th scope="row" class="align-middle">{{ i + 1 }}</th>
            <td class="align-middle">{{ lookAlike.name}}</td>
            <td class="align-middle">{{ lookAlike.description }}</td>
            <td class="align-middle">
                <div>
                    <div class="font-icon-wrapper">
                        <i [class]="getStatusStyle(i)"></i>
                    </div>
                    <div class="d-flex justify-content-center">
                        {{ getStatusLabel(i) }}
                    </div>
                </div>
            </td>
            <td class="align-middle">
                <div class="row">                    
                    <div class="btn" title="Create audience">
                        <div class="font-icon-wrapper" (click)="createAudience(i)">
                            <i class="pe-7s-radio"></i>
                        </div>
                    </div>                    
                    <div class="btn" title="Delete look alike">
                        <div class="font-icon-wrapper" (click)="deleteLookAlike(i)">
                            <i class="pe-7s-trash"></i>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>