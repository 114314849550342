<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div>
        <label>File data preview</label>
        <table class="mb-0 table table-bordered">
            <thead>
                <tr>
                    <th *ngFor="let columnName of sampleDataRows.header; index as i" scope="col" [style]="(currentStep == 2 && i == formStep2.controls.identifierColumnIndex.value - 1) ? 'background-color:rgb(209, 243, 255)' : ''">{{columnName}}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="ng-star-inserted" *ngFor="let row of sampleDataRows.data">                
                    <td *ngFor="let field of row; index as i" [style]="(currentStep == 2 && i == formStep2.controls.identifierColumnIndex.value - 1) ? 'background-color:rgb(209, 243, 255); word-break: break-all;' : 'word-break: break-all;'">{{field}}</td>
                </tr>    
            </tbody>
        </table>
    </div>
    <br/>
    <br/>
    <form [formGroup]="formStep1" *ngIf="currentStep == 1">    
        <label>Select the segment file structure parameters</label>
        <div class="position-relative form-check">   
            <label class="form-check-label">
                <input type="checkbox" class="form-check-input" name="hasHeader" formControlName="hasHeader" (change)="onChangeHashHeader()">Has header
            </label>          
        </div>           
        <div class="position-relative form-group">            
            <label for="fieldSeparator">Field separator</label>
            <input type="text" class="form-control"name="fieldSeparator" formControlName="fieldSeparator" (input)="onChangeFieldSeparator()">
        </div>            
    </form>
    <form [formGroup]="formStep2" *ngIf="currentStep == 2">
        <label>Select the matching parameters</label>
        <div class="form-group">            
            <label for="identifierColumnIndex">Identifier column index</label>
            <input type="number" class="form-control" name="identifierColumnIndex" formControlName="identifierColumnIndex" min="1" [max]="sampleDataRows.header.length">
        </div>       
        <div class="form-group">            
            <label for="idGraphTypeId">Id Graph identifier type</label>          
            <select  class="form-control" name="idGraphTypeId" formControlName="idGraphTypeId"> 
                <option *ngFor="let idGraphTypeKey of idGraphTypesKeys" [value]="idGraphTypes[idGraphTypeKey]">{{idGraphTypeKey}}</option> 
            </select>
        </div>                   
        <div class="form-group">            
            <label for="segmentName">Segment name</label>          
            <input type="text" class="form-control" name="segmentName" formControlName="segmentName">
        </div>                   
    </form>
</div>

<div class="modal-footer">
    <button *ngIf="currentStep == 1" type="button" class="btn btn-secondary" (click)="currentStep = 2;"
        [disabled]="formStep1.invalid">Next step</button>
    <button *ngIf="currentStep == 2" type="button" class="btn btn-secondary" (click)="currentStep = 1;">Previous step</button>
    <button *ngIf="currentStep == 2" type="button" class="btn btn-secondary" (click)="onAccept()"
        [disabled]="formStep2.invalid">Accept</button>
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">Cancel</button>
</div>