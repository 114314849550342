import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TableData } from 'src/app/models/services/core/table-data';
import { DialogService } from 'src/app/services/core/dialog.service';
import { NielsenDataService } from 'src/app/services/insights/nielsen-data.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Taxonomy, TaxonomyAggregation, TaxonomyLevel, TaxonomyMarket, TaxonomyPeriod, TaxonomyProduct } from 'src/app/models/services/insights/nielsen-data/taxonomy';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErisTreeNode } from 'src/app/models/components/eris-tree-node';
import { SelectionModel } from '@angular/cdk/collections';
import { Query } from 'src/app/models/services/insights/nielsen-data/query';
import { TableDataPreview } from 'src/app/models/services/core/table-data-preview';

@Component({
  selector: 'app-nielsen-data',
  templateUrl: './nielsen-data.component.html',
  styleUrls: ['./nielsen-data.component.sass']
})
export class NielsenDataComponent implements OnInit {
  @ViewChild('matAutocompleteLevels') inputAutocompleteLevels: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocompleteFromPeriods') inputAutocompleteFromPeriods: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocompleteToPeriods') inputAutocompleteToPeriods: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocompleteMarkets') inputAutocompleteMarkets: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocompleteAggregations') inputAutocompleteAggregations: ElementRef<HTMLInputElement>;

  public heading: string = 'Nielsen data';
  public subheading: string = '';
  public icon: string = 'pe-7s-graph2 icon-gradient bg-midnight-bloom';

  public tableDataPreview: TableDataPreview;
  public tableData: TableData;
  public taxonomy: Taxonomy;
  public formQuery: FormGroup;
  public filteredLevels: TaxonomyLevel[];
  public filteredPeriods: TaxonomyPeriod[];
  public filteredMarkets: TaxonomyMarket[];
  public filteredAggregations: TaxonomyAggregation[];
  public productsTreeData: ErisTreeNode[];  
  public productsSelectionModel: SelectionModel<ErisTreeNode>;
  public marketsSelectionModel: SelectionModel<TaxonomyMarket>;
  public aggregationsSelectionModel: SelectionModel<TaxonomyAggregation>;
  public currentStep: number = 1;

  constructor(
    private nielsenDataService: NielsenDataService,
    private datePipe: DatePipe,    
    private decimalPipe: DecimalPipe,    
    private dialogService: DialogService,
    private spinnerService: NgxSpinnerService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.spinnerService.show();
    this.nielsenDataService.getTaxonomy().subscribe(resp => {
      this.taxonomy = resp;
      this.productsTreeData = resp.products;      
      this.spinnerService.hide();
    });
    this.formQuery = this.formBuilder.group(
      {
        levelId: [ , Validators.required],
        fromPeriodId: [],
        toPeriodId: []
      });    
    this.productsSelectionModel = new SelectionModel<ErisTreeNode>(true);
    this.marketsSelectionModel = new SelectionModel<TaxonomyMarket>(true);    
    this.aggregationsSelectionModel = new SelectionModel<TaxonomyAggregation>(true);    
  }

  getDataPreview = (): void => {
    this.spinnerService.show();
    this.clearResults();
    this.nielsenDataService.getDataPreview(this.getCurrentQuery()).subscribe(resp => {
      this.tableDataPreview = resp;
      this.dialogService.showMessage("Nielsen data", "Data loaded");
      this.spinnerService.hide();
    }, () => {
      this.spinnerService.hide();
    });
  }

  getData = (actionCallback: () => void): void => {
    this.spinnerService.show();
    if(!this.tableData) {
      this.nielsenDataService.getData(this.getCurrentQuery()).subscribe(resp => {
        this.tableData = resp;
        if (!actionCallback)
          this.dialogService.showMessage("Nielsen data", "Data loaded");      
        else
          actionCallback();
        this.spinnerService.hide();
      }, () => {
        this.spinnerService.hide();
      });        
    } else {
      if (!!actionCallback)
        actionCallback();
      this.spinnerService.hide();
    }
  }

  getCurrentQuery = (): Query => {
    return {
      groupLevelId: this.formQuery.controls.levelId.value,
      aggregations: this.aggregationsSelectionModel.selected,
      fromPeriod: this.getPeriod(this.formQuery.controls.fromPeriodId.value),
      toPeriod: this.getPeriod(this.formQuery.controls.toPeriodId.value),
      products: this.productsSelectionModel.selected.map(x => this.getTaxonomyProduct(x)),
      markets: this.marketsSelectionModel.selected
    }
  }

  pasteToClipboard = (): void => {
    navigator.clipboard.writeText(this.getExcelData());
    this.dialogService.showMessage("Nielsen data", "Excel data copied to clipboard");
  }

  downloadTsv = (): void => {
    let filename: string = "Nielsen data.tsv";
    const blob = new Blob([this.getExcelData()], {type: 'text/tab-separated-values'});
    if((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveBlob(blob, filename);
    }
    else{
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;        
        document.body.appendChild(elem);
        elem.click();        
        document.body.removeChild(elem);
    }
  }
    
  getExcelData = (): string => {
    let headers = this.tableData.columns.map(x => x.header);
    let excelData: string = headers.join("\t") + "\n";
    for(let i:number = 0; i < this.tableData.columns[0].data.length; i++) {
      for (let j:number = 0; j < headers.length; j++) {
        excelData += this.formatData(this.tableData.columns[j].data[i], this.tableData.columns[j].type);        
        if(j < headers.length - 1)
          excelData += "\t";
      }
      excelData += "\n";
    }      

    return excelData;
  }

  formatData = (value: any, type: string): string => {
    switch (type.toLowerCase()) {
      case "float" :
      case "int" :
      case "bigint" :
            return value == null ? '' : this.decimalPipe.transform(value, '', 'es');

      case "datetime" :
        return value == null ? '' : this.datePipe.transform(value, 'yyyy-MM-dd hh:mm:ss');       
        
      default:
        return value == null ? '' : value.toString();
    }
  }

  getTaxonomyProduct = (node: ErisTreeNode): TaxonomyProduct => {
    let result: TaxonomyProduct;

    var i = 0;
    while(!result && i < this.taxonomy.products.length) {
      result = this.getTaxonomyProductInTreeNode(node.id, this.taxonomy.products[i]);
      i++;
    }

    return result;
  }

  getTaxonomyProductInTreeNode = (id: string, node: TaxonomyProduct): TaxonomyProduct => {
    var result;

    if(!node || !node.id)
      return undefined;

    if(node.id == id)
      return node;

    // Inner search
    var i = 0;
    while(!result && i < node.children?.length) {
      result = this.getTaxonomyProductInTreeNode(id, node.children[i]);
      i++;
    }

    return result;
  }
  
  getComposedName = (node: ErisTreeNode): string => {
    return this.getTaxonomyProduct(node)?.composedName;
  }

  getLevelDescription = (levelId?: number): string => {
    return this.taxonomy.levels.find(x => x.id == levelId)?.name;
  }

  filterLevels = (): void => {
    const filterValue = this.inputAutocompleteLevels.nativeElement.value.toLowerCase();
    this.filteredLevels = this.taxonomy.levels.filter(x => x.name.toLowerCase().includes(filterValue));
  }  

  resetLevelsFilter = (): void => {
    this.inputAutocompleteLevels.nativeElement.value = '';
    this.filteredLevels = this.taxonomy.levels;
  }

  getMarketDescription = (marketId?: string): string => {
    return this.taxonomy.markets.find(x => x.id == marketId)?.description;
  }

  filterMarkets = (): void => {
    const filterValue = this.inputAutocompleteMarkets.nativeElement.value.toLowerCase();
    this.filteredMarkets = this.taxonomy.markets.filter(x => x.description.toLowerCase().includes(filterValue));
  }  

  resetMarketsFilter = (): void => {
    this.inputAutocompleteMarkets.nativeElement.value = '';
    this.filteredMarkets = this.taxonomy.markets;
  }

  getAggregationDescription = (aggregationId?: string): string => {
    return this.taxonomy.aggregations.find(x => x.id == aggregationId)?.description;
  }

  filterAggregations = (): void => {
    const filterValue = this.inputAutocompleteAggregations.nativeElement.value.toLowerCase();
    this.filteredAggregations = this.taxonomy.aggregations.filter(x => x.description.toLowerCase().includes(filterValue));
  }  

  resetAggregationsFilter = (): void => {
    this.inputAutocompleteAggregations.nativeElement.value = '';
    this.filteredAggregations = this.taxonomy.aggregations;
  }

  getPeriodDescription = (periodId?: string): string => {
    return this.taxonomy.periods.find(x => x.id == periodId)?.description;
  }

  getPeriod = (periodId?: string): TaxonomyPeriod => {
    return this.taxonomy.periods.find(x => x.id == periodId);
  }

  filterFromPeriods = (): void => {
    const filterValue = this.inputAutocompleteFromPeriods.nativeElement.value.toLowerCase();
    this.filteredPeriods = this.taxonomy.periods.filter(x => x.description.toLowerCase().includes(filterValue));
  }  

  filterToPeriods = (): void => {
    const filterValue = this.inputAutocompleteToPeriods.nativeElement.value.toLowerCase();
    this.filteredPeriods = this.taxonomy.periods.filter(x => x.description.toLowerCase().includes(filterValue));
  }  

  resetFromPeriodsFilter = (): void => {
    this.inputAutocompleteFromPeriods.nativeElement.value = '';
    this.filteredPeriods = this.taxonomy.periods;
  }

  resetToPeriodsFilter = (): void => {
    this.inputAutocompleteToPeriods.nativeElement.value = '';
    this.filteredPeriods = this.taxonomy.periods;
  }

  toggleMarketSelection = (market: TaxonomyMarket, $event: any) => {
    this.marketsSelectionModel.toggle(market); 
  }

  toggleAggregationSelection = (aggregation: TaxonomyAggregation, $event: any) => {
    this.aggregationsSelectionModel.toggle(aggregation); 
  }

  showPreviousButton = (): boolean => {
    return this.currentStep != 1;
  }

  disabledNavigationButtons = (): boolean => {
    switch(this.currentStep) {
      case 1:
        return !!this.formQuery.controls.levelId.errors || !!this.formQuery.controls.fromPeriodId.errors || !!this.formQuery.controls.toPeriodId.errors;

      case 2:
        return false;

      case 3:
        return false;
        
      case 4:
        return false;        

      default:
        return true;
    }
  }

  previousStep = (): void => {
    if(this.currentStep > 1) {
      this.currentStep--;
      this.clearResults();
    }
  }

  showNextButton = (): boolean => {
    return this.currentStep != 4;
  }

  nextStep = (): void => {
    if(this.currentStep < 4)
      this.currentStep++;
  }

  showGetDataButton = (): boolean => {
    return this.currentStep == 4;
  }

  getQueryDescription = (): string => {
    let description: string;

    if (this.aggregationsSelectionModel.selected.length > 0)
      description = "\"" + this.aggregationsSelectionModel.selected.map(x => x.description).join("\", \"") + "\" ";
    else
      description = "All aggregation fields ";

    description += "between \"" + this.getPeriodDescription(this.formQuery.controls.fromPeriodId.value) + "\" and "   
    description += "\"" + this.getPeriodDescription(this.formQuery.controls.toPeriodId.value) + "\", "   
      
    description += "aggregated by \"" + this.getLevelDescription(this.formQuery.controls.levelId.value) + "\", "

    description += "filtered by \"" +  this.productsSelectionModel.selected.filter(x => x.level == this.formQuery.controls.levelId.value).map(x => x.name).join("\", \"") + "\"";

    if(this.marketsSelectionModel.selected.length > 0)
      description += " for markets \"" +  this.marketsSelectionModel.selected.map(x => x.description).join("\", \"") + "\"";
    
    return description;
  }

  clearResults = (): void => {
    this.tableDataPreview = null;
    this.tableData = null;
  }
}
