import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { ThemeOptions } from '../../../theme-options';

@Component({
  selector: 'app-non-logged',
  templateUrl: './non-logged.component.html',
  styleUrls: ['./non-logged.component.sass']
})
export class NonLoggedComponent implements OnInit {

  constructor(
    public globals: ThemeOptions,
    public userService: LoginService) { }

  ngOnInit(): void {
  }

}
