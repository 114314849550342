import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from 'src/app/models/services/user';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';
import { forkJoin } from 'rxjs';
import { Company } from 'src/app/models/services/company';
import { RightsService } from 'src/app/services/rights.service';
import { CompanyUserRight, UserRight } from 'src/app/models/services/right';
import { AdB2cUserSelectorDialogService } from 'src/app/services/dialogs/ad-b2c-user-selector-dialog.service';
import { DialogService } from 'src/app/services/core/dialog.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserSelectorDialogService } from 'src/app/services/dialogs/user-selector-dialog.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.sass']
})

export class AdminUsersComponent implements OnInit, OnDestroy {

  
  // Flatten definition
  private flatten = (x) => x.reduce((a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []);
  
  heading: string = 'Users rights';
  subheading: string = '';
  icon: string = 'pe-7s-user icon-gradient bg-midnight-bloom';

  users: User[];
  companies: Company[];
  globalsRights: UserRight[];
  companiesRights: CompanyUserRight[];
  currentUserGlobalsRights: UserRightSelection[];
  currentUserCompaniesRights: CompanyUserRights[];

  loadFinished: boolean = false;
  selectedUser: User;
  selectedUserId?: number;

  constructor(
    public usersService: UsersService,
    private globalsService: GlobalsService,
    private rightsService: RightsService, 
    private adB2cUserSelectorDialogService: AdB2cUserSelectorDialogService,
    private userSelectorDialogService: UserSelectorDialogService,
    private globals: ThemeOptions,
    private dialogService: DialogService,
    private spinnerService: NgxSpinnerService) { 

    }

  ngOnInit(): void {
    this.spinnerService.show();
    this.globals.adminMode = true;
    
    // Wait for all data received
    forkJoin([
      this.usersService.getAllUsers(),
      this.rightsService.getAllRights(),
      this.rightsService.getAllCompanyRights()    
    ]).subscribe(resp => {
      this.users = resp[0].sort((x, y) => x.name.toUpperCase() > y.name.toUpperCase() ? 1 : x.name.toUpperCase() < y.name.toUpperCase() ? -1 : 0);
      this.globalsRights = resp[1];
      this.companiesRights = resp[2];

      this.loadFinished = true;
      this.spinnerService.hide();
    })
    this.companies = this.globalsService.getCompanies().sort((x, y) => x.name.toUpperCase() > y.name.toUpperCase() ? 1 : x.name.toUpperCase() < y.name.toUpperCase() ? -1 : 0);    
  }
  
  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }

  onChangeUser = (): void => {
    this.spinnerService.show();

    this.selectedUser = null;
    this.usersService.getUser(this.selectedUserId).subscribe(resp => {
      this.selectedUser = resp;
      this.currentUserGlobalsRights = this.globalsRights.map(gr => {
        return {
          rightId: gr.rightId,
          section: gr.section,
          module: gr.module,
          action: gr.action,
          selected: this.selectedUser.globalUsersRights.findIndex(ur => gr.rightId == ur.rightId) >= 0
        }
      });
  
      this.currentUserCompaniesRights = this.companies.map(c => {
        let result: CompanyUserRights = {
          company: c,
          rights: this.companiesRights.map((cr) : CompanyUserRightSelection => {
            return {
              rightId: cr.rightId,
              companyId: c.id,
              section: cr.section,
              module: cr.module,
              action: cr.action,
              selected: this.selectedUser.companiesUsersRights.findIndex(ur => c.id == ur.companyId && cr.rightId == ur.rightId) >= 0
      
            }
          }) 
        };
        return result;       
      });  

      this.spinnerService.hide();
    });
  }
  
  addUser = (): void => {
    this.adB2cUserSelectorDialogService.showAdB2cUserSelector(selectedADB2CUser => {
      this.usersService.addUser(selectedADB2CUser).subscribe(x => {
        this.dialogService.showMessage("User management", "User added");
        this.usersService.getAllUsers().subscribe(resp => {
          this.users = resp.sort((x, y) => x.name.toUpperCase() > y.name.toUpperCase() ? 1 : x.name.toUpperCase() < y.name.toUpperCase() ? -1 : 0);
        });
      });
    });
  }

  saveUser = (): void => {
    let user: User = { ...this.selectedUser };

    user.globalUsersRights = this.currentUserGlobalsRights.filter(x => x.selected);
    user.companiesUsersRights = this.flatten(this.currentUserCompaniesRights.map(x => x.rights)).filter(x => x.selected);

    this.usersService.updateUser(user).subscribe(() => {
      this.dialogService.showMessage("User management", "User updated");
    });
  }

  copyUserRights = (): void => {
    this.userSelectorDialogService.showUserSelector(sourceUser => {
      this.usersService.copyUserRights(sourceUser.id, this.selectedUser.id).subscribe(x => {        
        this.dialogService.showMessage("User management", "User rights copied");
        this.onChangeUser();
      });
    });

  }

  selectAllGlobalRights = (): void => {
    this.currentUserGlobalsRights.forEach(x => x.selected = true);        
  }

  unselectAllGlobalRights = (): void => {
    this.currentUserGlobalsRights.forEach(x => x.selected = false);        
  }

  selectAllCompaniesRights = (): void => {
    this.currentUserCompaniesRights.forEach(x => x.rights.forEach(y => y.selected = true));
  }

  unselectAllCompaniesRights = (): void => {
    this.currentUserCompaniesRights.forEach(x => x.rights.forEach(y => y.selected = false));
  }

}

export interface UserRightSelection extends UserRight {
  selected: boolean;
}

export interface CompanyUserRightSelection extends CompanyUserRight {
  selected: boolean;
}

export interface CompanyUserRights {
  company: Company,
  rights: CompanyUserRightSelection[] 
}
