import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdB2cUserSelectorDialogComponent } from 'src/app/components/dialogs/admin/ad-b2c-user-selector-dialog/ad-b2c-user-selector-dialog.component';
import { ADB2CUser } from 'src/app/models/services/adB2CUser';

@Injectable({
  providedIn: 'root'
})
export class AdB2cUserSelectorDialogService {

  constructor(
    private modalService: NgbModal
  ) { }

  public showAdB2cUserSelector = (acceptCallback: (selectedADB2CUser: ADB2CUser) => void, rejectCallback?: () => void): void => {
    var ref = this.modalService.open(AdB2cUserSelectorDialogComponent, {
      backdrop: 'static'
    });
    let output: ADB2CUser;

    // Get the OkDialog from the NgbModal object  
    let dialog: AdB2cUserSelectorDialogComponent = ref.componentInstance as AdB2cUserSelectorDialogComponent;

    // Set the dialog's properties
    dialog.accept = (selectedADB2CUser: ADB2CUser) => {      
      output = selectedADB2CUser;
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }

}
