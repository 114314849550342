import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

// DEMO PAGES

// Dashboards

import { AnalyticsComponent } from './DemoPages/Dashboards/analytics/analytics.component';

// Pages

import { ForgotPasswordBoxedComponent } from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { LoginBoxedComponent } from './DemoPages/UserPages/login-boxed/login-boxed.component';
import { RegisterBoxedComponent } from './DemoPages/UserPages/register-boxed/register-boxed.component';

// Elements

import { StandardComponent } from './DemoPages/Elements/Buttons/standard/standard.component';
import { DropdownsComponent } from './DemoPages/Elements/dropdowns/dropdowns.component';
import { CardsComponent } from './DemoPages/Elements/cards/cards.component';
import { ListGroupsComponent } from './DemoPages/Elements/list-groups/list-groups.component';
import { TimelineComponent } from './DemoPages/Elements/timeline/timeline.component';
import { IconsComponent } from './DemoPages/Elements/icons/icons.component';

// Components

import { AccordionsComponent } from './DemoPages/Components/accordions/accordions.component';
// import {TabsComponent} from './DemoPages/Components/tabs/tabs.component';
import { CarouselComponent } from './DemoPages/Components/carousel/carousel.component';
import { ModalsComponent } from './DemoPages/Components/modals/modals.component';
import { ProgressBarComponent } from './DemoPages/Components/progress-bar/progress-bar.component';
import { PaginationComponent } from './DemoPages/Components/pagination/pagination.component';
import { TooltipsPopoversComponent } from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import { TablesMainComponent } from './DemoPages/Tables/tables-main/tables-main.component';

// Widgets

import { ChartBoxes3Component } from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import { ControlsComponent } from './DemoPages/Forms/Elements/controls/controls.component';
import { LayoutComponent } from './DemoPages/Forms/Elements/layout/layout.component';

// Charts

import { ChartjsComponent } from './DemoPages/Charts/chartjs/chartjs.component';
import { FileUploadComponent } from './pages/segments/file-upload/file-upload.component';
import { SegmentsListComponent } from './pages/segments/segments-list/segments-list.component';
import { AudiencesBuilderComponent } from './pages/audiences/deprecated/audiences-builder/audiences-builder.component';
import { AudiencesListComponent } from './pages/audiences/audiences-list/audiences-list.component';
import { InsightsComponent } from './pages/insights/insights/insights.component';
import { SegmentInsightsComponent } from './pages/segments/segment-insights/segment-insights.component';
import { RootComponent } from './pages/core/root/root.component';
import { LookAlikesListComponent } from './pages/look-alikes/look-alikes-list/look-alikes-list.component';
import { NewLookAlikeComponent } from './pages/look-alikes/new-look-alike/new-look-alike.component';
import { CustomInsightsComponent } from './pages/insights/custom-insights/custom-insights.component';
import { AdminDataTransfersComponent } from './pages/admin/data-transfers/admin-data-transfers.component';
import { AdminDataTransfersReportComponent } from './pages/admin/data-transfers-report/admin-data-transfers-report.component';
import { AudiencesSchedulerComponent } from './pages/audiences/audiences-scheduler/audiences-scheduler.component';
import { RootAdminComponent } from './pages/core/root-admin/root-admin.component';
import { LookAlikeSourceTypes } from './models/services/lookAlikeSourceTypes.enum';
import { AudiencesBuilderTransactionalComponent } from './pages/audiences/deprecated/audiences-builder-transactional/audiences-builder-transactional.component';
import { AdminUsersComponent } from './pages/admin/users/admin-users.component';
import { AdminProcessesComponent } from './pages/admin/processes/admin-processes.component';
import { AdminCompaniesComponent } from './pages/admin/companies/admin-companies.component';
import { AdminPowerBIReportsComponent } from './pages/admin/powerbi-reports/admin-powerbi-reports.component';
import { IABMasterMaintenanceComponent } from './pages/admin/iab-master-maintenance/iab-master-maintenance.component';
import { TaxonomyMaintenanceComponent } from './pages/admin/taxonomy-maintenance/taxonomy-maintenance.component';
import { AudiencesBuilderV2Component } from './pages/audiences/audiences-builder-v2/audiences-builder-v2.component';
import { IngestionProgressComponent } from './components/blocks/admin/ingestion-progress/ingestion-progress.component';
import { AdminQAReportsComponent } from './pages/admin/qa-reports/admin-qa-reports.component';
import { NielsenDataComponent } from './pages/insights/nielsen-data/nielsen-data.component';
import { SqlRunnerComponent } from './pages/admin/utils/sql-runner/sql-runner.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [

      // Dashboads

      { path: '', component: RootComponent, data: { extraParameter: '' } },

      // Elements

      { path: 'elements/buttons-standard', component: StandardComponent, data: { extraParameter: 'elementsMenu' } },
      { path: 'elements/dropdowns', component: DropdownsComponent, data: { extraParameter: 'elementsMenu' } },
      { path: 'elements/icons', component: IconsComponent, data: { extraParameter: 'elementsMenu' } },
      { path: 'elements/cards', component: CardsComponent, data: { extraParameter: 'elementsMenu' } },
      { path: 'elements/list-group', component: ListGroupsComponent, data: { extraParameter: 'elementsMenu' } },
      { path: 'elements/timeline', component: TimelineComponent, data: { extraParameter: 'elementsMenu' } },

      // Components

      // {path: 'components/tabs', component: TabsComponent, data: {extraParameter: 'componentsMenu'}},
      { path: 'components/accordions', component: AccordionsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'components/modals', component: ModalsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'components/progress-bar', component: ProgressBarComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'components/tooltips-popovers', component: TooltipsPopoversComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'components/carousel', component: CarouselComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'components/pagination', component: PaginationComponent, data: { extraParameter: 'componentsMenu' } },

      // Tables

      { path: 'tables/bootstrap', component: TablesMainComponent, data: { extraParameter: 'tablesMenu' } },

      // Widgets

      { path: 'widgets/chart-boxes-3', component: ChartBoxes3Component, data: { extraParameter: 'pagesMenu3' } },

      // Forms Elements

      { path: 'forms/controls', component: ControlsComponent, data: { extraParameter: 'formElementsMenu' } },
      { path: 'forms/layouts', component: LayoutComponent, data: { extraParameter: 'formElementsMenu' } },

      // Charts

      { path: 'charts/chartjs', component: ChartjsComponent, data: { extraParameter: '' } },

      // PublicisID

      { path: 'segments/file-upload', component: FileUploadComponent, data: { extraParameter: '' } },
      { path: 'segments/list', component: SegmentsListComponent, data: { extraParameter: '' } },
      { path: 'segments/:segmentId/insights', component: SegmentInsightsComponent, data: { extraParameter: '' } },
      { path: 'segments/:sourceIdentifier/look-alikes', component: LookAlikesListComponent, data: { sourceType: LookAlikeSourceTypes.Segment } },
      { path: 'segments/:sourceIdentifier/look-alikes/new', component: NewLookAlikeComponent, data: { sourceType: LookAlikeSourceTypes.Segment } },
      { path: 'audiences/builder', component: AudiencesBuilderV2Component, data: { extraParameter: '' } },
      { path: 'audiences/builder/transactional', component: AudiencesBuilderTransactionalComponent, data: { extraParameter: '' } },
      { path: 'audiences/list', component: AudiencesListComponent, data: { extraParameter: '' } },
      { path: 'audiences/scheduler', component: AudiencesSchedulerComponent, data: { extraParameter: '' } },
      { path: 'audiences/:sourceIdentifier/look-alikes', component: LookAlikesListComponent, data: { sourceType: LookAlikeSourceTypes.Audience } },
      { path: 'audiences/:sourceIdentifier/look-alikes/new', component: NewLookAlikeComponent, data: { sourceType: LookAlikeSourceTypes.Audience } },
      { path: 'insights/general', component: InsightsComponent, data: { extraParameter: '' } },
      { path: 'insights/custom', component: CustomInsightsComponent, data: { extraParameter: '' } },
      { path: 'insights/nielsen-data', component: NielsenDataComponent, data: { extraParameter: '' } },
      { path: 'admin', component: RootAdminComponent, data: { extraParameter: '' } },
      { path: 'admin/data-transfers', component: AdminDataTransfersComponent, data: { extraParameter: '' } },
      { path: 'admin/data-transfers-report', component: AdminDataTransfersReportComponent, data: { extraParameter: '' } },
      { path: 'admin/master/powerbi-reports', component: AdminPowerBIReportsComponent, data: { extraParameter: '' } },      
      { path: 'admin/master/iab', component: IABMasterMaintenanceComponent, data: { extraParameter: '' } },      
      { path: 'admin/master/taxonomy', component: TaxonomyMaintenanceComponent, data: { extraParameter: '' } },      
      { path: 'admin/user-rights', component: AdminUsersComponent, data: { extraParameter: '' } },
      { path: 'admin/companies', component: AdminCompaniesComponent, data: { extraParameter: '' } },
      { path: 'admin/processes', component: AdminProcessesComponent, data: { extraParameter: '' } },
      { path: 'admin/qa-reports', component: AdminQAReportsComponent, data: { extraParameter: '' } },
      { path: 'admin/sql-runner', component: SqlRunnerComponent, data: { extraParameter: '' } }

    ]

  },
  {
    path: '',
    component: PagesLayoutComponent,
    children: [

      // User Pages

      { path: 'pages/login-boxed', component: LoginBoxedComponent, data: { extraParameter: '' } },
      { path: 'pages/register-boxed', component: RegisterBoxedComponent, data: { extraParameter: '' } },
      { path: 'pages/forgot-password-boxed', component: ForgotPasswordBoxedComponent, data: { extraParameter: '' } },
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
