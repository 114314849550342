import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryBuilderConfig, RuleSet } from 'angular2-query-builder';
import { DndDropEvent } from 'ngx-drag-drop';
import { DialogService } from 'src/app/services/core/dialog.service';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';
import { TaxonomyGroup, TaxonomyRule, Taxonomy } from '../../../../models/services/taxonomiesConfig';
import { AudienceQueryCountRequest } from 'src/app/models/services/audienceQueryCount';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { timer } from 'rxjs';
import { AudienceParametersDialogService } from 'src/app/services/dialogs/audience-parameters-dialog.service';
import { CreateAudienceRequest } from 'src/app/models/services/createAudience';
import { AudienceParameters } from 'src/app/models/services/audience';
import { UsersService } from 'src/app/services/users.service';
import { AudienceSelectorDialogService } from 'src/app/services/dialogs/audience-selector-dialog.service';
import { BaseTenantRequiredPageComponent } from 'src/app/components/core/base-tenant-required-page/base-tenant-required-page.component';
import { GlobalsService } from 'src/app/services/globals.service';
import { AudiencesBuilderConfigService } from 'src/app/services/audiences/audiences-builder-config.service';

@Component({
  selector: 'app-audiences-builder',
  templateUrl: './audiences-builder.component.html',
  styleUrls: ['./audiences-builder.component.sass'],
})

export class AudiencesBuilderComponent extends BaseTenantRequiredPageComponent implements OnInit, DoCheck {
  // Flatten definition
  private flatten = (x) => x.reduce((a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []);

  // Public properties
  heading: string = 'Audiences builder';
  subheading: string = '';
  icon: string = 'pe-7s-radio icon-gradient bg-midnight-bloom';
  loadFinished: boolean = false;
  renderingQueryBuilder: boolean = false;
  environment = environment;
  dragging: boolean = false;
  query: RuleSet = {
    condition: 'and',
    rules: [
    ]
  };
  config: QueryBuilderConfig = {
    fields: {}
  }; // = queryBuilderConfig;
  customOperatorMap: { [key: string]: string[] } = {
    string: ['=', '!=', 'contains', 'like'],
    number: ['=', '!=', '>', '>=', '<', '<='],
    time: ['=', '!=', '>', '>=', '<', '<='],
    date: ['=', '!=', '>', '>=', '<', '<='],
    category: ['=', 'in'],
    boolean: ['=']
  };  
  taxonomyGroups: TaxonomyGroup[]; // = taxonomyGroups;
  taxonomyRulesPlain: TaxonomyRule[]; // = this.flatten(taxonomyGroups.map(x => x.taxonomies.map(y => y.taxonomyRules)));  
  resultsCountData = null;
  segmentId: string;
  segmentName: string;
  segmentRecordCount: number;
  loading: boolean = false;

  private rulesetIds: Map<object, string> = new Map<object, string>();
  private resultsJSONQuery: string;

  constructor(
    public usersService: UsersService,
    private audiencesService: AudiencesService,
    private audiencesBuilderConfigService: AudiencesBuilderConfigService,
    private dialogService: DialogService,
    private audienceParametersDialogServiceService: AudienceParametersDialogService,
    private audienceSelectorDialogService: AudienceSelectorDialogService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    globalsService: GlobalsService,
    router: Router) {
      super(
        usersService.hasSelectedCompanyModuleRight('Audiences', 'AudienceBuilder'),
        globalsService, 
        router);
  }

  ngOnInit(): void {
    if (this.redirecting)
      return;

    this.spinnerService.show();
    this.segmentId = this.route.snapshot.paramMap.get('segmentId');
    this.segmentName = this.route.snapshot.paramMap.get('segmentName');
    this.segmentRecordCount = +this.route.snapshot.paramMap.get('segmentRecordCount');

    this.audiencesBuilderConfigService.getAudienceBuilderConfig().subscribe(
      resp => {
        this.config = resp.queryBuilderConfig;
        this.taxonomyGroups = resp.taxonomies;
        let plain1 = this.flatten(resp.taxonomies.map(x => x.taxonomies.map(y => y.taxonomyRules)));
        let plain2 = this.flatten(resp.taxonomies.map(x => (!!x.searchableTaxonomies ? x.searchableTaxonomies : []).map(y => y.taxonomyRules)));
        this.taxonomyRulesPlain = [...plain1, ...plain2];
        this.loadFinished = true;
        this.spinnerService.hide();
      },
      () => {
        this.spinnerService.hide();
      }
    )
  }

  ngDoCheck(): void {
    if (JSON.stringify(this.query) != this.resultsJSONQuery)
      this.resultsCountData = null;

    if (this.renderingQueryBuilder) {
      this.spinnerService.hide();
      this.renderingQueryBuilder = false;
    }
  }

  public showQuery = () => {
    this.dialogService.showError(JSON.stringify(this.query));
  }

  onDrop(event: DndDropEvent) {
    let target: any = event.event.currentTarget;
    let targetId: string = target.id;
    let ruleset: RuleSet = this.getRulesetFromId(targetId);
    if (!ruleset)
      ruleset = this.query;

    let taxonomyRule: TaxonomyRule = this.getTaxonomyRuleFromId(event.data);

    // Add a copy of the rule
    if (!!taxonomyRule) {
      this.spinnerService.show();
      timer(500).subscribe(() => {
        this.renderingQueryBuilder = true;
        ruleset.rules.unshift(JSON.parse(JSON.stringify(taxonomyRule.rule)));
      });
    }
  };

  getIdFromRuleset = (ruleset: RuleSet): string => {
    let id: string = this.rulesetIds.get(ruleset);
    if (!id) {
      id = `rulesetContainer_${this.rulesetIds.size + 1}`;
      this.rulesetIds.set(ruleset, id);
    }

    return id;
  }

  private getRulesetFromId = (rulesetId: string): RuleSet => {
    let array = Array.from(this.rulesetIds.entries());
    let keyValuePair: [object, string] = array.find(x => x[1] == rulesetId);

    return (!!keyValuePair ? keyValuePair[0] : null) as RuleSet;
  }

  private getTaxonomyRuleFromId = (uniqueId: string): TaxonomyRule => {
    return this.taxonomyRulesPlain.find(x => x.uniqueId == uniqueId);
  }

  refreshCount = (): void => {

    this.spinnerService.show();
    var request: AudienceQueryCountRequest = {
      query: this.query
    };

    this.audiencesService.getAudienceCounts(request).subscribe(
      resp => {
        this.resultsCountData = {
          name: "Total",
          color: "#93f291",
          children: [
            {
              value: resp.audienceCount,
              color: "#34a832"
            },
            {
              value: resp.totalCount - resp.audienceCount,
              color: "#93f291"
            }
          ]
        };
        this.resultsJSONQuery = JSON.stringify(this.query);
        this.spinnerService.hide();
      },
      () => {
        this.spinnerService.hide();
      }
    );
  }

  createAudience = (): void => {
    this.audienceParametersDialogServiceService.show(
      (audienceParameters: AudienceParameters) => {
        // Save audience
        this.spinnerService.show();
        let request: CreateAudienceRequest = {
          name: audienceParameters.name,
          categoryId: audienceParameters.categoryId,
          query: this.query          
        };
        if(!!audienceParameters.schedulingId && +audienceParameters.schedulingId > 0) {
          request.schedulingId = audienceParameters.schedulingId;
          request.schedulingEndDate = audienceParameters.schedulingEndDate
        }
        
        this.audiencesService.createAudience(request).subscribe(
          () => {
            this.spinnerService.hide();
            this.dialogService.showMessage("Create audience", "Audience created");
          },
          () => {
            this.spinnerService.hide();
          }
        );
      },
      () => { }
    );
  }

  getQueryFromAudience = (): void => {
    this.audienceSelectorDialogService.showAudienceSelector(
      (audienceId:number) => {
        this.spinnerService.show();
        this.audiencesService.getAudience(audienceId).subscribe(resp => {
          this.query = resp.query;
          this.spinnerService.hide();
        });
      },
      () => {}
    );
  } 

  searchTaxonomyGroup = (taxonomyGroupName: string, searchTerm: string) => {
    this.spinnerService.show();
    timer(500).subscribe(() => {
      let searchRegExp: RegExp = new RegExp(searchTerm, 'i');
      let searchableGroup: TaxonomyGroup = this.taxonomyGroups.find(({ name }) => name === taxonomyGroupName);
      let foundTaxonomies: Taxonomy[] = [];
      searchableGroup.searchableTaxonomies.forEach(taxonomy => {
        let foundRules: TaxonomyRule[] = taxonomy.taxonomyRules.filter(({ description }) => description.match(searchRegExp));
        if (foundRules.length > 0)
          foundTaxonomies.push({
            name: taxonomy.name,
            taxonomyRules: foundRules
          });
      });

      searchableGroup.foundTaxonomies = foundTaxonomies;

      this.spinnerService.hide();
    });
  }
}
