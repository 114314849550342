import { Component, HostBinding } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptions } from '../../../theme-options';
import { Location } from '@angular/common'
import { GlobalsService } from 'src/app/services/globals.service';
import { ImpersonationService } from 'src/app/services/impersonation.service';
import { LoginService } from 'src/app/services/login.service';
import { environment } from 'src/environments/environment';
import { CompaniesService } from 'src/app/services/companies.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  faEllipsisV = faEllipsisV;

  constructor(
    public location: Location,
    public globals: ThemeOptions,
    public globalsService: GlobalsService,
    public impersonationService: ImpersonationService,
    public companiesService: CompaniesService,
    public loginService: LoginService) {
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  @select('config') public config$: Observable<any>;

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }

}
