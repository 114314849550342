import { Component, OnInit } from '@angular/core';
import { GenericItem } from 'src/app/models/components/generic-item';

@Component({
  selector: 'app-generic-id-name-selector-dialog',
  templateUrl: './generic-id-name-selector-dialog.component.html',
  styleUrls: ['./generic-id-name-selector-dialog.component.sass']
})
export class GenericIdNameSelectorDialogComponent implements OnInit {

  items: GenericItem[];
  selectedItems: GenericItem[];
  nonSelectedItems: GenericItem[];
  title: string = "Select an item";
  selectedItemId: any;

  constructor() { }

  ngOnInit(): void {
    this.nonSelectedItems = this.items
    .filter((x) => this.selectedItems.findIndex(y => y.id == x.id) < 0)
    .sort((x, y) => x.name.toUpperCase() > y.name.toUpperCase() ? 1 : x.name.toUpperCase() < y.name.toUpperCase() ? -1 : 0);
  }

  public accept: (selectedItem: GenericItem) => void;
  public dismiss: () => void;

  public onAccept = (selectedItemId: any): void  => {   
    let selectedItem: GenericItem = this.items.find(x => x.id == selectedItemId);
    this.accept(selectedItem);
  }

  onDismiss = (): void => {
    this.dismiss();
  }
}
