import { Component, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ConfigActions } from '../../ThemeOptions/store/config.actions';
import { ThemeOptions } from '../../theme-options';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { UsersService } from 'src/app/services/users.service';
import { LoginService } from 'src/app/services/login.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { CompaniesService } from '../../services/companies.service';
import { timer } from 'rxjs';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { forkJoin } from 'rxjs';
import { ImpersonationService } from '../../services/impersonation.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  animations: [

    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column'

          }),
        ]),
        query(':enter', [
          animate('200ms ease', style({ opacity: 1, transform: 'translateY(0)' })),
        ]),

        query(':leave', [
          animate('200ms ease', style({ opacity: 0, transform: 'translateY(-20px)' })),
        ], { optional: true })
      ]),
    ])
  ]
})

export class BaseLayoutComponent implements OnInit {

  @select('config') public config$: Observable<any>;

  constructor(
    public globals: ThemeOptions,
    public configActions: ConfigActions,
    public userService: LoginService,
    public usersService: UsersService,
    public globalsService: GlobalsService,
    private loginService: LoginService,
    private impersonationService: ImpersonationService,
    private companiesService: CompaniesService) {
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  ngOnInit(): void {
    registerLocaleData(es);
    if (!this.loginService.isLogged()) {
      this.loginService.login();
      return;
    }

    if (this.globalsService.getIsStarting()) {
      this.loadInitialData();
    }
  }

  private loadInitialData = (): void => {
    if (this.loginService.loginInteractionInProgress()) {
      timer(500).subscribe(() => this.loadInitialData());
      return;
    }

    forkJoin([
      !this.impersonationService.isImpersonationActive() ? this.usersService.getCurrentLoggedUser() : this.usersService.getUser(this.impersonationService.getImpersonatedUserId()),
      this.companiesService.getAll()
    ]).subscribe(
      resp => {
        // Set global resources
        this.globalsService.setCompanies(resp[1]);
        this.globalsService.setLoggedUser(resp[0]);

        if (this.globalsService.getAllowedCompanies().length == 1)
          this.companiesService.setActiveCompany(this.globalsService.getAllowedCompanies()[0].id);

        this.globalsService.setIsStarting(false);
      },
      (errorResponse: HttpErrorResponse) => { 
        if (!!this.usersService.getCurrentLoggedUser())
          this.globalsService.setIsPlatformMaintenance(true);

        this.globalsService.setIsStarting(false);
        console.log("Error loading user info") 
      },
      () => { console.log("Completed loading user info") }
    );
  }
}



