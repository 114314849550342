<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [searchEnabled]="true" [openSearchDialogCallback]="showFilterDialog" [isFilterApplied]="isFilterApplied">
</app-page-title>
<div *ngIf="!usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesList')">
    You have no rights to access this page
</div>
<div *ngIf="usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesList')">
    <app-publicis-table [dataSource]="audiencesDataSource" #parent>
        <ng-container *ngFor="let audience of parent.data; index as i">
            <tr>
                <th scope="row" class="align-middle" style="width: 5%;">{{ audience.id }}</th>
                <td class="align-middle" style="width: 10%;">{{ audience.category.name }}</td>
                <td class="align-middle" style="width: 25%; word-break: break-all;">{{ audience.name }}</td>
                <td class="align-middle" style="width: 10%;">{{ audience.created | date }}</td>
                <td class="align-middle" style="width: 7%;">{{ audience.updated | date }}</td>
                <td class="align-middle" style="width: 7%;">{{ audience.audienceScheduling?.endDate | date }}</td>
                <td class="align-middle" style="width: 16%;" [style.color]="!audience.audienceScheduling || audience.audienceScheduling.scheduling.id === 1 ? 'red' : null">{{!!audience.audienceScheduling ? audience.audienceScheduling.scheduling.description : 'Non scheduled' }}</td>
                <td class="align-middle" style="width: 10%;">
                    <div class="row">
                        <div class="btn" style="padding: 0.1rem 0.1rem" title="Schedule audience refresh">
                            <div class="font-icon-wrapper-medium" (click)="scheduleAudienceRefresh(audience)">
                                <i class="pe-7s-clock"></i>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-container>
    </app-publicis-table>
</div>