import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Rule } from 'angular2-query-builder';
import { AttributeValueDateSelectorDialogComponent } from 'src/app/components/dialogs/audiences-builder/attribute-value-date-selector-dialog/attribute-value-date-selector-dialog.component';
import { TaxonomyItem } from 'src/app/models/services/taxonomiesConfig';

@Injectable({
  providedIn: 'root'
})
export class AttributeValueDateSelectorDialogService {
  constructor(
    private modalService: NgbModal
  ) { }

  public show = (taxonomyItem: TaxonomyItem, acceptCallback: (rule: Rule) => void, rejectCallback?: () => void): void => {
    var ref = this.modalService.open(AttributeValueDateSelectorDialogComponent, {
      backdrop: 'static',
      size: 'xl'
    });
    let output: Rule;

    // Get the OkDialog from the NgbModal object  
    let dialog: AttributeValueDateSelectorDialogComponent = ref.componentInstance as AttributeValueDateSelectorDialogComponent;

    // Set the dialog's properties
    dialog.taxonomyItem = taxonomyItem;
    dialog.accept = (rule: Rule) => {      
      output = rule;
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }

}
