export enum AudiencesFilesTargetPlatforms {
    WAMProxy = 1,
    Facebook = 2,
    TikTok = 3,
    UnityOnboarding = 4,
    TapTap = 5,
    Movistar = 6,
    Amazon = 7,
    TapTapOOH = 8,
    Xandr = 9
}