<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div *ngIf="!usersService.hasGlobalSectionRight('Admin')">
    You have no rights to access this page
</div>
<div *ngIf="locked && usersService.hasGlobalSectionRight('Admin')">
    A taxonomy maintenance process is currently in progress. Please try again later or wait for the process to finish.
</div>
<div *ngIf="loadFinished && usersService.hasGlobalSectionRight('Admin')">
    <div class="row">
        <div class="col-md-6">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <h2 class="card-title">Taxonomy tree</h2>
                    <eris-iab-master-tree [data]="treeData" (onSelectedNodeChange)="onSelectedNodeChange($event)">
                    </eris-iab-master-tree>
                </div>
            </div>
        </div>
        <div class="col" style="overflow: visible;">
            <div class="main-card mb-3 card" *ngIf="!!selectedNode" style="position: sticky; top:100px">
                <div class="card-body">
                    <h2 class="card-title">{{selectedNode.name}}</h2>
                    <form [formGroup]="form">
                        <div class="form-group">
                            <label for="name">Name:</label>
                            <input type="text" formControlName="name" [(ngModel)]="selectedNode.name" class="form-control"
                                [ngClass]="{ 'is-invalid': form.controls.name.errors && (form.controls.name.dirty || form.controls.name.touched) }">
                            <div *ngIf="form.controls.name.errors && (form.controls.name.dirty || form.controls.name.touched)"
                                class="cross-validation-error-message alert alert-danger">
                                <div *ngIf="form.controls.name.errors?.required">
                                    Name is required
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="name">Description:</label>
                            <input type="text" formControlName="description" [(ngModel)]="selectedNode.description" class="form-control"
                                [ngClass]="{ 'is-invalid': form.controls.description.errors && (form.controls.description.dirty || form.controls.description.touched) }">
                            <div *ngIf="form.controls.description.errors && (form.controls.description.dirty || form.controls.description.touched)"
                                class="cross-validation-error-message alert alert-danger">
                                <div *ngIf="form.controls.description.errors?.required">
                                    Description is required
                                </div>
                            </div>
                        </div>
                    </form>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Keyword</th>
                                <th>Partner</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let keyword of selectedNode.keywords">
                                <td>{{keyword.value}}</td>
                                <td>{{getPartnersNames(keyword)}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="text-align: right;">
                        <button class="btn btn-success" (click)="joinSelectedNode()"  [disabled]="form.invalid">Join</button>&nbsp;&nbsp;
                        <button class="btn btn-success" (click)="reparentSelectedNode()"  [disabled]="form.invalid">Reparent</button>&nbsp;&nbsp;
                        <button class="btn btn-success" (click)="saveSelectedNode()" [disabled]="form.invalid">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>