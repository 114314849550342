import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models/services/company';
import { CompaniesService } from 'src/app/services/companies.service';
import { DialogService } from 'src/app/services/core/dialog.service';

@Component({
  selector: 'app-company-logo-upload-dialog',
  templateUrl: './company-logo-upload-dialog.component.html',
  styleUrls: ['./company-logo-upload-dialog.component.scss']
})
export class CompanyLogoUploadDialogComponent implements OnInit {

  title: string = "Select a png file";
  company: Company;
  uploadingFile: boolean = false;
  file: any;  

  constructor(
    private companiesService: CompaniesService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
  }

  public accept: () => void;
  public dismiss: () => void;

  public onAccept = (): void  => {       
    this.accept();
  }

  onDismiss = (): void => {
    this.dismiss();
  }

  /**
   * on file drop handler
   */
  onFileDropped = ($event): void => {
    if($event.length > 1)
    {
      this.dialogService.showError('Only one file is allowed');      
      return;
    }

    this.file = $event[0];
    this.file.progress = 0;

    this.uploadFile();
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler = ($event): void => {
    this.file = $event.target.files[0];
    this.file.progress = 0;

    this.uploadFile();
    
    $event.target.value = '';
  }

  private uploadFile = (): void => {
    if(this.file.type != "image/png")
    {
      this.dialogService.showError('Only png files are allowed');      
      return;
    }
    this.uploadingFile = true;

    this.companiesService.uploadLogo(this.company, this.file).subscribe(
      resp => {
        if (resp.type === HttpEventType.Response) {
          this.uploadingFile = false;
          console.log('Upload complete');
          this.accept();
        }
        if (resp.type === HttpEventType.UploadProgress) {

          let percentDone: number = Math.round(100 * resp.loaded / resp.total);
          this.file.progress = percentDone;

          console.log('Progress ' + percentDone + '%');
        }
      });
  }  
}
