import { ObservableStore } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer, Subscription } from 'rxjs';
import { ErisProcessQueueInfo } from 'src/app/models/services/erisProcessQueueInfo';
import { IngestionProcessType } from 'src/app/models/services/ingestionProcessType.enum';
import { MonitorService } from 'src/app/services/monitor.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-ingestion-progress',
  templateUrl: './ingestion-progress.component.html',
  styleUrls: ['./ingestion-progress.component.sass']
})
export class IngestionProgressComponent implements OnInit, OnDestroy {


  heading: string = 'Ingestion progress';
  subheading: string = '';
  icon: string = 'pe-7s-config icon-gradient bg-midnight-bloom';
  loadFinished: boolean = false;
  activePolling: boolean = true;

  identitiesQueueProcessesInfo: ErisProcessQueueInfo[];
  attributesQueueProcessesInfo: ErisProcessQueueInfo[];

  public identitiesChartData: ChartDataSets[] = [];  
  public attributesChartData: ChartDataSets[] = [];  
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        }
      ]
    }, elements: {
      point: {
        radius: 1,
        hitRadius: 5,
        hoverRadius: 10,
        hoverBorderWidth: 2
      }
    }, plugins: {
      datalabels: {
          display: false
      }
    }
  };
  public lineChartColors: Color[] = [
    { // 1
      backgroundColor: 'rgba(0,158,190,0.2)',
      borderColor: 'rgba(51,177,203,1)',
      pointBackgroundColor: 'rgba(102,197,216,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(102,197,216,0.8)'
    },
    { // 2
      backgroundColor: 'rgba(117,131,157,1)',
      borderColor: 'rgba(83,100,133.2)',
      pointBackgroundColor: 'rgba(152,162,182,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(152,162,182,1)'
    },
    { // 3
      backgroundColor: 'rgba(141,161,65)',
      borderColor: 'rgba(113,138,17)',
      pointBackgroundColor: 'rgba(170,185,112,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(170,185,112,0.8)'
    },
    { // 4
      backgroundColor: 'rgba(255,200,129)',
      borderColor: 'rgba(255,163,45)',
      pointBackgroundColor: 'rgba(255,218,171,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255,218,171,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  public isOptimizationEnabled: boolean = true;
  public currentRefreshTimerSubscription: Subscription;

  constructor(
    public usersService: UsersService,
    private globals: ThemeOptions,
    private monitorService: MonitorService,
    private spinnerService: NgxSpinnerService    
  ) { 
  }

  ngOnInit(): void {
    this.globals.adminMode = true;
    this.loadQueueInfo();
  }

  ngOnDestroy(): void {
    this.activePolling = false;
    this.globals.adminMode = false;
  }

  loadQueueInfo = (): void => {    
    if(this.activePolling)
    {
      this.monitorService.getIngestionProcessesInfo().subscribe(resp => {
        this.identitiesQueueProcessesInfo = resp.filter(x => x.processType == 2);
        if (this.identitiesChartData.length == 0)
        {            
          this.identitiesQueueProcessesInfo.forEach(x => {
            this.identitiesChartData.push({ data: [ x.approximateMessageCount * x.processMaxBatchSize ], label: x.partnerDescription})
          });
        }
        else {
          this.identitiesQueueProcessesInfo.forEach(x => {
            var dataset = this.identitiesChartData.find(y => y.label == x.partnerDescription);
            dataset.data.push(x.approximateMessageCount * x.processMaxBatchSize);
            x.estimatedTimeToFinish = this.getEstimatedTimeToFinish(x, dataset);

            // Limit to 5 minutes
            if(dataset.data.length > 30)
              dataset.data.shift();              
          });        
        } 

        this.attributesQueueProcessesInfo = resp.filter(x => x.processType == 3);
        if (this.attributesChartData.length == 0)
        {            
          this.attributesQueueProcessesInfo.forEach(x => {
            this.attributesChartData.push({ data: [ x.approximateMessageCount * x.processMaxBatchSize ], label: x.partnerDescription})
          });
        }
        else {
          this.attributesQueueProcessesInfo.forEach(x => {
            var dataset = this.attributesChartData.find(y => y.label == x.partnerDescription);
            dataset.data.push(x.approximateMessageCount * x.processMaxBatchSize);
            x.estimatedTimeToFinish = this.getEstimatedTimeToFinish(x, dataset);

            // Limit to 5 minutes
            if(dataset.data.length > 30)
              dataset.data.shift();              
          });        
        }         
        this.lineChartLabels.push(new Date().toLocaleTimeString())
        // Limit to 5 minutes
        if(this.lineChartLabels.length > 30)
          this.lineChartLabels.shift();

        this.loadFinished = true;

        this.currentRefreshTimerSubscription = timer(10000).subscribe(() => this.loadQueueInfo()); 
      }, 
      () => { 
        this.currentRefreshTimerSubscription = timer(10000).subscribe(() => this.loadQueueInfo()); 
      });
      
      this.monitorService.isOptimizationEnabled().subscribe( resp => {
        this.isOptimizationEnabled = resp;
      });
    }
  }

  getIdentititesProcessDescription = (processQueueInfo: ErisProcessQueueInfo): string => {
    switch(processQueueInfo.partnerDescription) {
      case "Carrefour":
        return "Number of tickets pending of ingestion";
      case "CitiServi":
        return "Number of signals pending of ingestion";
      default:
        return "Number of profiles pending of ingestion";                  
    }
  }

  getAttributesProcessDescription = (processQueueInfo: ErisProcessQueueInfo): string => {
    switch(processQueueInfo.partnerDescription) {
      case "Carrefour":
        return "Number of tickets attributes pending of update";
      default:
        return "Number of profiles attributes pending of update";                  
    }
  }

  getProcessStatus = (processQueueInfo: ErisProcessQueueInfo): number => {
    if (processQueueInfo.approximateMessageCount == 0)
      return 0;

    let dataset: ChartDataSets;
    if (processQueueInfo.processType == 2)
      dataset = this.identitiesChartData.find(y => y.label == processQueueInfo.partnerDescription);
    else
      dataset = this.attributesChartData.find(y => y.label == processQueueInfo.partnerDescription);

    if (dataset.data.length < 6)
      return 0;

    let processedPerMinute: number = (dataset.data[dataset.data.length - 7] as number) - (dataset.data[dataset.data.length - 1] as number);    
    return processedPerMinute == 0 ? 2 : 1;
  }

  private getEstimatedTimeToFinish = (processQueueInfo: ErisProcessQueueInfo, dataset: ChartDataSets): string => {
    if (dataset.data.length < 6)
      return '-';

    let remainingItems: number = processQueueInfo.approximateMessageCount * processQueueInfo.processMaxBatchSize;
    let processedPerMinute: number = (dataset.data[dataset.data.length - 7] as number) - (dataset.data[dataset.data.length - 1] as number);
    let remainingDays: number = processedPerMinute > 0 ? Math.floor((remainingItems / processedPerMinute) / (60 * 24)) : 0;
    let remainingHours: number = processedPerMinute > 0 ? Math.floor((remainingItems / processedPerMinute) / 60) - remainingDays * 24 : 0;              
    let remainingMinutes: number = processedPerMinute > 0 ? Math.floor(remainingItems / processedPerMinute) % 60 : 0;     
    
    if(remainingDays > 0)
      return `${remainingDays} days, ${remainingHours} hours and ${remainingMinutes} minutes`;

    if(remainingHours > 0)
      return `${remainingHours} hours and ${remainingMinutes} minutes`;

    if(remainingMinutes > 0)
      return `${remainingMinutes} minutes`;

    return '-';    
  }

  enableOptimization = (): void => {
    this.spinnerService.show();
    this.monitorService.enableOptimization().subscribe(() => {
      this.isOptimizationEnabled = true;
      this.spinnerService.hide();
    }, () => {
      this.spinnerService.hide();
    });    
  }

  disableOptimization = (): void => {
    this.spinnerService.show();
    this.monitorService.disableOptimization().subscribe(() => {
      this.isOptimizationEnabled = false;
      this.spinnerService.hide();
    }, () => {
      this.spinnerService.hide();
    });
  }

  updateProcessOptimization = (queueProcessInfo: ErisProcessQueueInfo): void => {
    this.spinnerService.show();
    this.monitorService.enableOptimization().subscribe(() => {
      this.isOptimizationEnabled = true;
      this.spinnerService.hide();
    }, () => {
      this.spinnerService.hide();
    });    
  }

  queueProcessInfoEnabledChange = (processQueueInfo: ErisProcessQueueInfo): void => {
    this.spinnerService.show();
    this.currentRefreshTimerSubscription.unsubscribe(); 
    if (processQueueInfo.enabled) {
      processQueueInfo.enabled = false;
      this.monitorService.disableProcessOptimization(processQueueInfo.id).subscribe(() => {
        this.spinnerService.hide();
        this.currentRefreshTimerSubscription = timer(10000).subscribe(() => this.loadQueueInfo()); 
      }, () => {
        this.spinnerService.hide();
        processQueueInfo.enabled = true;
        this.currentRefreshTimerSubscription = timer(10000).subscribe(() => this.loadQueueInfo()); 
      });  
    } else {
      processQueueInfo.enabled = true;
      this.monitorService.enableProcessOptimization(processQueueInfo.id).subscribe(() => {
        this.spinnerService.hide();
        this.currentRefreshTimerSubscription = timer(10000).subscribe(() => this.loadQueueInfo()); 
      }, () => {
        this.spinnerService.hide();
        processQueueInfo.enabled = false;
        this.currentRefreshTimerSubscription = timer(10000).subscribe(() => this.loadQueueInfo()); 
      });  
    }
  }  
}
