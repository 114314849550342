import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-platform-maintenance',
  templateUrl: './platform-maintenance.component.html',
  styleUrls: ['./platform-maintenance.component.sass']
})
export class PlatformMaintenanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
