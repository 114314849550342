import { Component, OnInit } from '@angular/core';
import { DataTransfer, PublicisAudience, PublicisAudienceFile, PublicisAudienceFileTimeSent } from 'src/app/models/services/dataTransferInfo';
import { IdGraphTypes } from 'src/app/models/services/idGraphTypes.enum';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';

@Component({
  selector: 'app-data-transfer-info-dialog',
  templateUrl: './data-transfer-info-dialog.component.html',
  styleUrls: ['./data-transfer-info-dialog.component.sass']
})
export class DataTransferInfoDialogComponent implements OnInit {
  public dismiss: () => void;
  public dataTransfer: DataTransfer;

  title: string = "Data transfer info"; 

  private flatten = (x) => x.reduce((a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []);

  constructor(private audiencesService: AudiencesService) { }

  ngOnInit(): void {
  }

  getSentHistory = (dataTransfer: DataTransfer): PublicisAudienceFileTimeSent[] => {
    if(dataTransfer.audienceFiles?.length == 0)
      return [];

    let result: PublicisAudienceFileTimeSent[] = this.flatten(dataTransfer.audienceFiles.map(x => x.timesSent));
    return result.sort();
  }

  getUniqueAudiences = (dataTransfer: DataTransfer): PublicisAudience[] => {
    if(dataTransfer.audienceFiles?.length == 0)
      return [];

    let result: PublicisAudience[] = this.flatten(dataTransfer.audienceFiles.map(x => x.audience));
    return this.uniqBy(result, JSON.stringify).sort((x: PublicisAudience, y: PublicisAudience) => x.id - y.id);
  }

  uniqBy = (a: any, key: any) => {
    var seen = {};
    return a.filter((item) => {
        var k = key(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
  }  

}


