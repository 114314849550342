import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AudienceListFilterDialogComponent } from 'src/app/components/dialogs/audiences/audience-list-filter-dialog/audience-list-filter-dialog.component';
import { Audience } from 'src/app/models/services/audience';
import { Tag } from 'src/app/models/services/tag';
import { AudiencesListFilter } from 'src/app/models/ui/audiencesListFilter';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class AudiencesListFilterDialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public show = (allTags: Tag[], currentFilter: AudiencesListFilter, acceptCallback: () => void, rejectCallback?: () => void): void => {    
    var ref = this.modalService.open(AudienceListFilterDialogComponent, { size: 'xl' });

    // Get the OkDialog from the NgbModal object  
    let dialog: AudienceListFilterDialogComponent = ref.componentInstance as AudienceListFilterDialogComponent;

    // Set the dialog's properties
    dialog.tags = allTags;
    dialog.filter = currentFilter;
    dialog.accept = () => {
      ref.close(1); 
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback();
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
   
  }
}
