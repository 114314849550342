<!-- https://stackblitz.com/angular/gabkadkvybq?file=app%2Ftree-checklist-example.ts -->
<mat-tree [dataSource]="nodesTreeDataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node"
            [checked]="selectionModel.isSelected(node)"
            (change)="nodeSelectionToggle(node);">
            {{node.name}}
        </mat-checkbox>                                                                                        
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: nodeHasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}                
            </mat-icon>
        </button>
        <mat-checkbox
            [checked]="descendantsAllSelected(node)"
            [indeterminate]="descendantsPartiallySelected(node)"
            (change)="nodeSelectionToggle(node)">
            {{node.name}}
        </mat-checkbox>                                            
    </mat-tree-node>
</mat-tree>