<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [searchEnabled]="true" [searchCallback]="filterSegments"></app-page-title>
<div class="card">
    <div class="card-body row">
        <div class="col-lg-6 text-left">
            <!--<app-search-box></app-search-box>-->
        </div>
        <div class="col-lg-6 text-right">
            <a routerLink="/segments/file-upload" routerLinkActive="active-item"
                [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success">
                    New segment
                </button>
            </a>

        </div>
    </div>
</div>
<app-publicis-table [dataSource]="segmentsDataSource" #parent>
    <ng-container *ngFor="let segment of parent.data; index as i">
        <tr>
            <td class="align-middle">{{ segment.fileName}}</td>
            <td class="align-middle">{{ segment.createdOn | date }}</td>
            <td class="align-middle">
                <ng-container *ngIf="segment.uploadedRecords == 0">...</ng-container>
                <ng-container *ngIf="segment.uploadedRecords > 0">{{ segment.uploadedRecords | number:'':'es' }}</ng-container> 
            </td>
            <td class="align-middle">
                <ng-container *ngIf="segment.matchedRecords == 0">...</ng-container>
                <ng-container *ngIf="segment.matchedRecords > 0">{{  segment.matchedRecords | number:'':'es' }}</ng-container> 
            </td>
            <td class="align-middle">
                <div>
                    <div class="font-icon-wrapper">
                        <i [class]="getStatusStyle(segment)"></i>
                    </div>
                    <div class="d-flex justify-content-center">
                        {{ getStatusLabel(segment) }}
                    </div>
                </div>
            </td>
            <td class="align-middle">
                <div class="row">
                    <div class="btn" *ngIf="segment?.status == 4" [title]="segment?.segmentInsightsCalculated ? 'Refresh insights' : 'Calculate insights'">
                        <div class="font-icon-wrapper" (click)="calculateInsights(segment)">
                            <i [class]="segment?.segmentInsightsCalculated ? 'pe-7s-refresh-2' : 'pe-7s-calculator'"></i>
                        </div>
                    </div>
                    <div class="btn" *ngIf="segment?.segmentInsightsCalculated && segment?.status == 4" title="Segment insights">
                        <div class="font-icon-wrapper" (click)="viewInsights(segment)">
                            <i class="pe-7s-graph3"></i>
                        </div>
                    </div>
                    <div class="btn" *ngIf="segment?.status == 4 && !segment?.lookAlikeParametersCalculated" title="Calculate look alikes parameters">
                        <div class="font-icon-wrapper" (click)="calculateLookAlikesParameters(segment)">
                            <i class="pe-7s-power"></i>
                        </div>
                    </div>                       
                    <div class="btn" *ngIf="segment?.lookAlikeParametersCalculated && segment?.status == 4" title="Show look alikes">
                        <div class="font-icon-wrapper" (click)="showLookAlikes(segment)">
                            <i class="pe-7s-users"></i>
                        </div>
                    </div>                    
                    <div class="btn" *ngIf="segment?.status == 4 || segment?.status == 5" title="Delete segment">
                        <div class="font-icon-wrapper" (click)="deleteSegment(segment)">
                            <i class="pe-7s-trash"></i>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </ng-container>
</app-publicis-table>