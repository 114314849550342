<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <!-- Text search -->
      <app-search-box *ngIf="searchEnabled && !openSearchDialogCallback" [searchBoxName]="" [search]="onSearch">        
      </app-search-box>      
      <!-- Custom filter dialog -->
      <label *ngIf="!!isFilterApplied && isFilterApplied()" style="color: red;">Filter applied&nbsp;&nbsp;&nbsp;</label>
      <button mat-icon-button *ngIf="searchEnabled && !!openSearchDialogCallback" (click)="openSearchDialogCallback()">
        <mat-icon class="pe-7s-filter" style="vertical-align:middle;" [style]="isFilterApplied() ? 'color: red' : ''"></mat-icon>  
      </button>
    </div>
  </div>
</div>