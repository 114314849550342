<div class="h-100 bg-premium-dark bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <img src="../../assets/images/ERIS.png" />
                <div>ERIS ID Portal</div>
                <span>You have no rights yet. Please contact ERIS team to configure your access.</span>
              </h4>
              <button class="btn btn-success" (click)="userService.logout()">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>