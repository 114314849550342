<ngx-spinner name="dialogSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p style="color: white">Loading ...</p>
</ngx-spinner>
<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-6">
            Filter audiences:
            <app-search-box [searchBoxName]="" [search]="onSearch">        
            </app-search-box>             
        </div>
        <div class="col-md-6">
            <mat-checkbox [(ngModel)]="showArchivedAudiences" (ngModelChange)="onShowArchivedAudiencesChange()">Show archived audiences</mat-checkbox>
        </div>
    </div>
    <div ngbRadioGroup name="audiences" [(ngModel)]="selectedAudienceId">
        <div style="padding: 2px;" *ngFor="let audience of filteredAudiences">
            <div class="btn-group-lg btn-group btn-group-toggle">
                <label ngbButtonLabel class="btn-focus">
                    <input ngbButton type="radio" [value]="audience.id">
                    <div style="word-break: break-all;">{{audience.name}}</div>                    
                </label>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button [disabled]="!selectedAudienceId" type="button" class="btn btn-secondary"
        (click)="onAccept()">Accept</button>
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">Cancel</button>
</div>