import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AudienceParametersDialogComponent } from 'src/app/components/dialogs/audiences/audience-parameters-dialog/audience-parameters-dialog.component';
import { AudienceParameters } from 'src/app/models/services/audience';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class AudienceParametersDialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public show = (acceptCallback: (data: AudienceParameters) => void, rejectCallback?: () => void, audienceParameters?: AudienceParameters): void => {    
    var ref = this.modalService.open(AudienceParametersDialogComponent, { size: 'xl' });
    var output: AudienceParameters;

    // Get the OkDialog from the NgbModal object  
    let dialog: AudienceParametersDialogComponent = ref.componentInstance as AudienceParametersDialogComponent;

    // Set the dialog's properties
    dialog.accept = (data: AudienceParameters) => {
      output = data;
      ref.close(1); 
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    dialog.audienceParameters = audienceParameters;
    
    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }
}
