import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-viewer-carrefour',
  templateUrl: './report-viewer-carrefour.component.html',
  styleUrls: ['./report-viewer-carrefour.component.sass']
})
export class ReportViewerCarrefourComponent implements OnInit {
  
  @Input()
  public selectedReport: any;

  constructor() { }

  ngOnInit(): void {
  }

}
