import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { IABMaster } from 'src/app/models/services/iab';

@Component({
  selector: 'eris-iab-master-tree',
  templateUrl: './iab-master-tree.component.html',
  styleUrls: ['./iab-master-tree.component.sass']
})
export class IabMasterTreeComponent implements OnInit, OnChanges {

  @Input() 
  data: IABMaster[];

  @Output() 
  onSelectedNodeChange = new EventEmitter<IABMaster>();

  selectedNodeId: number;

  private _transformer = (node: IABMaster, level: number) => {
    return {
      id: node.id,
      parentId: node.parentId,
      name: node.name,
      description: node.description,
      children: node.children,
      keywords: node.keywords,
      expandable: !!node.children && node.children.length > 0,
      level: level
    };
  };

  treeControl = new FlatTreeControl<IABMaster>(
    node => node.level,
    node => !node.children && node.children.length > 0,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
  ) { 
  }

  ngOnInit(): void {
    this.dataSource.data = this.data;    
  }
  ngOnChanges(changes: SimpleChanges): void {
    var x = changes;  
  }

  hasChild = (_: number, node: IABMaster) => !!node.children && node.children.length > 0;

  selectNode = (node: IABMaster) => {
    this.selectedNodeId = node.id;
    this.onSelectedNodeChange.emit(node);
  }  
}
