<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div *ngIf="!usersService.hasGlobalSectionRight('Admin')">
    You have no rights to access this page
</div>
<div *ngIf="loadFinished && usersService.hasGlobalSectionRight('Admin')">
    <div style="text-align: right;">
        <button class="btn btn-success" (click)="addReport()">Add report</button>
    </div>    
    <div class="row">&nbsp;</div>
    <div class="row">&nbsp;</div>
    <div class="row">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">PowerBI Portal Group</th>
                    <th scope="col">PowerBI Portal Report ID</th>
                    <th scope="col">Display name</th>                    
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let powerBIReport of powerBIReports">                    
                    <td>{{powerBIReport.workspaceName}}</td>
                    <td>{{powerBIReport.powerBIId}}</td>
                    <td>{{powerBIReport.name}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>