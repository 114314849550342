import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSelectorDialogComponent } from 'src/app/components/dialogs/admin/user-selector-dialog/user-selector-dialog.component';
import { User } from 'src/app/models/services/user';

@Injectable({
  providedIn: 'root'
})
export class UserSelectorDialogService {

  constructor(
    private modalService: NgbModal
  ) { }

  public showUserSelector = (acceptCallback: (selectedUser: User) => void, rejectCallback?: () => void): void => {
    var ref = this.modalService.open(UserSelectorDialogComponent, {
      backdrop: 'static'
    });
    let output: User;

    // Get the OkDialog from the NgbModal object  
    let dialog: UserSelectorDialogComponent = ref.componentInstance as UserSelectorDialogComponent;

    // Set the dialog's properties
    dialog.accept = (selectedUser: User) => {      
      output = selectedUser;
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }

}
