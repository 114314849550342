import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DialogService } from './dialog.service';
import { LoginService } from '../login.service';

@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(
    private httpClient: HttpClient,
    private userService: LoginService,
    private dialogService: DialogService) {

  }

  public get = <Type extends Object>(url: string, tenantId?: number, silentError?: boolean): Observable<Type> => {
    var instance = this;
    return new Observable<Type>(subscriber => {
      this.userService.getCurrentAuthentication().then(response => {
        this.httpClient.get(url, {
          headers: this.getCommonHeaders(response.accessToken, tenantId)
        }).subscribe({
          next(x: Type) { subscriber.next(x) },
          error(err) { 
            if (!silentError)              
              instance.handleError(err);
            subscriber.error(err) 
          },
          complete() { subscriber.complete() }
        });
      }).catch(err => instance.handleAuthError(err))
    });
  };

  public getFile = (url: string, tenantId?: number): Observable<Blob> => {
    var instance = this;
    return new Observable<Blob>(subscriber => {
      this.userService.getCurrentAuthentication().then(response => {
        this.httpClient.get(url, {
          headers: this.getCommonHeaders(response.accessToken, tenantId),
          responseType: 'blob'
        }).subscribe({
          next(x: Blob) { subscriber.next(x) },
          error(err) { instance.handleError(err); subscriber.error(err) },
          complete() { subscriber.complete() }
        });
      }).catch(err => instance.handleAuthError(err))
    });
  };

  public post = <Type extends Object>(url: string, data: object, tenantId?: number, silentError?: boolean): Observable<Type> => {
    var instance = this;
    return new Observable<Type>(subscriber => {
      this.userService.getCurrentAuthentication().then(response => {
        this.httpClient.post(url, data, {
          headers: this.getCommonHeaders(response.accessToken, tenantId)
        }).subscribe({
          next(x: Type) { subscriber.next(x) },
          error(err) { 
            if (!silentError)              
              instance.handleError(err);
            subscriber.error(err)
          },
          complete() { subscriber.complete() }
        });
      })
    });
  };

  public postNoResponse = (url: string, data: object, tenantId?: number): Observable<void> => {
    var instance = this;
    return new Observable<void>(subscriber => {
      this.userService.getCurrentAuthentication().then(response => {
        this.httpClient.post(url, data, {
          headers: this.getCommonHeaders(response.accessToken, tenantId)
        }).subscribe({
          next() { subscriber.next() },
          error(err) { instance.handleError(err); subscriber.error(err) },
          complete() { subscriber.complete() }
        });
      })
    });
  };

  public putNoResponse = (url: string, data: object, tenantId?: number): Observable<void> => {
    var instance = this;
    return new Observable<void>(subscriber => {
      this.userService.getCurrentAuthentication().then(response => {
        this.httpClient.put(url, data, {
          headers: this.getCommonHeaders(response.accessToken, tenantId)
        }).subscribe({
          next() { subscriber.next() },
          error(err) { instance.handleError(err); subscriber.error(err) },
          complete() { subscriber.complete() }
        });
      })
    });
  };

  public postWithProgress = <Type extends Object>(url: string, data: object, tenantId?: number): Observable<HttpEvent<Type>> => {
    var instance = this;
    return new Observable<HttpEvent<Type>>(subscriber => {
      this.userService.getCurrentAuthentication().then(response => {
        this.httpClient.post(url, data, {
          headers: this.getCommonHeaders(response.accessToken, tenantId),
          reportProgress: true,
          observe: 'events'
        }).subscribe({
          next(x: HttpEvent<Type>) { subscriber.next(x) },
          error(err) { instance.handleError(err); subscriber.error(err) },
          complete() { subscriber.complete() }
        });
      })
    });
  };

  public delete = <Type extends Object>(url: string, tenantId?: number): Observable<Type> => {
    var instance = this;
    return new Observable<Type>(subscriber => {
      this.userService.getCurrentAuthentication().then(response => {
        this.httpClient.delete(url, {
          headers: this.getCommonHeaders(response.accessToken, tenantId)
        }).subscribe({
          next(x: Type) { subscriber.next(x) },
          error(err) { instance.handleError(err); subscriber.error(err) },
          complete() { subscriber.complete() }
        });
      })
    });
  };

  public deleteNoResponse = (url: string, tenantId?: number): Observable<void> => {
    var instance = this;
    return new Observable<void>(subscriber => {
      this.userService.getCurrentAuthentication().then(response => {
        this.httpClient.delete(url, {
          headers: this.getCommonHeaders(response.accessToken, tenantId)
        }).subscribe({
          next() { subscriber.next() },
          error(err) { instance.handleError(err); subscriber.error(err) },
          complete() { subscriber.complete() }
        });
      })
    });
  };  

  private handleError = (error: HttpErrorResponse): void => {
    let message: string;
    switch (error.status) {
      case 401:
        message = "User has not enough rights for the requested operation.";
        break;
      case 400:
        message = error.error.Message || error.error.errorDescription || error.error;
        break;
      default:
        message = "Unknown error";
    }
    this.dialogService.showError(message);
  }

  private handleAuthError = (error: any): void => {
    if (error instanceof InteractionRequiredAuthError || error instanceof AuthError)
      this.userService.login();
  }

  private getCommonHeaders = (accesToken: string, tenantId: number): HttpHeaders => {
    let headers: HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + accesToken,
      //'Content-Type': 'multipart/form-data'
      "Ocp-Apim-Trace": environment.production ? "false" : "true"
    });

    let subscriptionKey = this.getSubscriptionKey();
    if (subscriptionKey != undefined)
      headers = headers.append("Ocp-Apim-Subscription-Key", subscriptionKey);
    
    if (tenantId != undefined)
      headers = headers.append("X-PublicisIdPortal-TenantId", tenantId.toString());

    return headers;
  }

  private getSubscriptionKey = (): string => {
    var account = this.userService.getLoggedAccount();

    return '' + account?.idTokenClaims['extension_APIKey'];
  }
}
