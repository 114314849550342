import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericIdNameSelectorDialogComponent } from 'src/app/components/core/generic-id-name-selector-dialog/generic-id-name-selector-dialog.component';
import { GenericItem } from 'src/app/models/components/generic-item';

@Injectable({
  providedIn: 'root'
})
export class GenericIdNameSelectorDialogService {

  constructor(
    private modalService: NgbModal
  ) { }

  public showSelector = (
    title: string, 
    items: GenericItem[], 
    selectedItems: GenericItem[], 
    acceptCallback: (selectedItem: GenericItem) => void, 
    rejectCallback?: () => void
  ): void => {
    var ref = this.modalService.open(GenericIdNameSelectorDialogComponent, {
      backdrop: 'static'
    });
    let output: GenericItem;

    // Get the OkDialog from the NgbModal object  
    let dialog: GenericIdNameSelectorDialogComponent = ref.componentInstance as GenericIdNameSelectorDialogComponent;

    // Set the dialog's properties
    dialog.title = title;
    dialog.items = items;
    dialog.selectedItems = selectedItems;
    dialog.accept = (selectedItem: GenericItem) => {      
      output = selectedItem;
      ref.close(1);
    };

    dialog.dismiss = () => {
      ref.dismiss(2);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback(output);
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }

}
